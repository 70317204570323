.breadcrumbs {
  padding-top: 10px;
  padding-bottom: 10px;
  //border-bottom: 1px solid $line3;

  //background: $bg-grey;



  ul {
    display: flex;
    flex-wrap: wrap;

    span,
    a {
      font-size: 10px;
      color: $txt-light;
      display: inline-block;

      padding: 5px 0;
      line-height: 1;
      font-weight: 300;

      &:before {
        margin-right: 5px;
        color: $txt-light;
        content: "/";

      }
    }

    li:first-child {
      a:before {
        content: none;
      }
    }

    a {
      margin-right: 5px;


      &:focus,
      &:hover {
        outline: none;
        color: $main;

        &:after {
          color: $txt-light;
        }

      }
    }
  }

  @media(min-width: $grid-breakpoints-md) {

    ul {

      a,
      span {
        font-size: 14px;
      }
    }
  }

}

.container .breadcrumbs .container{
  padding-left:0;
  padding-right:0;
}
.section--first{
  padding-top:60px;

  @media(max-width:$grid-breakpoints-md) {
    padding-top:30px;
  }
}
.fixed-header-scroll {
  &.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    background: #fff;
  }

  .headerT {
    display: none;
  }

  .headerB__wrapper {
    height: 60px;

    .btn--settings {
      padding-top: 10px;
      padding-bottom: 10px;
      height:48px;
    }
  }

  .nav__level-1 > li > a {
    padding-bottom: 18px;
  }

  .menu__item--has-children:not(.menu2) .nav__level-2 {
    top: 59px;
  }

  .nav-toggle {
    top: 5px;
  }

}


.headerT {
  background-image: linear-gradient(to right, #000 0%, #000 90%, #222121 90%, #222121 100%);
}

.header {
  .container {
    max-width: 1900px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.headerT__list {
  display: flex;
  justify-content: flex-end;
}

.headerT__item {
  background: #222121;
  border-left: 1px solid #000;

  a {
    height: 48px;
    color: #fff;
    font-size: 14px;
    line-height: 48px;
    display: inline-block;
    padding: 0 20px;
  }

  &:first-child {
    border-left: none;
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 0;
      height: 0;
      border-top: 24px solid transparent;
      border-bottom: 24px solid transparent;
      border-right: 30px solid #222121;
      position: absolute;
      right: 100%;
      top: 0;
    }
  }
}

.serachForm__btn {
  background: none;
  background: #222121;
  color: #FFF;
  font-size: 14px;
  line-height: 48px;
  height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;

  &:before {
    content: "";
    display: inline-block;
    width: 30px;
    height: 30px;
    background: url("../img/ico-search.svg") center no-repeat;
    background-size: 14px;
    margin-right: 5px;
  }

}


.header__logo {
  padding-right: 30px;
  border-right: 1px solid $line3;
  display: flex;
  align-items: center;
}

.headerB {
  border-bottom: 1px solid $line3;
}

.headerB__wrapper {
  display: flex;
  height: 80px;
}

.headerB__social {
  display: flex;
  align-items: center;

  padding-left: 50px;
  padding-right: 30px;

  .social__link img {
    opacity: 1;
    filter: none;
  }

  .social__link {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 48px;
    height: 48px;
  }
}

.headerB__other {
  display: flex;

  width: 100%;

  li {
    border-left: 1px solid $line3;
    padding-left: 20px;
    padding-right: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-left: auto;
      padding-right: 0;
    }
  }

  .btn {
    font-size: 14px;
    padding-right: 20px;
    padding-left: 20px;
  }
}

.headerB__tel {
  padding-left: 35px;
  font-size: $font16;
  display: block;
  background: url("../img/ico-phone.svg") 0px center no-repeat;
  background-size: 30px;
  line-height: 1;

  span {
    display: block;
    font-size: $font14;
  }

  strong, span {
    white-space: nowrap;
  }
}


.nav-toggle {
  width: 48px;
  min-width: 48px;
  height: 48px;
  position: relative;
  left: 10px;
  margin-left: 0px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  background: transparent;
  border: none;
  display: none;
  top: 15px;

}


.nav-toggle span {
  display: block;
  position: absolute;
  height: 2px;
  width: 25%;
  background: #000;
  opacity: 1;
  transform: rotate(0deg);
  transition: .25s ease-in-out;
  border-radius: 2px;
}

.nav-toggle span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

.nav-toggle span:nth-child(odd) {
  left: 25%;
  border-radius: 9px 0 0 9px;
}

.nav-toggle span:nth-child(1),
.nav-toggle span:nth-child(2) {
  top: 12px;
}

.nav-toggle span:nth-child(3),
.nav-toggle span:nth-child(4) {
  top: 24px;
}

.nav-toggle span:nth-child(5),
.nav-toggle span:nth-child(6) {
  top: 36px;
}

.nav-toggle.open span:nth-child(1),
.nav-toggle.open span:nth-child(6) {
  transform: rotate(45deg);
}

.nav-toggle.open span:nth-child(2),
.nav-toggle.open span:nth-child(5) {

  transform: rotate(-45deg);
}

.nav-toggle.open span:nth-child(1) {
  left: 15px;
  top: 20px;
}

.nav-toggle.open span:nth-child(2) {
  left: 50%;
  top: 20px;
}

.nav-toggle.open span:nth-child(3) {
  left: calc(50% - 1px);
  opacity: 0;
}

.nav-toggle.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

.nav-toggle.open span:nth-child(5) {
  left: 15px;
  top: 29px;
}

.nav-toggle.open span:nth-child(6) {
  left: calc(50% - 1px);
  top: 29px;
}

.nav__level-1 {
  display: flex;
  align-items: flex-end;
  height: 100%;

  > li > a {
    white-space: nowrap;
    padding: 10px 10px 25px 10px;
    font-size: $font16;
    font-weight: 500;
    display: block;
    &.active{
      color:$main;
    }
  }
}

.nav {
  display: flex;
  align-items: center;
  padding: 0 10px;
}


.serachForm__fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: $main;
  width: 100%;
  top: -130px;
  transition: top 0.3s ease-out;

  &.open {
    top: 0;
  }

  .container {
    height: 130px;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;

    input {
      background: none;
      border: none;

      padding-left: 70px;
      padding-right: 50px;

      width: 100%;
      color: #fff;

      font-size: 40px;
      -webkit-appearance: none;


    }

    /* clears the 'X' from Internet Explorer */
    input[type=search]::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    input[type=search]::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    /* clears the 'X' from Chrome */
    input[type="search"]::-webkit-search-decoration,
    input[type="search"]::-webkit-search-cancel-button,
    input[type="search"]::-webkit-search-results-button,
    input[type="search"]::-webkit-search-results-decoration {
      display: none;
    }


    ::placeholder {
      color: #fff;
    }

    .serachForm__btn {
      position: absolute;
      left: 10px;
      top: 50%;
      margin-top: -24px;
      font-weight: 300;
      height: 48px;

      &:before {
        background-size: 30px;
      }
    }
  }

}

.serachForm__close {
  color: #fff;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 50px;
  transform: rotate(45deg);
  margin-top: -30px;
  font-weight: 300;
  right: 10px;
  background: none;
  line-height: 1;
  border: none;
}

.page__header {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid $line3;
margin-bottom:50px;
  min-height:180px;
  display:flex;
  align-items: center;
  .breadcrumbs{
    margin-top:20px;
    padding-bottom:0;
  }


  h1 {
    margin-bottom: 0;
    font-size: $font40;
    display: flex;
    gap: 10px;
    align-items: center;
  }

  img {
    min-height: 65px;
    width: auto;
  }
}

@media(max-width: 1800px) {
  .headerB__social {
    padding-left: 10px;
    padding-right: 10px;
  }
  .headerB__other li {
    padding-left: 15px;
    padding-right: 15px;
  }
  .header-ue {
    img {
      max-width: 120px;
    }
  }
}

@media(max-width: 1600px) {
  .headerB__social {
    padding-left: 10px;
    padding-right: 10px;
  }
  .headerB__other li {
    padding-left: 15px;
    padding-right: 15px;
  }
  .headerB__social .social__link {
    min-width: 38px;
  }


}

@media(max-width: 1500px) {
  .headerB__social {
    display: none;
  }

  .headerB__other {
    width: initial;
    margin-left: auto;
  }


}

@media(max-width: 1400px) {
  .headerB__tel {
    height: 50px;
    padding-left: 50px;
    background-position: 10px center;

    span, strong {
      display: none;
    }
  }
  .header .btn--settings {
    background-color: #fff !important;
    width: 50px;
    padding-right: 10px;
    padding-left: 10px;

    span {
      display: none;
    }

    &:before {
      margin-right: 0;
      content: url("../img/ico-settings2.svg");
    }
  }
  .serachForm__fixed .container input {
    font-size: 30px;
  }

}


@media(max-width: $grid-breakpoints-xl) {
  .nav-toggle {
    display: block;
  }

  .nav {
    display: none;
  }

  .header__logo {
    border: none;
  }
  .page__header {
    h1 {
      font-size: $font34;
    }
  }
}

@media(max-width: $grid-breakpoints-md) {
  .header__logo {
    img {
      max-width: 140px;
    }
  }
  .headerB__other li {
    padding-left: 10px;
    padding-right: 10px;
    border-right: 1px solid $line3;

  }
  .serachForm__fixed .container input {
    font-size: 20px;
  }

  .serachForm__fixed .container .serachForm__btn {

    &:before {
      background-size: 20px;
    }
  }
  .page__header {
    margin-bottom:25px;
    h1 {
      font-size: $font28;
    }
  }
}

@media(max-width: $grid-breakpoints-sm) {

  .header .header-ue {
    display: none;
  }
  .headerT {
    background: #222121;

    .container {
      padding-right: 0;
    }

    a, .serachForm__btn {
      font-size: 11px;
      height: 30px;
      line-height: 30px;

    }
  }
  .headerB__wrapper {
    height: 70px;
  }
  .headerT__item:first-child:before {
    content: none;
  }

  .nav-toggle {
    top: 10px;
  }


  .page__header {
    min-height:120px;
    padding-top: 20px;
    padding-bottom: 20px;

.breadcrumbs{
  margin-top:10px;
}

    h1 {
      margin-bottom: 0;
      font-size: $font22;

    }
    img{
      min-height:44px;
    }
  }
}

@media(max-width: 400px) {
  .header__logo {
    padding-right: 20px;
  }
  .headerB__other li {
    padding-left: 5px;
    padding-right: 5px;
  }
  .headerT {
    .container {
      padding-left: 0;
    }

    a {
      padding: 0 10px
    }
  }
  .headerT .serachForm__btn, {
    padding-left: 10px;
  }


}

@media(max-width: 365px) {
  .header__logo img {
    max-width: calc(100vw - 235px);
  }
}


@media(min-width:1201px) {

  .nav {
    display: flex;

  }

  .nav__wrapper {
    height: 100%;
  }

  .menu__item--has-children:not(.menu2) {
    .nav__level-2 {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);

      position: fixed;
      display: none;
      top: 127px;
      width: 40vw;
      left: 0;
      background: $bg-grey;
      z-index: 99;

      padding-left: 20px;
      padding-bottom: 20px;

      &:before {
        content: "";
        width: 100vw;
        left: 0;
        top: 0;
        height: 1px;
        background: $line3;
        display: block;
      }

      li {
        position: static;
      }


      > li {
        width: 100%;

        &.open-l {
          > a:after {
            display: block;
            content: "";
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-left: 10px solid $bg-grey;
            border-bottom: 10px solid transparent;
            position: absolute;
            bottom: 16px;
            right: -10px;
            z-index: 20;
          }
        }

        > a {
          width: 100%;

          display: flex;
          align-items: center;
          gap: 15px;
          padding: 10px 0;
          background: $bg-grey;
          z-index: 30;
          padding-left: calc(100% - 410px);
          position: relative;

          img {
            filter: brightness(0);
            transition: all 0.3s ease-out;
          }

          &.active, &:hover {

            img {
              filter: none;
            }

            color: $main;
          }

        }

        &:first-child > a {
          padding-top: 25px;
        }
      }

    }

    .nav__level-3 {


      width: 125%;
      background: #fff;
      height: 100%;
      left: 100%;
      position: absolute;
      top: 1px;
      padding-left: 50px;
      z-index: 10;
      display: none;

      &.open {
        z-index: 20;
        display: block;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 80vw;
        height: 100%;
        background: #fff;
        z-index: -1;

      }


      h3 {
        font-size: 24px;
        padding-left: 35px;
        position: relative;
        margin-bottom: 30px;
        margin-top: 40px;

        &:before {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background: $main;
          position: absolute;
          top: 50%;
          margin-top: -10px;
          left: 0;
        }
      }

      ul {
        display: flex;
        margin-bottom: 35px;
        max-width: 900px;
        gap: 20px;

        li {
          flex: 0 0 25%;
          max-width: calc(25% - 15px);

          a {
            background: $bg-grey;
            border-radius: 5px;
            padding: 10px 20px;
            display: block;
            height:100%;
          }

          figure {
            padding-top: 100%;
            height: 0;
            position: relative;
            margin-bottom: 10px;

            img {
              width: 90%;
              height: 90%;
              object-fit: contain;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              position: absolute;
            }
          }

          h4 {
            font-size: $font14;
            font-weight: 700;
            margin-bottom: 0;

            span {
              color: $main;
            }
          }

          p {
            font-size: $font14;
          }
        }
      }
    }
  }

  .menu2 {
    position: relative;

    .nav__level-2 {
      position: absolute;
      display: none;
      top: 100%;
      width: 205px;
      background: $bg-grey;
      padding: 10px 0px;
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .16);
      z-index: 90;


      a {
        font-size: $font14;
        font-weight: 500;
        display: block;
        padding: 7px 20px;
      }
    }
  }


  .nav-overlay {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    z-index: 2;
  }

}

@media(max-width: $grid-breakpoints-xl) {
  .nav {
    position: fixed;
    top: 129px;
    width: 100vw;
    left: 0;
    height: calc(100vh - 140px);
    overflow: auto;
    background: $bg-grey;
    padding: 10px 0 60px 0;
    z-index: 100;

  }

  .headerB__social {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 5px;

    margin-top: 15px;

    .social__link {
      min-width: 48px;
    }
  }

  .nav__level-1 {
    display: block;

    a {
      width: 100%;
      padding: 10px 20px;
      font-size: $font16;
    }

    > li {
      border-bottom: 1px solid $line3;

      > a {
        font-size: $font20;
        padding: 10px 20px;
        padding-bottom: 10px !important;

        width: 80%;
      }
    }


    > .menu__item--has-children {
      position: relative;

      &:before, &:after {
        content: "";
        position: absolute;
        right: 20px;
        top: 24px;
        background: #000;
        transition: all 0.3s ease-out;
        transform-origin: center;
        width: 20px;
        display: block;
        height: 2px;
      }

      &:after {
        transform: rotate(-90deg);
      }

      &.open {
        &:after {
          transform: rotate(0deg);
        }
      }
    }
  }

  .nav__level-2 {
    display: none;

    a {
      display: flex;
      align-items: center;
      gap: 15px;
      font-weight: 500;
      padding: 10px 0 10px 30px;
      width: 100%;
    }
  }

  .nav__level-3 {
    display: none;
  }

  .fixed-header-scroll {
    .nav {
      top: 60px;
      height: calc(100vh - 60px);
    }
  }
}


@media (min-width: 1200px) and (max-width: 1960px) {
  .menu__item--has-children:not(.menu2) {
    .nav__level-2 {
      width: 700px;
    }

    .nav__level-3 {
      width: calc(100vw - 740px);
    }
  }
}

@media (min-width: 1200px) and (max-width: 1600px) {
  .menu__item--has-children:not(.menu2) {
    .nav__level-2 {
      width: 430px;

      > li > a {
        padding-left: 10px;
      }
    }

    .nav__level-3 {
      width: calc(100vw - 470px);
    }
  }
}


@media(max-width: $grid-breakpoints-sm) {
  .nav {
    top: 100px;
  }
}






.headerP {
  min-height: 540px;
  display: flex;
  align-items: center;
  font-size: $font37;
  position: relative;
  color: #fff;

  p {
    max-width: 1200px;
  }
}

.headerP__img {
  position: absolute;
  top: 0;
  width: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

@media(max-width: $grid-breakpoints-lg) {
  .headerP {
    min-height: 400px;
    font-size: $font30;
  }
}

@media(max-width: $grid-breakpoints-sm) {
  .headerP {
    align-items: flex-end;
    min-height: 260px;
    font-size: $font22;
    padding-bottom: 20px;
  }
}