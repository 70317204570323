/* roboto-300 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/roboto-v30-latin_latin-ext-300.eot'); /* IE9 Compat Modes */
    src: url('../fonts/roboto-v30-latin_latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v30-latin_latin-ext-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/roboto-v30-latin_latin-ext-300.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/roboto-v30-latin_latin-ext-300.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/roboto-v30-latin_latin-ext-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/roboto-v30-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/roboto-v30-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v30-latin_latin-ext-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/roboto-v30-latin_latin-ext-regular.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/roboto-v30-latin_latin-ext-regular.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/roboto-v30-latin_latin-ext-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/roboto-v30-latin_latin-ext-500.eot'); /* IE9 Compat Modes */
    src: url('../fonts/roboto-v30-latin_latin-ext-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v30-latin_latin-ext-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/roboto-v30-latin_latin-ext-500.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/roboto-v30-latin_latin-ext-500.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/roboto-v30-latin_latin-ext-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/roboto-v30-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
    src: url('../fonts/roboto-v30-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/roboto-v30-latin_latin-ext-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/roboto-v30-latin_latin-ext-700.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/roboto-v30-latin_latin-ext-700.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/roboto-v30-latin_latin-ext-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

/* montserrat-300 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/montserrat-v25-latin_latin-ext-300.eot'); /* IE9 Compat Modes */
    src: url('../fonts/montserrat-v25-latin_latin-ext-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/montserrat-v25-latin_latin-ext-300.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/montserrat-v25-latin_latin-ext-300.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/montserrat-v25-latin_latin-ext-300.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/montserrat-v25-latin_latin-ext-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/montserrat-v25-latin_latin-ext-regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/montserrat-v25-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/montserrat-v25-latin_latin-ext-regular.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/montserrat-v25-latin_latin-ext-regular.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/montserrat-v25-latin_latin-ext-regular.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/montserrat-v25-latin_latin-ext-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/montserrat-v25-latin_latin-ext-500.eot'); /* IE9 Compat Modes */
    src: url('../fonts/montserrat-v25-latin_latin-ext-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/montserrat-v25-latin_latin-ext-500.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/montserrat-v25-latin_latin-ext-500.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/montserrat-v25-latin_latin-ext-500.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/montserrat-v25-latin_latin-ext-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/montserrat-v25-latin_latin-ext-600.eot'); /* IE9 Compat Modes */
    src: url('../fonts/montserrat-v25-latin_latin-ext-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/montserrat-v25-latin_latin-ext-600.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/montserrat-v25-latin_latin-ext-600.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/montserrat-v25-latin_latin-ext-600.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/montserrat-v25-latin_latin-ext-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/montserrat-v25-latin_latin-ext-700.eot'); /* IE9 Compat Modes */
    src: url('../fonts/montserrat-v25-latin_latin-ext-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/montserrat-v25-latin_latin-ext-700.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/montserrat-v25-latin_latin-ext-700.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/montserrat-v25-latin_latin-ext-700.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/montserrat-v25-latin_latin-ext-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin_latin-ext */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 800;
    src: url('../fonts/montserrat-v25-latin_latin-ext-800.eot'); /* IE9 Compat Modes */
    src: url('../fonts/montserrat-v25-latin_latin-ext-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/montserrat-v25-latin_latin-ext-800.woff2') format('woff2'), /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
    url('../fonts/montserrat-v25-latin_latin-ext-800.woff') format('woff'), /* Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+, iOS 5+ */
    url('../fonts/montserrat-v25-latin_latin-ext-800.ttf') format('truetype'), /* Chrome 4+, Firefox 3.5+, IE 9+, Safari 3.1+, iOS 4.2+, Android Browser 2.2+ */
    url('../fonts/montserrat-v25-latin_latin-ext-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}