.footer {
  background: #000;
  color: #fff;
  font-size: $font16;

  .line{
    border-color:rgba(255, 255, 255, 0.2);
    display:none;
  }
}

.footer__top {
  padding-top: 110px;
  padding-bottom: 110px;
}

.footer__logo {
  width: 210px;
  max-width: 100%;
}

.footer__bg {
  background: #1A1A1A;
}

.footer__center {
  padding-top: 70px;
  padding-bottom: 0px;

  h3 {
    font-size: $font18;
  }

  p, li {
    color: $txt-light-w;
  }

  a {
    color: $txt-light-w;
    display: block;
    padding: 3px 0;

    @media(hover: hover) {
      &:hover {
        color: rgba(255, 255, 255);

        img {
          opacity: 1;
        }
      }
    }
  }
}


.social__bottom {
  @media(min-width: $grid-breakpoints-xl) {
    padding-left: 15%;
  }
}

.social__link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;


  img {
    filter: invert(1);
    opacity: 0.65;
  }

}

.footer__brand{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  line-height: 1;
  a{
    display: inline-block;
    padding: 2px 5px;
  }
}

.footer__bottom {
  padding-top: 50px;
  padding-bottom: 50px;
  opacity: 0.65;

  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: space-between;
  font-size: $font14;
  align-items: center;
  position: relative;
  z-index: 10;
  //
  //img {
  //  max-height: 40px;
  //  width: auto;
  //  max-width: 100%;
  //  object-fit: contain;
  //}
}


@media(max-width: $grid-breakpoints-lg) {

}

@media(max-width: $grid-breakpoints-md) {

}

@media(min-width: $grid-breakpoints-sm) {

}

@media(max-width: $grid-breakpoints-sm) {
  .footer .line{
    display:block;
  }
  .footer__top {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .footer__center {
    padding-top: 40px;
  }

  .footer__bottom{
    flex-direction: column;
    align-items: flex-start;
    gap:20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }

}



