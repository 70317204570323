html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
figure,
figcaption,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

img {
  vertical-align: middle;
}

/* tables still need 'cellspacing="0"' in the markup */
table {
  border-collapse: separate;
  border-spacing: 0;
  color: #000;
  -moz-text-size-adjust: none;
       text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.container {
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1800px;
}
@media (min-width: 375px) {
  .container {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 768px) {
  .container {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.container--md {
  max-width: 1400px;
}

@media (max-width: 576px) {
  .container--xs {
    max-width: 420px;
  }
}

@media (max-width: 576px) {
  .container--xxs {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
}

.container--half {
  max-width: 900px;
  padding-left: 20px;
  padding-right: 20px;
}
@media (min-width: 375px) {
  .container--half {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 768px) {
  .container--half {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
}
@media (max-width: 576px) {
  .row {
    margin-left: -8px;
    margin-right: -8px;
    width: calc(100% + 16px);
  }
}

[class*=col-] {
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-bottom: 30px;
}
@media (max-width: 576px) {
  [class*=col-] {
    padding-left: 8px;
    padding-right: 8px;
    margin-bottom: 16px;
  }
}

.row--16 {
  margin-left: -8px;
  margin-right: -8px;
  width: calc(100% + 16px);
}
.row--16 [class*=col-] {
  padding-left: 8px;
  padding-right: 8px;
  width: 100%;
  margin-bottom: 16px;
}

@media (min-width: 1200px) {
  .row--60 {
    margin-left: -30px;
    margin-right: -30px;
    width: calc(100% + 60px);
  }
  .row--60 [class*=col-] {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    margin-bottom: 60px;
  }
}

.w-100 {
  width: 100% !important;
  max-width: 100% !important;
  flex: 0 0 100% !important;
}

.col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

@media (min-width: 576px) {
  .col-sm-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-sm-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-sm-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-sm-8 {
    max-width: 66.66%;
    flex: 0 0 66.66%;
  }
}
@media (min-width: 768px) {
  .col-md-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }

  .col-md-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-md-5 {
    max-width: 41.66%;
    flex: 0 0 41.66%;
  }

  .col-md-50,
.col-md-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-md-7 {
    max-width: 58.33%;
    flex: 0 0 58.33%;
  }

  .col-md-8 {
    max-width: 66.66%;
    flex: 0 0 66.66%;
  }
}
@media (min-width: 992px) {
  .col-lg-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }

  .col-lg-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-lg-5 {
    max-width: 41.66%;
    flex: 0 0 41.66%;
  }

  .col-lg-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-lg-7 {
    max-width: 58.33%;
    flex: 0 0 58.33%;
  }

  .col-lg-12 {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
@media (min-width: 1200px) {
  .col-xl-125 {
    max-width: 12.5%;
    flex: 0 0 12.5%;
  }

  .col-xl-2 {
    max-width: 16.66%;
    flex: 0 0 16.66%;
  }

  .col-xl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xl-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-xl-6 {
    max-width: 50%;
    flex: 0 0 50%;
  }

  .col-xl-8 {
    max-width: 66.66%;
    flex: 0 0 66.66%;
  }

  .col-xl-20 {
    max-width: 20%;
    flex: 0 0 20%;
  }
}
@media (min-width: 1500px) {
  .col-xxl-3 {
    max-width: 25%;
    flex: 0 0 25%;
  }

  .col-xxl-4 {
    max-width: 33.33%;
    flex: 0 0 33.33%;
  }

  .col-xxl-20 {
    max-width: 20%;
    flex: 0 0 20%;
  }
}
.img--fluid {
  max-width: 100%;
  height: auto;
}

.box--center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.nowrap {
  white-space: nowrap !important;
}

.section {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media (max-width: 576px) {
  .section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.section--30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.d-block {
  display: block;
}

.ml-auto {
  margin-left: auto !important;
}

.p-20 {
  padding: 20px;
}

.pt-0 {
  padding-top: 0px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-100 {
  margin-top: 100px !important;
}
@media (max-width: 768px) {
  .mt-100 {
    margin-top: 50px !important;
  }
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}
@media (max-width: 768px) {
  .mb-100 {
    margin-bottom: 60px !important;
  }
}

.mb-sm-50 {
  margin-bottom: 50px !important;
}
@media (max-width: 576px) {
  .mb-sm-50 {
    margin-bottom: 30px !important;
  }
}

/* roboto-300 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-v30-latin_latin-ext-300.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/roboto-v30-latin_latin-ext-300.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v30-latin_latin-ext-300.woff2") format("woff2"), url("../fonts/roboto-v30-latin_latin-ext-300.woff") format("woff"), url("../fonts/roboto-v30-latin_latin-ext-300.ttf") format("truetype"), url("../fonts/roboto-v30-latin_latin-ext-300.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-regular - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-v30-latin_latin-ext-regular.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/roboto-v30-latin_latin-ext-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v30-latin_latin-ext-regular.woff2") format("woff2"), url("../fonts/roboto-v30-latin_latin-ext-regular.woff") format("woff"), url("../fonts/roboto-v30-latin_latin-ext-regular.ttf") format("truetype"), url("../fonts/roboto-v30-latin_latin-ext-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-500 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/roboto-v30-latin_latin-ext-500.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/roboto-v30-latin_latin-ext-500.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v30-latin_latin-ext-500.woff2") format("woff2"), url("../fonts/roboto-v30-latin_latin-ext-500.woff") format("woff"), url("../fonts/roboto-v30-latin_latin-ext-500.ttf") format("truetype"), url("../fonts/roboto-v30-latin_latin-ext-500.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* roboto-700 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-v30-latin_latin-ext-700.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/roboto-v30-latin_latin-ext-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v30-latin_latin-ext-700.woff2") format("woff2"), url("../fonts/roboto-v30-latin_latin-ext-700.woff") format("woff"), url("../fonts/roboto-v30-latin_latin-ext-700.ttf") format("truetype"), url("../fonts/roboto-v30-latin_latin-ext-700.svg#Roboto") format("svg");
  /* Legacy iOS */
}
/* montserrat-300 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/montserrat-v25-latin_latin-ext-300.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/montserrat-v25-latin_latin-ext-300.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v25-latin_latin-ext-300.woff2") format("woff2"), url("../fonts/montserrat-v25-latin_latin-ext-300.woff") format("woff"), url("../fonts/montserrat-v25-latin_latin-ext-300.ttf") format("truetype"), url("../fonts/montserrat-v25-latin_latin-ext-300.svg#Montserrat") format("svg");
  /* Legacy iOS */
}
/* montserrat-regular - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/montserrat-v25-latin_latin-ext-regular.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/montserrat-v25-latin_latin-ext-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v25-latin_latin-ext-regular.woff2") format("woff2"), url("../fonts/montserrat-v25-latin_latin-ext-regular.woff") format("woff"), url("../fonts/montserrat-v25-latin_latin-ext-regular.ttf") format("truetype"), url("../fonts/montserrat-v25-latin_latin-ext-regular.svg#Montserrat") format("svg");
  /* Legacy iOS */
}
/* montserrat-500 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/montserrat-v25-latin_latin-ext-500.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/montserrat-v25-latin_latin-ext-500.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v25-latin_latin-ext-500.woff2") format("woff2"), url("../fonts/montserrat-v25-latin_latin-ext-500.woff") format("woff"), url("../fonts/montserrat-v25-latin_latin-ext-500.ttf") format("truetype"), url("../fonts/montserrat-v25-latin_latin-ext-500.svg#Montserrat") format("svg");
  /* Legacy iOS */
}
/* montserrat-600 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/montserrat-v25-latin_latin-ext-600.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/montserrat-v25-latin_latin-ext-600.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v25-latin_latin-ext-600.woff2") format("woff2"), url("../fonts/montserrat-v25-latin_latin-ext-600.woff") format("woff"), url("../fonts/montserrat-v25-latin_latin-ext-600.ttf") format("truetype"), url("../fonts/montserrat-v25-latin_latin-ext-600.svg#Montserrat") format("svg");
  /* Legacy iOS */
}
/* montserrat-700 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/montserrat-v25-latin_latin-ext-700.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/montserrat-v25-latin_latin-ext-700.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v25-latin_latin-ext-700.woff2") format("woff2"), url("../fonts/montserrat-v25-latin_latin-ext-700.woff") format("woff"), url("../fonts/montserrat-v25-latin_latin-ext-700.ttf") format("truetype"), url("../fonts/montserrat-v25-latin_latin-ext-700.svg#Montserrat") format("svg");
  /* Legacy iOS */
}
/* montserrat-800 - latin_latin-ext */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  src: url("../fonts/montserrat-v25-latin_latin-ext-800.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/montserrat-v25-latin_latin-ext-800.eot?#iefix") format("embedded-opentype"), url("../fonts/montserrat-v25-latin_latin-ext-800.woff2") format("woff2"), url("../fonts/montserrat-v25-latin_latin-ext-800.woff") format("woff"), url("../fonts/montserrat-v25-latin_latin-ext-800.ttf") format("truetype"), url("../fonts/montserrat-v25-latin_latin-ext-800.svg#Montserrat") format("svg");
  /* Legacy iOS */
}
html {
  font-size: 16px;
  scroll-behavior: smooth;
}
@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}
@media (max-width: 360px) {
  html {
    font-size: 12px;
  }
}

body {
  line-height: 1.5;
  font-size: 1.125rem;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #000;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.tooltip {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.tooltip:after {
  content: "i";
  display: inline-block;
  min-width: 25px;
  height: 25px;
  background: #000;
  color: #fff;
  text-align: center;
  line-height: 25px;
  border-radius: 50%;
}
.tooltip span {
  opacity: 0;
  position: absolute;
  top: 100%;
  right: 0;
  background: #fff;
  z-index: -1000;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 0.25);
  padding: 5px;
  font-size: 0.75rem;
  color: #000;
  width: 0;
  height: 0;
  overflow: hidden;
  text-align: center;
  line-height: 1.2;
}
.tooltip:hover span {
  opacity: 1;
  z-index: 1;
  height: auto;
  width: auto;
  min-width: 200px;
}

iframe,
video {
  max-width: 100%;
  width: 100%;
}

strong {
  font-weight: 700;
}

.bold {
  font-weight: 700 !important;
}

.italic {
  font-style: italic;
}

.lh--2 {
  line-height: 2 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font--9 {
  font-size: 0.5625rem !important;
}

.font--10 {
  font-size: 0.625rem !important;
}

.font--12 {
  font-size: 0.75rem !important;
}

.font--14 {
  font-size: 0.875rem !important;
}

.font--18 {
  font-size: 1.125rem !important;
}

.font--20 {
  font-size: 1.25rem !important;
}

.main {
  color: #E6007E !important;
}

.white {
  color: #fff !important;
}

.black {
  color: #000 !important;
}

.main2 {
  color: #1F0970 !important;
}

.txt--center,
.text-center {
  text-align: center !important;
}

.txt--left {
  text-align: left !important;
}

.txt--right {
  text-align: right !important;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.alert,
#komunikat,
.komunikat {
  border: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 20px;
  padding: 15px;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.4;
  background-color: #f8fbcb;
  color: #000 !important;
  display: block;
  font-weight: 500;
}

.alert-success {
  background-color: #F5F7F8 !important;
}

.alert-info {
  background-color: #f5f7f8 !important;
}

.alert-warning {
  background-color: #f8f8cb !important;
}

.alert-danger {
  background-color: #faeded !important;
  color: #ca0038 !important;
}

.alert-grey {
  background-color: #F5F5F5 !important;
}

/* remember to define focus styles! */
:focus {
  outline: none;
}

:focus-visible {
  outline: 2px solid #E6007E;
}

a,
button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

a {
  color: #000;
  text-decoration: none;
}
@media (hover: hover) {
  a:hover {
    color: #E6007E;
  }
}
a:focus-visible {
  outline-offset: 2px;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  z-index: -100;
  opacity: 0;
}

.history--back {
  width: 50px;
  height: 50px;
  display: block;
  margin-bottom: 0px;
}
.history--back:before {
  content: "" !important;
  width: 50px;
  height: 50px;
  background: url("../img/swiper-arrow-left.svg") center no-repeat;
  background-size: 16px;
  display: block;
}

h1,
h2,
h3 {
  letter-spacing: initial;
  line-height: 1.2;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}
h1 a,
h2 a,
h3 a {
  color: inherit;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
}

h3 {
  font-size: 1.875rem;
  margin-bottom: 20px;
}

.title {
  font-weight: 400;
  font-size: 56px;
  margin-bottom: 40px;
}
.title strong {
  display: block;
  color: #E6007E;
  font-size: 116%;
  line-height: 1;
}

.title2 {
  font-size: 1.875rem;
  font-weight: 700;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.title2:before {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #E6007E;
  content: "";
}

.title3 {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 25px;
}
.title3 span {
  color: #E6007E;
}

.description {
  text-align: center;
  font-size: 1.25rem;
  margin-bottom: 50px;
}

.overflow-hidden {
  overflow: hidden !important;
}

.relative {
  position: relative !important;
}

h4,
h5,
h6 {
  line-height: 1.3;
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: 10px;
  font-family: "Montserrat", sans-serif;
}

@media (max-width: 1500px) {
  .title {
    font-size: 3.125rem;
  }
}
@media (max-width: 1200px) {
  h1, h2 {
    font-size: 2.125rem;
  }

  .title {
    font-size: 2.5rem;
    margin-bottom: 30px;
  }

  .title3 {
    font-size: 2.25rem;
  }
}
@media (max-width: 992px) {
  .title2 {
    font-size: 2.125rem;
  }

  .title3 {
    font-size: 2.125rem;
  }
}
@media (max-width: 768px) {
  h1, h2 {
    font-size: 1.6875rem;
    margin-bottom: 20px;
  }

  .title {
    font-size: 2.125rem;
  }

  .title3 {
    font-size: 1.875rem;
  }
}
@media (max-width: 576px) {
  .title {
    margin-bottom: 20px;
  }

  .title2 {
    margin-bottom: 30px;
    gap: 10px;
    font-size: 1.25rem;
  }
  .title2:before {
    width: 12px;
    height: 12px;
  }

  .title3 {
    font-size: 1.75rem;
  }
}
ul,
ol {
  list-style: none;
  padding-left: 0;
}

.page__txt {
  line-height: 1.6;
}
.page__txt h3 {
  font-size: 1.5625rem;
}
.page__txt img {
  max-width: 100%;
  margin-top: 20px;
  margin-bottom: 30px;
  display: inline-block;
  height: auto;
}
.page__txt ul,
.page__txt ol {
  padding-left: 30px;
}
.page__txt ul {
  list-style: none;
}
.page__txt ul li {
  position: relative;
}
.page__txt ul li:before {
  display: inline-block;
  width: 6px;
  height: 6px;
  position: absolute;
  left: -14px;
  top: 11px;
  content: "";
  background: #E6007E;
}
.page__txt ol {
  list-style: auto;
}
.page__txt ol,
.page__txt ul,
.page__txt p {
  margin-bottom: 15px;
}
.page__txt li {
  margin-bottom: 5px;
}
.page__txt a {
  text-decoration: underline;
}
.page__txt h2:not(:first-child) {
  margin-top: 35px;
}
.page__txt h3:not(:first-child) {
  margin-top: 25px;
}
.page__txt h3:not([class]) {
  font-size: 1.625rem;
}

.article__imggroup {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.article__imggroup img {
  max-width: calc(33.33% - 10px);
  flex: 0 0 33.33%;
  margin: 0;
}
@media (max-width: 768px) {
  .article__imggroup {
    gap: 10px;
  }
  .article__imggroup img {
    max-width: calc(50% - 10px);
    flex: 0 0 50%;
  }
}
@media (max-width: 768px) {
  .article__imggroup {
    gap: 10px;
  }
  .article__imggroup img {
    max-width: 100%;
    flex: 0 0 100%;
  }
}

.link--underline {
  text-decoration: underline;
  font-weight: bold;
  color: #000;
}
@media (hover: hover) {
  .link--underline:hover {
    color: #1F0970;
  }
}

.link--underline-main {
  text-decoration: underline;
  color: #1F0970;
}
@media (hover: hover) {
  .link--underline-main:hover {
    color: #E6007E;
  }
}

.link--arrowdown {
  display: block;
  padding-left: 35px;
  font-size: 1.125rem;
  position: relative;
  line-height: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent;
  border: none;
  color: #000;
}
.link--arrowdown:before {
  content: url("../img/arrow-down.svg");
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
@media (hover: hover) {
  .link--arrowdown {
    color: #E6007E;
  }
}

.link--contact {
  display: inline-block;
  padding: 5px 0 5px 35px;
  font-size: 1.125rem;
  background: url("../img/ico-phone-2.svg") 0 center no-repeat;
  background-size: 28px;
  white-space: nowrap;
}

.link--mail {
  background-image: url("../img/ico-mail3.svg");
}

input:not([type=number], [type=radio], [type=checkbox]), textarea,
button {
  -webkit-appearance: none;
  font-family: "Roboto", sans-serif;
  border-radius: 5px;
}

input,
textarea,
select {
  border: 1px solid rgba(149, 149, 149, 0.8);
  line-height: 1.6;
  padding: 10px 15px;
  color: #212121;
  font-family: "Roboto", sans-serif;
  border-radius: 5px;
}
input::-moz-placeholder, textarea::-moz-placeholder, select::-moz-placeholder {
  font-family: "Roboto", sans-serif;
}
input:-ms-input-placeholder, textarea:-ms-input-placeholder, select:-ms-input-placeholder {
  font-family: "Roboto", sans-serif;
}
input::placeholder,
textarea::placeholder,
select::placeholder {
  font-family: "Roboto", sans-serif;
}

::-moz-placeholder {
  color: #959595;
  font-weight: 400;
  font-style: inherit;
  font-family: "Roboto", sans-serif;
}

:-ms-input-placeholder {
  color: #959595;
  font-weight: 400;
  font-style: inherit;
  font-family: "Roboto", sans-serif;
}

::placeholder {
  color: #959595;
  font-weight: 400;
  font-style: inherit;
  font-family: "Roboto", sans-serif;
}

.disabled {
  color: #959595;
}

.nowrap {
  white-space: nowrap;
}

.btn {
  border: none;
  background-color: #E6007E;
  color: #fff !important;
  font-size: 1.125rem;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease-out;
  border: none;
  line-height: 1.2;
  padding: 12px 30px;
  height: 60px;
  vertical-align: middle;
  text-align: center;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
}
@media (hover: hover) {
  .btn:hover {
    background-color: #C8026F;
    color: #fff !important;
  }
}
@media (max-width: 768px) {
  .btn {
    padding: 18px 20px;
  }
}

.btn--settings {
  line-height: 1;
}
.btn--settings:before {
  content: url("../img/ico-settings.svg");
  margin-right: 10px;
  position: relative;
  top: 3px;
}

.btn--black {
  background-color: #000;
}
@media (hover: hover) {
  .btn--black:hover {
    background-color: #E6007E;
    color: #fff !important;
  }
}

.btn--order:before {
  content: url("../img/ico-order.svg");
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.btn--trans {
  border: 1px solid #eaeaea;
  background: transparent;
  color: #000 !important;
  transition: border 0.3s ease-out;
}
@media (hover: hover) {
  .btn--trans:hover {
    background: transparent;
    border-color: #E6007E;
    color: #000 !important;
  }
}
.btn--trans.btn--settings:before {
  content: url("../img/ico-settings2.svg");
}

.btn--pdf:before {
  content: url("../img/ico-pdf.svg");
  margin-right: 10px;
  position: relative;
  top: 2px;
}

.bg--main2 {
  background: #1F0970 !important;
}

.bg--main {
  background: #E6007E !important;
}

.bg--grey {
  background: #F5F5F5 !important;
}

.bg--light {
  background: rgba(230, 0, 126, 0.5) !important;
}

hr {
  border: none;
  border-top: 1px solid #eaeaea;
  margin-top: 20px;
  margin-bottom: 20px;
}

table {
  max-width: 100%;
  font-size: 0.875rem;
  border-collapse: separate;
  line-height: 1.4;
  font-weight: 300;
}
table * {
  line-height: 1.4;
}
table th {
  background: transparent;
  font-weight: 700;
  border-bottom: 1px solid #CECECE;
  padding: 10px 10px;
  vertical-align: middle;
}
table td {
  border-bottom: 1px solid #CECECE;
  padding: 10px 10px;
  vertical-align: middle;
}
@media (max--width: 1400px) {
  table {
    font-size: 1rem;
  }
}
table thead {
  position: relative;
}
table thead th {
  border: none;
  padding: 12px 10px 12px 10px;
  border-top: 2px solid #1F0970;
  border-bottom: 2px solid #1F0970;
}
table .align-right {
  text-align: right;
}
table .align-left {
  text-align: left;
}
table .align-center {
  text-align: center;
}
table tbody tr:first-child th,
table tbody tr:first-child td {
  border-top-width: 5px;
}

.table__wrapper {
  position: relative;
}
.table__wrapper table {
  width: 100%;
  position: relative;
}
.table__wrapper table a {
  display: inline-block;
  padding: 1px 5px;
  text-decoration: underline;
  font-weight: bold;
}
@media (max-width: 768px) {
  .table__wrapper {
    overflow: auto;
  }
  .table__wrapper table {
    min-width: 800px;
    width: initial;
  }
  .table__wrapper table th,
.table__wrapper table td {
    padding: 8px 5px;
  }
  .table__wrapper.table__wrapper--big table {
    min-width: 1300px;
    width: initial;
  }
}

.btn--table {
  background: #E6007E;
  color: #fff;
  padding: 5px 10px !important;
  margin-top: 7px;
  text-decoration: none !important;
}
@media (hover: hover) {
  .btn--table:hover {
    background: #1F0970;
    color: #fff;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 120;
  padding: 30px;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.1s, opacity 0.3s ease;
}
.modal.open {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}
.modal .link--underline-main {
  display: block;
  margin-bottom: 10px;
}
.modal p {
  margin-bottom: 20px;
}
.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 21;
  background-color: rgba(0, 0, 0, 0.7);
}
.modal__close {
  position: absolute;
  top: 0px;
  right: 0px;
  border: none;
  outline: none;
  background: none;
  width: 48px;
  height: 48px;
  display: inline-block;
  background: #fff;
  color: #000;
  padding: 0;
  z-index: 50;
}
.modal__close:after {
  content: "+";
  transform: rotate(45deg);
  font-size: 30px;
  position: absolute;
  top: 4px;
  left: 16px;
  transition: all 0.3s ease-out;
}
.modal__close:hover {
  color: #E6007E;
}
.modal__container {
  position: absolute;
  z-index: 22;
  max-width: 1520px;
  width: 96%;
  max-width: 700px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  overflow: hidden;
  background-color: #fff;
  border-radius: 5px;
}
.modal__body {
  max-height: 90vh;
  overflow: auto;
  padding: 50px;
}
.modal__title {
  margin-bottom: 30px;
  text-transform: uppercase;
  text-align: left;
  margin-top: 0;
}
.modal h4 {
  font-size: 31px;
  margin-bottom: 10px;
  color: #000;
}
@media (max-width: 992px) {
  .modal__title {
    font-size: 24px;
  }
  .modal h4 {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .modal__body {
    max-height: 90vh;
    overflow: auto;
    padding: 50px 25px;
  }
}
.modal ul {
  list-style: disc;
  padding-left: 30px;
}

.img__wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  height: 0;
}
.img__wrapper svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.img__wrapper img {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.page__des {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  margin-top: 50px;
  font-size: 1.25rem;
  text-align: center;
}

.sl-navigation button {
  width: 48px !important;
  text-indent: -500px !important;
  background-size: 70% !important;
}
.sl-navigation button:before {
  content: none;
}

.ul--square ul {
  padding-left: 0px;
  font-size: 0.875rem;
}
.ul--square li {
  position: relative;
  padding-left: 25px;
}
.ul--square li:before {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: #E6007E;
  content: "";
  position: absolute;
  top: 3px;
  left: 0;
}

.disabled {
  color: #959595;
}

fieldset {
  border: none;
  padding: 0;
}

.form-h-info {
  margin-top: 5px;
  font-size: 0.625rem;
  max-height: 0;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s ease-in-out;
}

.form__input input:focus + .form-h-info {
  max-height: 100px;
}

.form__select {
  padding: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 25px;
  z-index: 10;
  width: 100%;
}
.form__select label {
  color: #6C6C6C;
  font-size: 0.75rem;
  margin-bottom: 6px;
  font-weight: 700;
  display: block;
}
.form__select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid rgba(149, 149, 149, 0.8);
  border-radius: 12px;
  padding: 12px 45px 12px 15px;
  display: block;
  background: transparent;
  width: 100%;
  font-size: 1.125rem;
  line-height: 1.5;
  cursor: pointer;
}
.form__select select:focus, .form__select select:focus-visible {
  outline: 2px solid #000;
  border-color: #000;
  box-shadow: none;
  outline-offset: 0;
}
.form__select option {
  border: none;
  padding: 10px;
  font-size: 1rem;
  color: #212121;
  line-height: 40px;
}
.form__select:after {
  content: "";
  position: absolute;
  right: 15px;
  bottom: 23px;
  z-index: -1;
  width: 12px;
  height: 12px;
  display: block;
  border-left: 1px solid #212121;
  border-bottom: 1px solid #212121;
  transform: rotate(-45deg);
}
@media (max-width: 576px) {
  .form__select {
    width: 100%;
  }
  .form__select select {
    width: 100%;
  }
}

.is--desktop .custom-select select {
  display: none;
  /*hide original SELECT element: */
}

.custom-select {
  position: relative;
  z-index: 40;
}
.custom-select .select-selected {
  color: #000;
  border-radius: 5px;
  padding: 12px 45px 12px 15px;
  border: 1px solid #eaeaea;
  display: block;
  background: transparent;
  width: 100%;
  font-weight: 400;
  font-size: 1.125rem;
  background: #fff;
  z-index: 50;
  position: relative;
}
.custom-select .select-selected:after {
  content: "";
  position: absolute;
  right: 15px;
  bottom: 23px;
  z-index: -1;
  width: 12px;
  height: 12px;
  display: block;
  border-left: 1px solid #292827;
  border-bottom: 1px solid #292827;
  transform: rotate(-45deg);
}
.custom-select .select-items {
  position: absolute;
  background-color: #fff;
  padding-top: 5px;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 49;
  margin-top: -5px;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  overflow: hidden;
}
.custom-select .select-items.select-hide {
  display: none;
}
.custom-select .select-items div {
  padding: 8px 15px;
  cursor: pointer;
  font-size: 1.125rem;
}
@media (hover: hover) {
  .custom-select .select-items div:hover {
    background-color: #E6007E;
    color: #fff;
  }
}
.custom-select .same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}

.form__checkbox {
  margin-bottom: 0px;
  position: relative;
}
.form__checkbox input {
  position: absolute;
  left: 0;
  top: 0;
  padding: 0;
  z-index: -1000;
  opacity: 0;
  width: 0;
  height: 0;
}
.form__checkbox a {
  text-decoration: underline;
  font-weight: 500;
}
.form__checkbox label {
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 34px;
  line-height: 20px;
  color: #6C6C6C;
  font-size: 1.125rem;
  line-height: 1.4;
  cursor: pointer;
  display: inline-block;
  width: 100%;
}
.form__checkbox label:before {
  content: "";
  width: 24px;
  height: 24px;
  position: absolute;
  top: 12px;
  left: 0;
  display: block;
  border: 2px solid rgba(149, 149, 149, 0.8);
  background-color: #fff;
  transition: all 0.3s ease-out;
  border-radius: 4px;
}
.form__checkbox label:after {
  content: "";
  display: block;
  position: absolute;
  top: 19px;
  left: 7px;
  width: 0;
  height: 0px;
  transform: rotate(-45deg);
}
.form__checkbox input:checked ~ label:before {
  background-color: #E6007E;
  border-color: #E6007E;
}
.form__checkbox input:checked ~ label:after {
  width: 12px;
  height: 7px;
  border-bottom: 3px solid #fff;
  border-left: 3px solid #fff;
}
.form__checkbox input:focus-visible ~ label:before {
  outline: 2px solid #E6007E;
  outline-offset: 2px;
}
.form__checkbox input[type=radio] ~ label:before {
  border-radius: 50%;
}
.form__checkbox input[type=radio]:checked ~ label:after {
  width: 10px;
  height: 10px;
  border: none;
  border-radius: 50%;
  background: #E6007E;
  top: 4px;
  left: 5px;
}
.form__checkbox input[type=radio]:checked ~ label:before {
  background: #fff;
}
.form__checkbox.is--invalid label:before {
  border-color: #D93526;
}

.form__checkbox--small label {
  font-size: 0.75rem;
}

.form__checkbox--medium label {
  font-size: 1rem;
}

.input--data {
  background: url("../img/ico/ico-date-input.svg") right 15px center no-repeat;
}

.form__input {
  margin-bottom: 15px;
  position: relative;
}
.form__input label {
  font-size: 0.625rem;
  margin-bottom: 6px;
  font-weight: 700;
  color: #2FAC66;
  display: block;
  position: absolute;
  background: #fff;
  padding: 0 5px;
  top: 10px;
  z-index: -1;
  opacity: 0;
  transition: top 0.3s ease-out;
  transition: top 0.3s ease-out;
}
.form__input textarea {
  width: 100% !important;
}
.form__input input,
.form__input textarea {
  border-radius: 6px;
  border: 1px solid rgba(149, 149, 149, 0.8);
  width: 100%;
  display: block;
  color: #212121;
  font-size: 1.125rem;
  padding: 12px 15px;
  min-height: 54px;
}
.form__input input:focus, .form__input input:focus-visible,
.form__input textarea:focus,
.form__input textarea:focus-visible {
  border-color: #2FAC66;
  box-shadow: none;
  box-shadow: inset 0px 0px 0px 1px #2FAC66;
  outline-color: transparent;
}
.form__input input:focus::-moz-placeholder, .form__input input:focus-visible::-moz-placeholder, .form__input textarea:focus::-moz-placeholder, .form__input textarea:focus-visible::-moz-placeholder {
  opacity: 0;
}
.form__input input:focus:-ms-input-placeholder, .form__input input:focus-visible:-ms-input-placeholder, .form__input textarea:focus:-ms-input-placeholder, .form__input textarea:focus-visible:-ms-input-placeholder {
  opacity: 0;
}
.form__input input:focus::placeholder, .form__input input:focus-visible::placeholder,
.form__input textarea:focus::placeholder,
.form__input textarea:focus-visible::placeholder {
  opacity: 0;
}
.form__input input:not(:-moz-placeholder-shown) ~ label, .form__input textarea:not(:-moz-placeholder-shown) ~ label input:valid:required ~ label {
  opacity: 1;
  top: -5px;
  left: 20px;
  z-index: 5;
}
.form__input input:not(:-ms-input-placeholder) ~ label, .form__input textarea:not(:-ms-input-placeholder) ~ label input:valid:required ~ label {
  opacity: 1;
  top: -5px;
  left: 20px;
  z-index: 5;
}
.form__input input:focus ~ label, .form__input input:not(:placeholder-shown) ~ label,
.form__input textarea:focus ~ label, .form__input textarea:not(:placeholder-shown) ~ label input:valid:required ~ label {
  opacity: 1;
  top: -5px;
  left: 20px;
  z-index: 5;
}
.form__input.is--invalid label {
  color: #D93526;
}
.form__input.is--invalid input,
.form__input.is--invalid textarea {
  border-color: #D93526 !important;
  box-shadow: inset 0px 0px 0px 1px #D93526;
  background-image: none;
}
.form__input.is--invalid input:focus, .form__input.is--invalid input:focus-visible,
.form__input.is--invalid textarea:focus,
.form__input.is--invalid textarea:focus-visible {
  outline: 2px solid #D93526;
  border-color: #D93526;
  box-shadow: none;
}
.form__input.is--invalid:after {
  content: "+";
  transform: rotate(45deg);
  color: #D93526;
  position: absolute;
  top: -3px;
  right: 20px;
  font-size: 2.5rem;
}
.form__input.is--valid input,
.form__input.is--valid textarea {
  border-color: #2FAC66 !important;
  box-shadow: inset 0px 0px 0px 1px #2FAC66;
  background-image: none;
}
.form__input.is--valid:after {
  content: "";
  transform: rotate(-45deg);
  border-left: 2px solid #2FAC66;
  border-bottom: 2px solid #2FAC66;
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;
  width: 12px;
  height: 8px;
}
@media (max-width: 992px) {
  .form__input input,
.form__input textarea {
    font-size: 1rem;
  }
}

.form__input--bold label {
  font-size: 1.125rem;
  color: #000;
  font-weight: 700;
  margin-bottom: 10px;
}
.form__input--bold.is--valid:after {
  top: 52px;
}
.form__input--bold.is--invalid:after {
  top: 46px;
}

.form__nolabel.is--valid:after {
  top: 15px;
}
.form__nolabel.is--invalid:after {
  top: 7px;
}

.form__input--error {
  color: #D93526;
  font-size: 0.625rem;
  display: block;
  margin-top: 5px;
}

.form__checkbox .form__input--error {
  margin-bottom: 5px;
}

.form__select + .form__input--error {
  position: relative;
  top: -20px;
}

.form__switch {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  /* Hide default HTML checkbox */
  /* The slider */
  /* Rounded sliders */
}
.form__switch input {
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  z-index: -1;
}
.form__switch label {
  padding-right: 60px;
  line-height: 30px;
  display: inline-block;
  font-size: 1.25rem;
  position: relative;
}
.form__switch label:before {
  content: "";
  height: 30px;
  width: 50px;
  border-radius: 16px;
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-15px);
  right: 0;
  right: 0;
  bottom: 0;
  background-color: #F5F5F5;
  transition: 0.4s;
  z-index: 2;
  cursor: pointer;
}
.form__switch label:after {
  content: "";
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  right: 24px;
  bottom: 4px;
  border-radius: 11px;
  background-color: #959595;
  transition: 0.4s;
  z-index: 3;
  cursor: pointer;
}
.form__switch input:checked + label:before {
  background-color: #E6007E;
}
.form__switch input:checked + label:after {
  background: #fff;
}
.form__switch input:checked + label:after {
  right: 4px;
}

.modal .form__input input, .modal .form__input textarea {
  padding-top: 9px;
  padding-bottom: 9px;
  min-height: 48px;
}
.modal .form__input.is--invalid:after {
  top: -6px;
}
.modal .form__input.is--valid:after {
  top: 16px;
}

.form__nrInput label {
  display: block;
  width: 100%;
}
.form__nrInput input {
  max-width: 70px;
}

.footer {
  background: #000;
  color: #fff;
  font-size: 1rem;
}
.footer .line {
  border-color: rgba(255, 255, 255, 0.2);
  display: none;
}

.footer__top {
  padding-top: 110px;
  padding-bottom: 110px;
}

.footer__logo {
  width: 210px;
  max-width: 100%;
}

.footer__bg {
  background: #1A1A1A;
}

.footer__center {
  padding-top: 70px;
  padding-bottom: 0px;
}
.footer__center h3 {
  font-size: 1.125rem;
}
.footer__center p, .footer__center li {
  color: rgba(255, 255, 255, 0.65);
}
.footer__center a {
  color: rgba(255, 255, 255, 0.65);
  display: block;
  padding: 3px 0;
}
@media (hover: hover) {
  .footer__center a:hover {
    color: white;
  }
  .footer__center a:hover img {
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .social__bottom {
    padding-left: 15%;
  }
}

.social__link {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}
.social__link img {
  filter: invert(1);
  opacity: 0.65;
}

.footer__brand {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  line-height: 1;
}
.footer__brand a {
  display: inline-block;
  padding: 2px 5px;
}

.footer__bottom {
  padding-top: 50px;
  padding-bottom: 50px;
  opacity: 0.65;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  align-items: center;
  position: relative;
  z-index: 10;
}

@media (max-width: 576px) {
  .footer .line {
    display: block;
  }

  .footer__top {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .footer__center {
    padding-top: 40px;
  }

  .footer__bottom {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.breadcrumbs {
  padding-top: 10px;
  padding-bottom: 10px;
}
.breadcrumbs ul {
  display: flex;
  flex-wrap: wrap;
}
.breadcrumbs ul span,
.breadcrumbs ul a {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.6);
  display: inline-block;
  padding: 5px 0;
  line-height: 1;
  font-weight: 300;
}
.breadcrumbs ul span:before,
.breadcrumbs ul a:before {
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.6);
  content: "/";
}
.breadcrumbs ul li:first-child a:before {
  content: none;
}
.breadcrumbs ul a {
  margin-right: 5px;
}
.breadcrumbs ul a:focus, .breadcrumbs ul a:hover {
  outline: none;
  color: #E6007E;
}
.breadcrumbs ul a:focus:after, .breadcrumbs ul a:hover:after {
  color: rgba(0, 0, 0, 0.6);
}
@media (min-width: 768px) {
  .breadcrumbs ul a,
.breadcrumbs ul span {
    font-size: 14px;
  }
}

.container .breadcrumbs .container {
  padding-left: 0;
  padding-right: 0;
}

.section--first {
  padding-top: 60px;
}
@media (max-width: 768px) {
  .section--first {
    padding-top: 30px;
  }
}

.fixed-header-scroll.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background: #fff;
}
.fixed-header-scroll .headerT {
  display: none;
}
.fixed-header-scroll .headerB__wrapper {
  height: 60px;
}
.fixed-header-scroll .headerB__wrapper .btn--settings {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 48px;
}
.fixed-header-scroll .nav__level-1 > li > a {
  padding-bottom: 18px;
}
.fixed-header-scroll .menu__item--has-children:not(.menu2) .nav__level-2 {
  top: 59px;
}
.fixed-header-scroll .nav-toggle {
  top: 5px;
}

.headerT {
  background-image: linear-gradient(to right, #000 0%, #000 90%, #222121 90%, #222121 100%);
}

.header .container {
  max-width: 1900px;
  padding-left: 20px;
  padding-right: 20px;
}

.headerT__list {
  display: flex;
  justify-content: flex-end;
}

.headerT__item {
  background: #222121;
  border-left: 1px solid #000;
}
.headerT__item a {
  height: 48px;
  color: #fff;
  font-size: 14px;
  line-height: 48px;
  display: inline-block;
  padding: 0 20px;
}
.headerT__item:first-child {
  border-left: none;
  position: relative;
}
.headerT__item:first-child:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 24px solid transparent;
  border-bottom: 24px solid transparent;
  border-right: 30px solid #222121;
  position: absolute;
  right: 100%;
  top: 0;
}

.serachForm__btn {
  background: none;
  background: #222121;
  color: #FFF;
  font-size: 14px;
  line-height: 48px;
  height: 48px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
}
.serachForm__btn:before {
  content: "";
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url("../img/ico-search.svg") center no-repeat;
  background-size: 14px;
  margin-right: 5px;
}

.header__logo {
  padding-right: 30px;
  border-right: 1px solid #eaeaea;
  display: flex;
  align-items: center;
}

.headerB {
  border-bottom: 1px solid #eaeaea;
}

.headerB__wrapper {
  display: flex;
  height: 80px;
}

.headerB__social {
  display: flex;
  align-items: center;
  padding-left: 50px;
  padding-right: 30px;
}
.headerB__social .social__link img {
  opacity: 1;
  filter: none;
}
.headerB__social .social__link {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  height: 48px;
}

.headerB__other {
  display: flex;
  width: 100%;
}
.headerB__other li {
  border-left: 1px solid #eaeaea;
  padding-left: 20px;
  padding-right: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
}
.headerB__other li:last-child {
  margin-left: auto;
  padding-right: 0;
}
.headerB__other .btn {
  font-size: 14px;
  padding-right: 20px;
  padding-left: 20px;
}

.headerB__tel {
  padding-left: 35px;
  font-size: 1rem;
  display: block;
  background: url("../img/ico-phone.svg") 0px center no-repeat;
  background-size: 30px;
  line-height: 1;
}
.headerB__tel span {
  display: block;
  font-size: 0.875rem;
}
.headerB__tel strong, .headerB__tel span {
  white-space: nowrap;
}

.nav-toggle {
  width: 48px;
  min-width: 48px;
  height: 48px;
  position: relative;
  left: 10px;
  margin-left: 0px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  background: transparent;
  border: none;
  display: none;
  top: 15px;
}

.nav-toggle span {
  display: block;
  position: absolute;
  height: 2px;
  width: 25%;
  background: #000;
  opacity: 1;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
  border-radius: 2px;
}

.nav-toggle span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

.nav-toggle span:nth-child(odd) {
  left: 25%;
  border-radius: 9px 0 0 9px;
}

.nav-toggle span:nth-child(1),
.nav-toggle span:nth-child(2) {
  top: 12px;
}

.nav-toggle span:nth-child(3),
.nav-toggle span:nth-child(4) {
  top: 24px;
}

.nav-toggle span:nth-child(5),
.nav-toggle span:nth-child(6) {
  top: 36px;
}

.nav-toggle.open span:nth-child(1),
.nav-toggle.open span:nth-child(6) {
  transform: rotate(45deg);
}

.nav-toggle.open span:nth-child(2),
.nav-toggle.open span:nth-child(5) {
  transform: rotate(-45deg);
}

.nav-toggle.open span:nth-child(1) {
  left: 15px;
  top: 20px;
}

.nav-toggle.open span:nth-child(2) {
  left: 50%;
  top: 20px;
}

.nav-toggle.open span:nth-child(3) {
  left: calc(50% - 1px);
  opacity: 0;
}

.nav-toggle.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

.nav-toggle.open span:nth-child(5) {
  left: 15px;
  top: 29px;
}

.nav-toggle.open span:nth-child(6) {
  left: calc(50% - 1px);
  top: 29px;
}

.nav__level-1 {
  display: flex;
  align-items: flex-end;
  height: 100%;
}
.nav__level-1 > li > a {
  white-space: nowrap;
  padding: 10px 10px 25px 10px;
  font-size: 1rem;
  font-weight: 500;
  display: block;
}
.nav__level-1 > li > a.active {
  color: #E6007E;
}

.nav {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.serachForm__fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: #E6007E;
  width: 100%;
  top: -130px;
  transition: top 0.3s ease-out;
}
.serachForm__fixed.open {
  top: 0;
}
.serachForm__fixed .container {
  height: 130px;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  /* clears the 'X' from Internet Explorer */
  /* clears the 'X' from Chrome */
}
.serachForm__fixed .container input {
  background: none;
  border: none;
  padding-left: 70px;
  padding-right: 50px;
  width: 100%;
  color: #fff;
  font-size: 40px;
  -webkit-appearance: none;
}
.serachForm__fixed .container input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
.serachForm__fixed .container input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}
.serachForm__fixed .container input[type=search]::-webkit-search-decoration,
.serachForm__fixed .container input[type=search]::-webkit-search-cancel-button,
.serachForm__fixed .container input[type=search]::-webkit-search-results-button,
.serachForm__fixed .container input[type=search]::-webkit-search-results-decoration {
  display: none;
}
.serachForm__fixed .container ::-moz-placeholder {
  color: #fff;
}
.serachForm__fixed .container :-ms-input-placeholder {
  color: #fff;
}
.serachForm__fixed .container ::placeholder {
  color: #fff;
}
.serachForm__fixed .container .serachForm__btn {
  position: absolute;
  left: 10px;
  top: 50%;
  margin-top: -24px;
  font-weight: 300;
  height: 48px;
}
.serachForm__fixed .container .serachForm__btn:before {
  background-size: 30px;
}

.serachForm__close {
  color: #fff;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 50px;
  transform: rotate(45deg);
  margin-top: -30px;
  font-weight: 300;
  right: 10px;
  background: none;
  line-height: 1;
  border: none;
}

.page__header {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 50px;
  min-height: 180px;
  display: flex;
  align-items: center;
}
.page__header .breadcrumbs {
  margin-top: 20px;
  padding-bottom: 0;
}
.page__header h1 {
  margin-bottom: 0;
  font-size: 2.5rem;
  display: flex;
  gap: 10px;
  align-items: center;
}
.page__header img {
  min-height: 65px;
  width: auto;
}

@media (max-width: 1800px) {
  .headerB__social {
    padding-left: 10px;
    padding-right: 10px;
  }

  .headerB__other li {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header-ue img {
    max-width: 120px;
  }
}
@media (max-width: 1600px) {
  .headerB__social {
    padding-left: 10px;
    padding-right: 10px;
  }

  .headerB__other li {
    padding-left: 15px;
    padding-right: 15px;
  }

  .headerB__social .social__link {
    min-width: 38px;
  }
}
@media (max-width: 1500px) {
  .headerB__social {
    display: none;
  }

  .headerB__other {
    width: initial;
    margin-left: auto;
  }
}
@media (max-width: 1400px) {
  .headerB__tel {
    height: 50px;
    padding-left: 50px;
    background-position: 10px center;
  }
  .headerB__tel span, .headerB__tel strong {
    display: none;
  }

  .header .btn--settings {
    background-color: #fff !important;
    width: 50px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .header .btn--settings span {
    display: none;
  }
  .header .btn--settings:before {
    margin-right: 0;
    content: url("../img/ico-settings2.svg");
  }

  .serachForm__fixed .container input {
    font-size: 30px;
  }
}
@media (max-width: 1200px) {
  .nav-toggle {
    display: block;
  }

  .nav {
    display: none;
  }

  .header__logo {
    border: none;
  }

  .page__header h1 {
    font-size: 2.125rem;
  }
}
@media (max-width: 768px) {
  .header__logo img {
    max-width: 140px;
  }

  .headerB__other li {
    padding-left: 10px;
    padding-right: 10px;
    border-right: 1px solid #eaeaea;
  }

  .serachForm__fixed .container input {
    font-size: 20px;
  }

  .serachForm__fixed .container .serachForm__btn:before {
    background-size: 20px;
  }

  .page__header {
    margin-bottom: 25px;
  }
  .page__header h1 {
    font-size: 1.75rem;
  }
}
@media (max-width: 576px) {
  .header .header-ue {
    display: none;
  }

  .headerT {
    background: #222121;
  }
  .headerT .container {
    padding-right: 0;
  }
  .headerT a, .headerT .serachForm__btn {
    font-size: 11px;
    height: 30px;
    line-height: 30px;
  }

  .headerB__wrapper {
    height: 70px;
  }

  .headerT__item:first-child:before {
    content: none;
  }

  .nav-toggle {
    top: 10px;
  }

  .page__header {
    min-height: 120px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .page__header .breadcrumbs {
    margin-top: 10px;
  }
  .page__header h1 {
    margin-bottom: 0;
    font-size: 1.375rem;
  }
  .page__header img {
    min-height: 44px;
  }
}
@media (max-width: 400px) {
  .header__logo {
    padding-right: 20px;
  }

  .headerB__other li {
    padding-left: 5px;
    padding-right: 5px;
  }

  .headerT .container {
    padding-left: 0;
  }
  .headerT a {
    padding: 0 10px;
  }

  .headerT .serachForm__btn {
    padding-left: 10px;
  }
}
@media (max-width: 365px) {
  .header__logo img {
    max-width: calc(100vw - 235px);
  }
}
@media (min-width: 1201px) {
  .nav {
    display: flex;
  }

  .nav__wrapper {
    height: 100%;
  }

  .menu__item--has-children:not(.menu2) .nav__level-2 {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    position: fixed;
    display: none;
    top: 127px;
    width: 40vw;
    left: 0;
    background: #F5F5F5;
    z-index: 99;
    padding-left: 20px;
    padding-bottom: 20px;
  }
  .menu__item--has-children:not(.menu2) .nav__level-2:before {
    content: "";
    width: 100vw;
    left: 0;
    top: 0;
    height: 1px;
    background: #eaeaea;
    display: block;
  }
  .menu__item--has-children:not(.menu2) .nav__level-2 li {
    position: static;
  }
  .menu__item--has-children:not(.menu2) .nav__level-2 > li {
    width: 100%;
  }
  .menu__item--has-children:not(.menu2) .nav__level-2 > li.open-l > a:after {
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-left: 10px solid #F5F5F5;
    border-bottom: 10px solid transparent;
    position: absolute;
    bottom: 16px;
    right: -10px;
    z-index: 20;
  }
  .menu__item--has-children:not(.menu2) .nav__level-2 > li > a {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 10px 0;
    background: #F5F5F5;
    z-index: 30;
    padding-left: calc(100% - 410px);
    position: relative;
  }
  .menu__item--has-children:not(.menu2) .nav__level-2 > li > a img {
    filter: brightness(0);
    transition: all 0.3s ease-out;
  }
  .menu__item--has-children:not(.menu2) .nav__level-2 > li > a.active, .menu__item--has-children:not(.menu2) .nav__level-2 > li > a:hover {
    color: #E6007E;
  }
  .menu__item--has-children:not(.menu2) .nav__level-2 > li > a.active img, .menu__item--has-children:not(.menu2) .nav__level-2 > li > a:hover img {
    filter: none;
  }
  .menu__item--has-children:not(.menu2) .nav__level-2 > li:first-child > a {
    padding-top: 25px;
  }
  .menu__item--has-children:not(.menu2) .nav__level-3 {
    width: 125%;
    background: #fff;
    height: 100%;
    left: 100%;
    position: absolute;
    top: 1px;
    padding-left: 50px;
    z-index: 10;
    display: none;
  }
  .menu__item--has-children:not(.menu2) .nav__level-3.open {
    z-index: 20;
    display: block;
  }
  .menu__item--has-children:not(.menu2) .nav__level-3:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 80vw;
    height: 100%;
    background: #fff;
    z-index: -1;
  }
  .menu__item--has-children:not(.menu2) .nav__level-3 h3 {
    font-size: 24px;
    padding-left: 35px;
    position: relative;
    margin-bottom: 30px;
    margin-top: 40px;
  }
  .menu__item--has-children:not(.menu2) .nav__level-3 h3:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: #E6007E;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 0;
  }
  .menu__item--has-children:not(.menu2) .nav__level-3 ul {
    display: flex;
    margin-bottom: 35px;
    max-width: 900px;
    gap: 20px;
  }
  .menu__item--has-children:not(.menu2) .nav__level-3 ul li {
    flex: 0 0 25%;
    max-width: calc(25% - 15px);
  }
  .menu__item--has-children:not(.menu2) .nav__level-3 ul li a {
    background: #F5F5F5;
    border-radius: 5px;
    padding: 10px 20px;
    display: block;
    height: 100%;
  }
  .menu__item--has-children:not(.menu2) .nav__level-3 ul li figure {
    padding-top: 100%;
    height: 0;
    position: relative;
    margin-bottom: 10px;
  }
  .menu__item--has-children:not(.menu2) .nav__level-3 ul li figure img {
    width: 90%;
    height: 90%;
    -o-object-fit: contain;
       object-fit: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  .menu__item--has-children:not(.menu2) .nav__level-3 ul li h4 {
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0;
  }
  .menu__item--has-children:not(.menu2) .nav__level-3 ul li h4 span {
    color: #E6007E;
  }
  .menu__item--has-children:not(.menu2) .nav__level-3 ul li p {
    font-size: 0.875rem;
  }

  .menu2 {
    position: relative;
  }
  .menu2 .nav__level-2 {
    position: absolute;
    display: none;
    top: 100%;
    width: 205px;
    background: #F5F5F5;
    padding: 10px 0px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    z-index: 90;
  }
  .menu2 .nav__level-2 a {
    font-size: 0.875rem;
    font-weight: 500;
    display: block;
    padding: 7px 20px;
  }

  .nav-overlay {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
    z-index: 2;
  }
}
@media (max-width: 1200px) {
  .nav {
    position: fixed;
    top: 129px;
    width: 100vw;
    left: 0;
    height: calc(100vh - 140px);
    overflow: auto;
    background: #F5F5F5;
    padding: 10px 0 60px 0;
    z-index: 100;
  }

  .headerB__social {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 5px;
    margin-top: 15px;
  }
  .headerB__social .social__link {
    min-width: 48px;
  }

  .nav__level-1 {
    display: block;
  }
  .nav__level-1 a {
    width: 100%;
    padding: 10px 20px;
    font-size: 1rem;
  }
  .nav__level-1 > li {
    border-bottom: 1px solid #eaeaea;
  }
  .nav__level-1 > li > a {
    font-size: 1.25rem;
    padding: 10px 20px;
    padding-bottom: 10px !important;
    width: 80%;
  }
  .nav__level-1 > .menu__item--has-children {
    position: relative;
  }
  .nav__level-1 > .menu__item--has-children:before, .nav__level-1 > .menu__item--has-children:after {
    content: "";
    position: absolute;
    right: 20px;
    top: 24px;
    background: #000;
    transition: all 0.3s ease-out;
    transform-origin: center;
    width: 20px;
    display: block;
    height: 2px;
  }
  .nav__level-1 > .menu__item--has-children:after {
    transform: rotate(-90deg);
  }
  .nav__level-1 > .menu__item--has-children.open:after {
    transform: rotate(0deg);
  }

  .nav__level-2 {
    display: none;
  }
  .nav__level-2 a {
    display: flex;
    align-items: center;
    gap: 15px;
    font-weight: 500;
    padding: 10px 0 10px 30px;
    width: 100%;
  }

  .nav__level-3 {
    display: none;
  }

  .fixed-header-scroll .nav {
    top: 60px;
    height: calc(100vh - 60px);
  }
}
@media (min-width: 1200px) and (max-width: 1960px) {
  .menu__item--has-children:not(.menu2) .nav__level-2 {
    width: 700px;
  }
  .menu__item--has-children:not(.menu2) .nav__level-3 {
    width: calc(100vw - 740px);
  }
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .menu__item--has-children:not(.menu2) .nav__level-2 {
    width: 430px;
  }
  .menu__item--has-children:not(.menu2) .nav__level-2 > li > a {
    padding-left: 10px;
  }
  .menu__item--has-children:not(.menu2) .nav__level-3 {
    width: calc(100vw - 470px);
  }
}
@media (max-width: 576px) {
  .nav {
    top: 100px;
  }
}
.headerP {
  min-height: 540px;
  display: flex;
  align-items: center;
  font-size: 2.3125rem;
  position: relative;
  color: #fff;
}
.headerP p {
  max-width: 1200px;
}

.headerP__img {
  position: absolute;
  top: 0;
  width: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
}

@media (max-width: 992px) {
  .headerP {
    min-height: 400px;
    font-size: 1.875rem;
  }
}
@media (max-width: 576px) {
  .headerP {
    align-items: flex-end;
    min-height: 260px;
    font-size: 1.375rem;
    padding-bottom: 20px;
  }
}
#scrollDown {
  position: absolute;
  bottom: 60px;
  right: calc(50vw - 25px);
  z-index: 20;
  color: #E6007E;
  height: 50px;
  width: 50px;
  background: none;
  border: none;
  transition: none;
}
#scrollDown:before {
  content: "";
  width: 13px;
  height: 13px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  display: block;
  top: 50%;
  left: 50%;
  transform-origin: center;
  transform: translate(-50%, -50%) rotate(-45deg);
  position: absolute;
}

.hero {
  position: relative;
}
.hero .swiper-slide {
  height: 960px;
  max-height: 90vh;
  padding-bottom: 100px;
}
.hero .link--underline {
  color: #fff;
}
.hero .container {
  height: 100%;
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 12;
  color: #fff;
}
@media (min-width: 576px) {
  .hero .swiper-pagination {
    text-align: right;
    left: 50% !important;
    transform: translateX(-50%);
    bottom: 60px !important;
    max-width: 1800px;
    padding-right: 80px;
    padding-right: 80px;
  }
}
.hero .swiper-pagination-bullet {
  background: #fff !important;
}
.hero .swiper-button-prev:after, .hero .swiper-button-next:after {
  filter: brightness(0) invert(1);
}
@media (min-width: 992px) {
  .hero .swiper-button-next {
    right: 80px;
  }
  .hero .swiper-button-prev {
    left: 80px;
  }
}
.hero .swiper-slide:before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  display: block;
  width: 100%;
  height: 40%;
  z-index: 5;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.19), transparent);
}
.hero .swiper-slide:after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  display: block;
  width: 100%;
  height: 40%;
  z-index: 5;
  background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.19));
}

.hero__p {
  font-size: 20px;
  margin-bottom: 20px;
}

.hero__img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
}

.hero__btns {
  display: flex;
  align-items: center;
  gap: 10px 30px;
  flex-wrap: wrap;
}

@media (max-width: 1700px) {
  .hero .swiper-slide {
    height: 800px;
  }
}
@media (max-width: 992px) {
  .hero .swiper-slide {
    height: 700px;
  }

  .hero__p {
    font-size: 1.125rem;
    margin-bottom: 10px;
  }
}
@media (max-width: 576px) {
  .hero__p {
    font-size: 0.875rem;
  }

  .hero .title {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  .hero .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .hero .swiper-slide {
    height: 460px;
    padding-bottom: 70px;
  }
  .hero .swiper-button-prev, .hero .swiper-button-next {
    display: none;
  }

  .hero__btns {
    flex-direction: column;
    justify-content: center;
  }

  #scrollDown {
    bottom: 30px;
  }
}
.partners {
  padding-top: 80px;
  padding-bottom: 50px;
}

.partners__list {
  display: flex;
  gap: 10px 20px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
}

.partners__item {
  display: flex;
  justify-content: center;
  align-items: center;
}
.partners__item img {
  max-height: 90px;
  max-width: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

@media (max-width: 576px) {
  .partners {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.contactW__section {
  position: relative;
  min-height: 950px;
  padding-top: 100px;
  padding-bottom: 100px;
  color: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.contactW__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
}

.contactW__left {
  max-width: 50%;
}
.contactW__left p {
  margin-bottom: 40px;
}

@media (max-width: 992px) {
  .contactW__section {
    min-height: 480px;
    padding-top: 100px;
    align-items: flex-end;
    padding-bottom: 45px;
  }

  .contactW__left {
    max-width: 100%;
  }
}
.hssp__section {
  position: relative;
  padding-top: 140px;
  padding-bottom: 80px;
  color: #fff;
  overflow: hidden;
}

.hssp__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: -1;
}

.hssp__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  margin-top: 70px;
}

.hssp__list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.hssp__item {
  position: relative;
  padding-left: 65px;
  line-height: 1.2;
}
.hssp__item img {
  position: absolute;
  top: 0;
  left: 0;
}
.hssp__item strong {
  display: block;
  font-size: 1.25rem;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 5px;
}
.hssp__item span {
  color: rgba(255, 255, 255, 0.65);
  display: block;
  font-size: 1rem;
}

@media (max-width: 576px) {
  .hssp__section {
    padding-top: 80px;
  }

  .hssp__wrapper {
    margin-top: 40px;
  }
}
.characteristics {
  background: #2E67B0;
  color: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
}

.characteristics__list {
  display: flex;
  justify-content: space-between;
  gap: 20px 0px;
}

.characteristics__item {
  flex: 0 0 11%;
  position: relative;
  text-align: center;
}
.characteristics__item img {
  max-width: 100%;
}
.characteristics__item span {
  display: block;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.2;
  margin-top: 15px;
}

@media (max-width: 1200px) {
  .characteristics__item span {
    font-size: 0.6875rem;
  }
}
@media (max-width: 992px) {
  .characteristics__list {
    flex-wrap: wrap;
    justify-content: center;
  }

  .characteristics__item {
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media (max-width: 576px) {
  .characteristics__item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}
.pr__nav {
  position: relative;
  margin-bottom: 40px;
}
@media (max-width: 576px) {
  .pr__nav {
    margin-bottom: 20px;
  }
}

.pr__nav-slider {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  background: #E6007E;
  height: 10px;
  width: 70px;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  z-index: 10;
}

.pr__nav--count {
  position: relative;
}
.pr__nav--count:after {
  content: "";
  width: 100%;
  height: 10px;
  background: #D3D3D3;
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: -1;
  display: block;
  border-radius: 5px;
  z-index: 1;
}

.tabs__navlist {
  display: inline-flex;
  gap: 10px;
  position: relative;
  max-width: 100%;
  z-index: 1;
}
@media (min-width: 1200px) {
  .tabs__navlist {
    gap: 20px;
  }
}

.tabs__nav-trigger {
  padding: 10px 5px 30px 5px;
  border: none;
  background: none;
  display: inline-block;
  font-size: 1.125rem;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 700;
}
@media (hover: hover) {
  .tabs__nav-trigger:hover {
    color: #000;
  }
}
.tabs__nav-trigger[aria-selected=true] {
  color: #000;
}

.tabs--links .pr__nav-slider {
  display: none;
}
.tabs--links .tabs__nav-item.is-active a {
  color: #000;
  position: relative;
  padding-left: 0;
  padding-right: 0;
}
.tabs--links .tabs__nav-item.is-active a:after {
  content: "";
  width: 100%;
  height: 10px;
  background: #E6007E;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  border-radius: 5px;
  z-index: 20;
}

.tabs__panel, .tabs2__panel {
  display: none;
  margin-bottom: 10px;
}
.tabs__panel.is-current, .tabs2__panel.is-current {
  display: block;
}
@media (min-width: 768px) {
  .tabs__panel, .tabs2__panel {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .tabs__nav-trigger {
    font-size: 1rem;
  }
}
.blog__list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 30px;
}

.blog__item {
  flex: 0 0 100%;
  padding: 0;
  height: 100%;
}
.blog__item .blog__link {
  display: block;
  height: 100%;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
  outline: 2px solid transparent;
  background: #fff;
}

.blog__link--first {
  position: relative;
}
.blog__link--first .blog__figure {
  padding-top: 63%;
  margin-bottom: 0;
}
.blog__link--first .blog__txt {
  z-index: 30;
  background: #fff;
  border-radius: 5px;
}
@media (min-width: 576px) {
  .blog__link--first .blog__txt {
    position: absolute;
    bottom: 20px;
    width: calc(50% - 40px);
    right: 20px;
  }
  .blog__link--first .blog__figure {
    border-radius: 5px;
    height: 100%;
  }
  .blog__link--first .blog__figure img {
    border-radius: 5px;
  }
}
@media (max-width: 576px) {
  .blog__link--first .blog__figure {
    width: 100vw;
    margin-left: -30px;
    border: none;
  }
  .blog__link--first .blog__txt {
    position: relative;
    top: -20px;
  }
}

.blog__item--r {
  max-width: calc(33.33% - 20px);
  flex: 0 0 33.33%;
}

.blog__figure {
  width: 100%;
  padding-top: 83%;
  position: relative;
  overflow: hidden;
  height: 0;
  margin-bottom: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.blog__figure img {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  position: absolute;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 2;
}

.blog__title {
  font-size: 1.875rem;
  font-weight: 700;
  min-height: 75px;
  margin-bottom: 15px;
}

.blog__txt {
  padding: 20px 30px;
}

.blog__summary {
  font-size: 1.125rem;
  color: #000;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

@media (hover: hover) {
  .blog__link:hover {
    outline-color: #E6007E;
  }
}

.blogM {
  overflow: hidden;
  padding-top: 90px;
  padding-bottom: 40px;
  background: #F5F5F5;
}

.blogSwiper {
  position: relative;
  margin-left: -10px;
}
.blogSwiper .swiper-nav {
  padding-left: 20px;
}

.blogSwiper .swiper {
  width: calc(100vw - 80px);
}
@media (min-width: 1680px) {
  .blogSwiper .swiper {
    width: calc(50vw + 830px);
  }
}
@media (max-width: 768px) {
  .blogSwiper .swiper {
    width: calc(100vw - 10px);
  }
}

.blogSwiper .swiper-slide {
  padding: 10px;
  height: auto;
  max-width: 28%;
}

@media (max-width: 1500px) {
  .blog__title {
    font-size: 25px;
    min-height: 80px;
  }
}
@media (max-width: 1200px) {
  .blog__title {
    font-size: 20px;
    min-height: 55px;
  }

  .blogSwiper .swiper-slide {
    max-width: 40%;
  }
}
@media (max-width: 768px) {
  .blog__title {
    font-size: 17px;
    min-height: 44px;
  }

  .blog__txt {
    padding: 20px;
  }
}
@media (max-width: 576px) {
  .blogSwiper .swiper-slide {
    max-width: 75%;
  }

  .blogM {
    padding-top: 30px;
  }
}
.swiper-pagination-bullet {
  background: #E6007E !important;
  opaxcity: 0.5;
  width: 12px !important;
  height: 12px !important;
  border-radius: 0 !important;
}

.swiper-pagination-bullet-active {
  opacity: 1 !important;
}

body .swiper-button-prev, body .swiper-button-next {
  width: 48px;
  height: 48px;
}
body .swiper-button-next:after {
  content: "" !important;
  width: 48px;
  height: 48px;
  background: url("../img/swiper-right-b.svg") center no-repeat;
  background-size: 16px;
}
body .swiper-button-prev:after {
  content: "" !important;
  width: 48px;
  height: 48px;
  background: url("../img/swiper-left-b.svg") center no-repeat;
  background-size: 16px;
}

.swiper-nav {
  max-width: 500px;
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
}
.swiper-nav .swiper-scrollbar {
  height: 10px;
  width: 100%;
  cursor: pointer;
}
.swiper-nav .swiper-scrollbar-drag {
  background: #3D3D3C;
}
.swiper-nav .swiper-button-prev, .swiper-nav .swiper-button-next {
  position: relative;
  transform: none;
  top: 0;
  margin: 0;
}
.swiper-nav .swiper-button-prev {
  left: 0;
}
.swiper-nav .swiper-button-next {
  right: 0;
}
@media (max-width: 576px) {
  .swiper-nav {
    margin-top: 30px;
  }
}

.swiper-button-disabled {
  opacity: 0.08 !important;
}

.swiper-nav2 .swiper-button-prev, .swiper-nav2 .swiper-button-next {
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-nav2 .swiper-button-prev:after, .swiper-nav2 .swiper-button-next:after {
  content: "";
  width: 10px;
  height: 10px;
  display: block;
  border-left: 2px solid #000;
  border-bottom: 2px solid #000;
  transform: rotate(45deg);
  margin: 0;
  background-image: none;
}
.swiper-nav2 .swiper-button-prev {
  left: -20px;
  margin-top: -32px;
}
.swiper-nav2 .swiper-button-next {
  right: -20px;
  margin-top: -32px;
}
.swiper-nav2 .swiper-button-next:after {
  transform: rotate(-135deg);
}

.configuration {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  position: relative;
}

.configuration__left,
.configuration__right {
  width: 100%;
  position: relative;
}

.configuration__left {
  padding-top: 80px;
  padding-bottom: 40px;
  padding-right: 20px;
  margin-left: auto;
}
.configuration__left h3 {
  font-size: 2.5rem;
}
.configuration__left h4 {
  font-weight: 400;
  text-transform: uppercase;
  font-size: 1.25rem;
  margin-bottom: 50px;
}

.configuration__list {
  display: flex;
  gap: 30px;
  margin-bottom: 70px;
}

.configuration__item strong {
  display: block;
  font-size: 1.25rem;
}
.configuration__item span {
  color: rgba(0, 0, 0, 0.6);
}

.tabSwiper {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 60px;
}
.tabSwiper .swiper-slide {
  width: auto;
}
@media (max-width: 1700px) {
  .tabSwiper {
    margin-bottom: 40px;
  }
}
@media (max-width: 1200px) {
  .tabSwiper {
    margin-bottom: 30px;
  }
}

.configurationImgWrapper {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.configurationImgWrapper img {
  max-width: 500px;
  width: 94%;
}

.configuration__wrapperBack {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.configuration__wrapperBack img {
  max-width: 500px;
  width: 94%;
}

.configuration-clipping-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: 10;
  display: block;
  background: #fff;
}
.configuration-clipping-wrapper:after {
  content: "";
  display: block;
  width: 100px;
  height: 100%;
  left: 100%;
  top: 0;
  height: 100%;
  position: absolute;
  z-index: 10;
  background-image: linear-gradient(to right, rgba(51, 87, 190, 0.1), transparent);
}
.configuration-clipping-wrapper .configuration__wrapper {
  overflow: hidden;
  height: 100%;
}

.configuration__move {
  width: 14px;
  height: 30px;
  border-radius: 7px;
  background: #2E67B0;
  position: absolute;
  top: 10%;
  right: 0;
  transform: translate(7px, 10px);
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  z-index: 20;
}
.configuration__move:before {
  content: "||";
  font-size: 12px;
  font-weight: 700;
}

.configuration__imgLeft--2 {
  opacity: 1;
}
@media (min-width: 576px) {
  .configuration__imgLeft--2 .hero__txt {
    max-width: 360px;
  }
}

@media (min-width: 768px) {
  .configuration__left {
    flex: 0 0 50%;
    width: 50%;
    height: 100%;
    justify-content: flex-end;
  }

  .configuration__right {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .configuration-clipping-wrapper .configurationImgWrapper {
    width: 50vw;
  }

  .configuration__wrapperBack {
    width: 50vw;
  }
}
@media (max-width: 1500px) {
  .configuration__left h3 {
    font-size: 2.125rem;
  }
  .configuration__left h4 {
    font-size: 1.125rem;
  }
}
@media (max-width: 1200px) {
  .configuration__left h3 {
    font-size: 1.875rem;
    margin-bottom: 10px;
  }
  .configuration__left h4 {
    font-size: 1rem;
    margin-bottom: 30px;
  }

  .configuration__list {
    margin-bottom: 40px;
    flex-wrap: wrap;
  }

  .configuration__item {
    max-width: calc(50% - 15px);
    flex: 0 0 50%;
  }
}
@media (max-width: 768px) {
  .configuration__left h3 {
    font-size: 1.625rem;
  }
  .configuration__left h4 {
    font-size: 0.875rem;
    margin-bottom: 15px;
  }
}
@media (max-width: 576px) {
  .configuration__left {
    padding-top: 40px;
  }
  .configuration__left .btn--settings {
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}
.swiper-wrapper.tabs__navlist {
  gap: 0;
}
.swiper-wrapper.tabs__navlist .tabs__nav-trigger {
  padding-left: 0;
  padding-right: 0;
}

.about__section {
  padding-top: 140px;
  padding-bottom: 140px;
  position: relative;
  overflow: hidden;
}
.about__section:after {
  content: "";
  display: block;
  width: 140px;
  height: 140px;
  position: absolute;
  right: 50%;
  bottom: 0;
  background: #E6007E;
}

.about__left {
  width: 50%;
  max-width: 670px;
  padding-right: 40px;
  padding-bottom: 100px;
}
.about__left p {
  font-size: 1.25rem;
}

.about__imgRight {
  position: absolute;
  left: 50%;
  top: 0;
  height: 730px;
  width: auto;
}

.about__accordion {
  padding-top: 70px;
}

@media (max-width: 992px) {
  .about__accordion {
    padding-top: 20px;
  }

  .about__left {
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    padding-bottom: 40px;
  }

  .about__imgRight {
    position: static;
    width: 100%;
    height: auto;
    margin-left: 30px;
  }

  .about__section:after {
    width: 110px;
    height: 110px;
    right: initial;
    left: 0;
    bottom: 30px;
  }
}
@media (max-width: 768px) {
  .about__section:after {
    width: 60px;
    height: 60px;
    left: 0;
    bottom: 80px;
  }
}
@media (max-width: 576px) {
  .about__section {
    padding-top: 50px;
  }
  .about__section .title {
    text-align: center;
  }
  .about__section .container > p {
    text-align: center;
  }
}
@media (max-width: 375px) {
  .about__section:after {
    width: 50px;
  }
}
.accordion__content {
  display: none;
  padding: 0;
}
.accordion__content p, .accordion__content ul, .accordion__content ol {
  margin-bottom: 20px;
}

.accordion {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  position: relative;
  padding: 15px 0;
}
.accordion:after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -1px;
  background: #E6007E;
  transition: all 0.3s ease-out;
  transform-origin: center;
  width: 0px;
  display: block;
  height: 1px;
}
@media (hover: hover) {
  .accordion:hover:after {
    width: 50px;
  }
}
.accordion.active:after {
  width: 80px;
}

.accordion__trigger {
  border: none;
  width: 100%;
  display: block;
  position: relative;
  text-align: left;
  background: transparent;
  font-size: 1.875rem;
  font-weight: 400;
  padding: 20px 50px 20px 0px;
  color: #000;
}
.accordion__trigger:before, .accordion__trigger:after {
  content: "";
  position: absolute;
  right: 0px;
  top: 50%;
  background: #E6007E;
  transition: all 0.3s ease-out;
  transform-origin: center;
  width: 25px;
  display: block;
  height: 1px;
}
.accordion__trigger:after {
  transform: rotate(-90deg);
}
.accordion__trigger .hide {
  display: none;
}
.accordion__trigger .show {
  display: inline;
}

.accordion__trigger[aria-expanded=true]:after {
  transform: rotate(0deg);
}

.accordion2 {
  border: none;
}
.accordion2:after {
  content: none;
}

.accordion2 {
  padding: 0;
}
.accordion2 .accordion__trigger {
  padding: 15px 0px 15px 50px;
  font-size: 1.125rem;
  font-weight: 400;
}
.accordion2 .accordion__trigger:before, .accordion2 .accordion__trigger:after {
  right: initial;
  left: 0;
  background: #000;
}
@media (hover: hover) {
  .accordion2 .accordion__trigger:hover {
    color: #E6007E;
  }
  .accordion2 .accordion__trigger:hover:before, .accordion2 .accordion__trigger:hover:after {
    background: #E6007E;
  }
}

.accordion__inner {
  padding-bottom: 10px;
}

.accordion3 {
  background: #F5F7F8;
  padding: 0 20px;
  border-radius: 5px;
  border-radius: 5px;
  border: none;
  margin-bottom: 20px;
}
.accordion3:after {
  content: none;
}
.accordion3 .accordion__trigger {
  font-size: 1.25rem;
  font-weight: 700;
  padding: 20px 30px 20px 0;
}
.accordion3 .accordion__trigger:after {
  content: none;
}
.accordion3 .accordion__trigger:before {
  width: 12px;
  height: 12px;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  transform-origin: center;
  transform: rotate(-45deg);
  background: transparent;
  top: 23px;
}
.accordion3 .accordion__trigger[aria-expanded=true]:before {
  transform: rotate(135deg);
  top: 30px;
}

.accordion4 {
  background: transparent;
  padding: 0;
  margin-bottom: 0px;
  margin-top: 0px;
  border-radius: 0;
  border-bottom: 1px solid #eaeaea;
}
.accordion4 .accordion__trigger {
  font-weight: 400;
}

@media (max-width: 1500px) {
  .accordion__trigger {
    font-size: 1.75rem;
  }
}
@media (max-width: 1200px) {
  .accordion__trigger {
    font-size: 1.625rem;
  }
}
@media (max-width: 768px) {
  .accordion__trigger {
    font-size: 1.5rem;
    padding-top: 14px;
    padding-bottom: 14px;
  }
}
.offertSwiper .swiper-slide {
  height: auto;
  width: 22%;
}
.offertSwiper .swiper {
  width: calc(100vw - 80px);
}
@media (min-width: 1680px) {
  .offertSwiper .swiper {
    width: calc(50vw + 820px);
  }
}
@media (max-width: 768px) {
  .offertSwiper .swiper {
    width: calc(100vw - 10px);
  }
}

.offertSwiperNav .tabs__nav-trigger {
  font-weight: 400;
  color: #000;
}
.offertSwiperNav .tabs__nav-trigger[aria-selected=true] {
  font-weight: 500;
}
.offertSwiperNav .swiper {
  z-index: 12;
}

.offert__section {
  padding-top: 80px;
  padding-bottom: 40px;
  overflow: hidden;
}
.offert__section .tabs__panel {
  min-height: 540px;
}

.offert__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 40px;
  justify-content: space-between;
}
.offert__header .title {
  margin-bottom: 0;
}

@media (max-width: 1200px) {
  .offertSwiper .swiper-slide {
    width: 30%;
  }
}
@media (max-width: 992px) {
  .offertSwiper .swiper-slide {
    width: 40%;
  }
}
@media (max-width: 576px) {
  .offert__section {
    padding-top: 40px;
  }
  .offert__section .tabs__panel {
    min-height: 440px;
  }

  .offertSwiper {
    margin-left: -10px;
  }
  .offertSwiper .swiper-slide {
    width: 75%;
  }

  .offert__header {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
  }
}
.tabs2__navlist {
  display: flex;
  gap: 0;
}
.tabs2__navlist li:first-child .tabs2__nav-trigger {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.tabs2__navlist li:last-child .tabs2__nav-trigger {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.tabs2__nav-trigger {
  background: #fff;
  color: rgba(0, 0, 0, 0.6);
  border: 1px solid #CECECE;
  font-size: 1rem;
  padding: 15px 30px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius: 0;
}
.tabs2__nav-trigger[aria-selected=true] {
  color: #fff;
  background: #3D3D3C;
  border-color: #3D3D3C;
}

.sector--main {
  margin-bottom: 130px;
}

.sector__list {
  margin-top: -30px;
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
  justify-content: center;
  margin-bottom: 30px;
}

.sector__item {
  flex: 0 0 25%;
  max-width: calc(25% - 21px);
}

.sector__link, .card__link {
  padding-top: 70%;
  height: 0;
  overflow: hidden;
  display: block;
  width: 100%;
  position: relative;
}
.sector__link.active h3, .card__link.active h3 {
  position: relative;
  padding-top: 10px;
}
.sector__link.active h3:after, .card__link.active h3:after {
  content: "";
  display: block;
  width: 20px;
  height: 2px;
  background: #fff;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -10px;
}

.card__front, .card__back, .card {
  background: #E6007E;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  padding: 20px;
}
.card__front h3, .card__back h3, .card h3 {
  font-size: 1.25rem;
  margin-bottom: 0;
  text-transform: uppercase;
}

.card__back {
  transform: rotateY(180deg);
}

.sector__link:hover .card__front {
  transform: rotateY(-180deg);
}

.sector__link:hover .card__back {
  transform: rotateY(0);
}

@media (hover: hover) {
  .card__link:hover .card {
    background: #C8026F;
  }
}

@media (max-width: 1500px) {
  .sector__list {
    gap: 16px;
  }

  .sector__item {
    max-width: calc(25% - 12px);
  }

  .card__front h3, .card__back h3 {
    font-size: 1rem;
  }
}
@media (max-width: 992px) {
  .sector__item {
    flex: 0 0 33.33%;
    max-width: calc(33.33% - 11px);
  }
}
@media (max-width: 768px) {
  .sector__item {
    flex: 0 0 50%;
    max-width: calc(50% - 8px);
  }

  .sector--main {
    margin-bottom: 70px;
  }
}
@media (max-width: 420px) {
  .card__front, .card__back, .card {
    padding: 20px 10px;
  }

  .sector__list {
    gap: 8px;
  }

  .sector__item {
    flex: 0 0 50%;
    max-width: calc(50% - 4px);
  }
}
.sector__list2 {
  margin-top: 50px;
  margin-bottom: 200px;
}
.sector__list2 .sector__item {
  flex: 0 0 12.5%;
  max-width: calc(12.5% - 25px);
}
.sector__list2 .sector__item h3 {
  font-size: 9px;
}
.sector__list2 .sector__item .card__front, .sector__list2 .sector__item .card__back {
  padding: 10px;
}
@media (max-width: 1500px) {
  .sector__list2 .sector__item {
    max-width: calc(12.5% - 14px);
  }
}
@media (max-width: 1200px) {
  .sector__list2 {
    gap: 10px;
  }
  .sector__list2 .sector__item {
    flex: 0 0 12.5%;
    max-width: calc(12.5% - 9px);
  }
}
@media (max-width: 992px) {
  .sector__list2 {
    margin-top: 0px;
    margin-bottom: 80px;
    gap: 12px;
  }
  .sector__list2 .sector__item {
    flex: 0 0 25%;
    max-width: calc(25% - 9px);
  }
}
@media (max-width: 576px) {
  .sector__list2 {
    gap: 12px;
    margin-bottom: 30px;
  }
  .sector__list2 .sector__item {
    flex: 0 0 33.33%;
    max-width: calc(33.33% - 8px);
  }
}

.product__section {
  margin-top: -30px;
  overflow: hidden;
}

.product__wrapper {
  margin-bottom: 50px;
}
@media (max-width: 576px) {
  .product__wrapper {
    margin-bottom: 20px;
  }
}

.product__img {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-45%, 50%);
  width: auto;
  height: 180px;
}

.produkt__item {
  background: #E6007E;
  text-align: center;
  color: #fff;
  padding: 50px 20px 100px 20px;
  min-height: 350px;
  position: relative;
  margin-bottom: 120px;
}
.produkt__item .history--back {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 10px;
}
.produkt__item.produkt__item--pb0 {
  padding-bottom: 50px;
  margin-bottom: 0px;
}
.produkt__item h2 {
  position: relative;
  font-size: 1.875rem;
  margin-bottom: 60px;
}
.produkt__item h2:after {
  display: block;
  width: 30px;
  height: 2px;
  content: "";
  position: absolute;
  background: #fff;
  bottom: -30px;
  left: 50%;
  margin-left: -15px;
}
.produkt__item p {
  font-size: 1.125rem;
  font-style: italic;
  max-width: 550px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.2;
}
.produkt__item .product__txt--wide {
  max-width: 910px;
}
@media (max-width: 1200px) {
  .produkt__item {
    min-height: 300px;
  }
  .produkt__item h2 {
    font-size: 1.75rem;
  }
  .produkt__item .product__img {
    height: 120px;
  }
}
@media (max-width: 768px) {
  .produkt__item {
    padding: 35px 20px 90px 20px;
    min-height: auto;
    margin-bottom: 90px;
  }
  .produkt__item h2 {
    font-size: 1.625rem;
  }
}
@media (max-width: 576px) {
  .produkt__item {
    margin-bottom: 70px;
  }
  .produkt__item h2 {
    font-size: 1.375rem;
    margin-bottom: 40px;
  }
  .produkt__item h2:after {
    width: 20px;
    bottom: -10px;
    margin-left: -15px;
  }
}

.file__item {
  display: flex;
  align-items: center;
  padding-left: 70px;
  background: url("../img/pdf.svg") 0px center no-repeat;
  background-size: 48px;
  border-bottom: 1px solid #CECECE;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.file__txt {
  width: 100%;
  font-size: 1.125rem;
}
.file__txt p {
  margin-bottom: 0;
}

.file__link {
  display: inline-flex;
  align-items: center;
  min-height: 50px;
  justify-content: center;
  padding: 10px 20px 10px 30px;
  color: #000;
  background: #EAEAEA;
  font-size: 0.875rem;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
  margin-left: auto;
  font-family: "Montserrat", sans-serif;
  border-radius: 5px;
  transition: all 0.3s ease-out;
}
.file__link:after {
  content: url("../img/ico-download.svg");
  margin-left: 10px;
  transition: all 0.3s ease-out;
}
@media (hover: hover) {
  .file__link:hover {
    background-color: #E6007E;
    color: #FFF;
  }
  .file__link:hover:after {
    filter: brightness(0) invert(1);
  }
}

.file__title {
  font-size: 1.125rem;
  margin-bottom: 0px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .file__txt p {
    font-size: 0.875rem;
  }

  .file__item {
    padding-left: 60px;
  }

  .file__link {
    text-indent: -1000px;
    min-width: 50px;
    height: 0;
    padding: 0;
  }
  .file__link:after {
    text-indent: 0px;
    margin-left: 0;
  }
}
.product__figure {
  position: relative;
  height: 0;
  padding-top: 90%;
  transition: all 0.3s ease-in-out;
}
.product__figure img {
  width: 90%;
  height: 90%;
  -o-object-fit: contain;
     object-fit: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.product__txt {
  padding: 10px 30px 20px 30px;
}

.product__title {
  font-size: 1.5rem;
  margin-bottom: 15px;
  transition: color 0.3s ease-in-out;
}
.product__title span {
  color: #E6007E;
  display: block;
}

.product__summary {
  color: #000;
}

.product__txt--small {
  padding: 10px 20px 15px 20px;
}
.product__txt--small .product__title {
  font-size: 0.9375rem;
  margin-bottom: 5px;
}
.product__txt--small .product__summary {
  font-size: 1rem;
}

.product__item {
  position: relative;
  opacity: 1;
  transition: opacity 0.3s ease-out;
}

.product__item--op0 {
  opacity: 0;
}

.product__link {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  border-radius: 5px;
  background: #F5F5F5;
  outline: 1px solid transparent;
}
@media (hover: hover) {
  .product__link:hover, .product__link.active {
    outline-color: #E6007E !important;
    border-color: #E6007E !important;
    color: #000;
  }
  .product__link:hover .product__title, .product__link.active .product__title {
    color: #000;
  }
  .product__link:hover .product__summary, .product__link.active .product__summary {
    color: #000;
  }
}

.product__item--big .product__title {
  font-size: 2rem;
}

.product2__link {
  display: flex;
  gap: 15px;
  justify-content: center;
  padding: 10px 10px 10px 80px;
  background: #fff;
  outline-color: #d3d3d3;
  border: 1px solid transparent;
  height: 100%;
  position: relative;
  gap: 5px;
  min-height: 90px;
}
.product2__link.active {
  background: #F5F5F5;
}
.product2__link img {
  width: 68px;
  height: auto;
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.product2__link h3 {
  margin-bottom: 0;
  font-size: 1.125rem;
}

.swiper .product__item {
  padding: 8px;
  height: 100%;
}
.swiper .product__link {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  outline-width: 2px;
}

.product__item--white .product__link {
  background: #fff;
  outline-color: #d3d3d3;
}

.product2__section {
  padding-top: 30px;
}

.category .product__title {
  font-size: 1.375rem;
  margin-bottom: 0;
}
.category .product__figure {
  padding-top: 105%;
}

.categoryS {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pr--contact {
  background: #fff;
  padding: 10px;
  color: #000;
  display: inline-flex;
  gap: 5px;
  align-items: center;
  font-weight: 700;
  border-radius: 5px;
  border: 1px solid #eaeaea;
}
.pr--contact:before {
  content: "";
  width: 24px;
  height: 24px;
  display: block;
  background: url("../img/ico-phone.svg") center no-repeat;
  background-size: 100%;
}

.pr--email:before {
  background-image: url("../img/ico-mail3.svg");
}

.product__contact {
  background: #F5F5F5;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 50px;
}
.product__contact .pr--contact {
  width: 100%;
  margin-top: 10px;
}
.product__contact h3 {
  font-size: 1.625rem;
}
.product__contact p {
  margin-bottom: 30px;
}

.categoryS__title {
  padding: 20px 25px;
  margin-bottom: 0;
  font-size: 1.125rem;
}

.categoryS__link {
  min-height: 90px;
  justify-content: center;
}

@media (max-width: 1700px) {
  .product__txt {
    padding: 10px 25px 20px 25px;
  }

  .product__title {
    font-size: 1.375rem;
  }

  .category .product__title {
    font-size: 1.25rem;
  }

  .product__item--big .product__title {
    font-size: 1.75rem;
  }
}
@media (max-width: 1200px) {
  .product__txt {
    padding: 10px 20px 20px 20px;
  }

  .product__title {
    font-size: 1.25rem;
  }

  .category .product__title {
    font-size: 1.125rem;
  }

  .product__item--big .product__title {
    font-size: 1.625rem;
  }
}
@media (max-width: 768px) {
  .product__title {
    font-size: 1.0625rem;
  }

  .category .product__title {
    font-size: 1rem;
  }

  .product__txt {
    padding: 10px 15px 20px;
  }

  .product__item--big .product__title {
    font-size: 1.5rem;
  }
}
@media (max-width: 576px) {
  .product__item--big .product__title {
    font-size: 1.25rem;
  }

  .category .product__title {
    font-size: 0.75rem;
  }

  .product__contact {
    padding: 30px 15px;
  }
  .product__contact h3 {
    font-size: 0.8125rem;
  }
  .product__contact p {
    font-size: 0.625rem;
    margin-bottom: 10px;
  }
  .product__contact .pr--contact {
    font-size: 0.625rem;
  }
  .product__contact .pr--contact:before {
    width: 12px;
    height: 12px;
  }

  .categoryS__link {
    align-items: center;
    text-align: center;
  }

  .product__txt--small {
    padding-top: 10px;
  }
  .product__txt--small .product__title {
    font-size: 0.75rem;
  }
  .product__txt--small .product__summary {
    font-size: 0.6875rem;
  }
}
.gallery__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.gallery__item {
  max-width: calc(16.66% - 10px);
  flex: 0 0 16.66%;
  position: relative;
}
.gallery__item:after {
  display: block;
  content: "";
  width: 100%;
  height: 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
}

@media (max-width: 768px) {
  .gallery__wrapper {
    gap: 8px;
  }

  .gallery__item {
    max-width: calc(25% - 6px);
    flex: 0 0 25%;
  }
}
.gallery__link {
  height: 0;
  padding-top: 90%;
  position: relative;
  display: block;
}
.gallery__link img {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.gallery__link:before {
  content: url(../img/btn-search-white.svg);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1.5);
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s ease-out 0s;
}
.gallery__link:after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: #b8308b;
  z-index: -1;
  transition: all 0.3s ease-out;
  opacity: 0;
}
@media (hover: hover) {
  .gallery__link:hover:before {
    z-index: 12;
    opacity: 1;
    transition: opacity 0.3s ease-out;
  }
  .gallery__link:hover:after {
    opacity: 0.2;
    z-index: 10;
  }
}

.sl-prev {
  color: transparent !important;
}
.sl-prev:before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("../img/swiper-left.svg");
  background-size: 25px;
  background-position: center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  display: block;
}

.sl-next {
  color: transparent !important;
}
.sl-next:before {
  content: "";
  width: 100%;
  height: 100%;
  background-image: url("../img/swiper-right.svg");
  background-size: 25px;
  background-position: center;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  display: block;
}

@media (min-width: 992px) {
  .newsletter .container {
    max-width: 900px;
  }
}
@media (max-width: 576px) {
  .newsletter {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.newsletter__form {
  display: flex;
  flex-wrap: wrap;
  -moz-column-gap: 20px;
       column-gap: 20px;
  width: 100%;
}
.newsletter__form label {
  color: #fff;
}
.newsletter__form .form__input {
  width: 100%;
}
@media (min-width: 768px) {
  .newsletter__form .form__input {
    flex: 0 0 50%;
    max-width: calc(50% - 10px);
  }
}

.newsletter__wrapper {
  background-color: #E6007E;
  color: #fff;
  padding: 40px 20px;
  margin-left: -20px;
  margin-right: -20px;
}
.newsletter__wrapper .title {
  color: #fff;
  text-align: center;
}
@media (min-width: 576px) {
  .newsletter__wrapper {
    padding: 50px;
  }
}
@media (min-width: 992px) {
  .newsletter__wrapper {
    padding: 60px 100px;
  }
}

.newsletter__wrapper .form__input--error {
  color: #eee;
}

@media (min-width: 992px) {
  .cart__wrapper {
    display: flex;
    gap: 50px;
  }

  .cart__left {
    flex: 0 0 450px;
  }

  .cart__right {
    max-width: calc(100% - 500px);
  }
  .cart__right img {
    max-width: 100%;
  }
}
.cart__left .form__input {
  width: 100%;
  max-width: 100%;
}

#cookie_info {
  border-color: #E6007E;
  background: #FEF7A8 !important;
}
#cookie_info #cookie_info2 {
  background: #FEF7A8 !important;
}
#cookie_info .cookie-info-txt-elm {
  color: #000;
}
#cookie_info #close_info_cookie {
  background-color: #E6007E;
}

.strategy {
  position: relative;
}

.strategy__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.strategy__wrapper {
  position: relative;
}

.strategySwiper2 {
  padding-bottom: 70px !important;
}

.strategySwiper__btn {
  border: 1px solid #E6007E;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #E6007E;
  cursor: pointer;
  font-size: 1.875rem;
  font-weight: 700;
}
.strategySwiper__btn:after {
  content: "";
  display: inline-block;
  background-image: url("../img/arrow-right-blue.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 40px;
  margin-left: 20px;
  transition: all 0.3s ease-out;
}

.strategySwiper .swiper-slide-thumb-active .strategySwiper__btn {
  color: #fff;
  background: #E6007E;
}
.strategySwiper .swiper-slide-thumb-active .strategySwiper__btn:after {
  filter: brightness(0) invert(1);
  transform: rotate(90deg);
}

.vision {
  padding-top: 100px;
}

.vision__txt h2 {
  font-size: 86px;
  font-weight: 400;
  margin-bottom: 60px;
}
.vision__txt p {
  font-weight: 700;
  font-size: 22px;
  max-width: 870px;
  margin-bottom: 15px;
}
.vision__txt ul {
  padding-left: 25px;
}
.vision__txt ul li {
  font-size: 26px;
}
.vision__txt ul li:before {
  left: -25px;
}

.mission__heading {
  text-align: right;
  font-size: 34px;
  margin-top: 60px;
  margin-bottom: 30px;
  max-width: 1070px;
  font-weight: 700;
  margin-left: auto;
  font-style: italic;
}

.tech {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 10px;
}

.tech__item {
  flex: 0 0 50%;
  max-width: calc(50% - 15px);
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  min-height: 180px;
  position: relative;
  z-index: 2;
}

.tech__item:before {
  display: block;
  content: "";
  width: 56px;
  height: 56px;
  position: absolute;
  z-index: -1;
}
.tech__item.tech__item--left.tech__item--top:before {
  border-left: 1px solid #E6007E;
  border-top: 1px solid #E6007E;
  top: -10px;
  left: -10px;
}
.tech__item.tech__item--right.tech__item--top:before {
  border-right: 1px solid #E6007E;
  border-top: 1px solid #E6007E;
  top: -10px;
  right: -10px;
}
.tech__item.tech__item--left.tech__item--bottom:before {
  border-left: 1px solid #E6007E;
  border-bottom: 1px solid #E6007E;
  bottom: -25px;
  left: -10px;
  width: 140px;
  height: 80px;
}
.tech__item.tech__item--right.tech__item--bottom:before {
  border-right: 1px solid #E6007E;
  border-bottom: 1px solid #E6007E;
  bottom: -25px;
  right: -10px;
  width: 140px;
  height: 80px;
}

.tech__txt {
  font-size: 1.125rem;
  padding: 25px;
  width: 100%;
  font-weight: 500;
}

.tech__title {
  font-size: 34px;
  font-weight: 700;
  padding: 15px 0;
  margin-bottom: 0;
}

.tech__letter span {
  background: #E6007E;
  color: #fff;
  font-size: 100px;
  font-weight: 700;
  min-width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tech__item--left .tech__letter {
  margin-left: 20px;
}

.tech__item--right .tech__letter {
  margin-right: 20px;
  order: -1;
}
.tech__item--right .tech__txt {
  text-align: right;
  order: 2;
}
.tech__item--right .tech__title {
  order: 1;
}

.tech__item--top .tech__letter {
  align-self: flex-end;
}
.tech__item--top .tech__title {
  align-self: flex-end;
}

.tech__item--bottom .tech__txt {
  align-self: flex-end;
}

.tech__bottom {
  max-width: calc(100% - 300px);
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  text-align: center;
  font-size: 1.125rem;
}
.tech__bottom strong {
  font-size: 1.875rem;
}

@media (max-width: 1500px) {
  .vision {
    padding-top: 50px;
  }

  .vision__txt h2 {
    font-size: 64px;
    margin-bottom: 40px;
  }
  .vision__txt ul li {
    font-size: 20px;
  }
  .vision__txt p {
    font-size: 1.125rem;
  }

  .strategySwiper__btn {
    height: 70px;
    font-size: 1.625rem;
  }
  .strategySwiper__btn:after {
    background-size: 20px;
  }

  .mission__heading {
    font-size: 26px;
    margin-top: 50px;
    max-width: 900px;
  }

  .tech__letter span {
    font-size: 75px;
    min-width: 90px;
    height: 90px;
  }

  .tech__title {
    font-size: 1.625rem;
  }

  .tech__txt {
    font-size: 1rem;
  }

  .tech__bottom {
    font-size: 1rem;
  }
}
@media (max-width: 1200px) {
  .mission__heading {
    font-size: 22px;
  }

  .vision__txt h2 {
    font-size: 44px;
  }

  .tech {
    gap: 16px;
  }

  .tech__item {
    flex-wrap: wrap;
    align-items: flex-start;
    min-height: auto;
    max-width: calc(50% - 8px);
    gap: 0;
  }
  .tech__item.tech__item--top .tech__txt {
    order: -1;
  }
  .tech__item.tech__item--bottom .tech__txt {
    order: 2;
  }
  .tech__item.tech__item--left {
    justify-content: flex-end;
  }

  .tech__txt {
    width: 100%;
  }

  .tech__letter span {
    font-size: 50px;
    min-width: 60px;
    height: 60px;
  }

  .tech__title {
    font-size: 1.25rem;
  }

  .tech__item--bottom:before {
    width: 30px !important;
    height: 60px !important;
  }

  .tech__bottom {
    max-width: calc(100% - 70px);
  }
}
@media (max-width: 576px) {
  .strategySwiper__btn {
    height: 50px;
    font-size: 1.125rem;
    padding: 0 5px;
    text-align: center;
  }
  .strategySwiper__btn:after {
    margin-left: 5px;
    background-size: 15px;
  }

  .vision {
    padding-top: 20px;
  }

  .vision__txt h2 {
    font-size: 1.625rem;
    margin-bottom: 20px;
  }
  .vision__txt ul li {
    font-size: 1.125rem;
  }
  .vision__txt p {
    font-size: 1rem;
  }

  .mission__heading {
    font-size: 1rem;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .tech__txt {
    padding: 10px;
    font-size: 0.875rem;
  }

  .tech__title {
    font-size: 1.125rem;
    padding: 10px 0;
  }

  .tech__letter span {
    font-size: 34px;
    min-width: 46px;
    height: 46px;
  }

  .tech__item--left .tech__letter {
    margin-left: 10px;
  }

  .tech__item--right .tech__letter {
    margin-right: 10px;
  }

  .tech__bottom {
    font-size: 0.875rem;
  }
}
@media (max-width: 450px) {
  .tech__txt {
    font-size: 0.75rem;
  }
}
.lk__swiper {
  margin-left: -40px;
  margin-right: -40px;
  width: calc(100% + 80px);
  padding: 10px 40px;
  position: relative;
  margin-bottom: 150px;
}
.lk__swiper .swiper-slide {
  height: auto;
  height: auto;
}

.lk__item {
  padding: 45px 30px 35px 30px;
  height: 100%;
  border: 1px solid #B3E4FA;
  transition: all 0.3s ease-out;
  text-align: center;
}
.lk__item .title--border {
  font-size: 1.125rem;
  margin-bottom: 50px;
}
.lk__item .title--border:after {
  bottom: -25px;
}
.lk__item .file__link {
  display: block;
  padding: 12px;
  color: #E6007E;
}
@media (hover: hover) {
  .lk__item:hover {
    background: #d6deec;
  }
  .lk__item:hover .file__title {
    background-image: url("../img/ico-download.svg");
  }
  .lk__item:hover h3 {
    color: #E6007E;
  }
}

.swiper-slide, .col-md-4 {
  height: initial;
}
.swiper-slide:nth-child(odd) .lk__item, .col-md-4:nth-child(odd) .lk__item {
  border-color: #C7C1DB;
}

.historySwiper {
  position: relative;
  padding: 10px 40px;
  margin-top: 50px;
  margin-left: -40px;
  margin-right: -40px;
  width: calc(100% + 80px);
}
.historySwiper .swiper-wrapper {
  padding: 5px 0px;
}
.historySwiper .swiper-slide {
  height: initial;
  border: 1px solid #B3E4FA;
}
.historySwiper .swiper-slide:nth-child(even) {
  border-color: #C7C1DB;
}
.historySwiper .list--own {
  line-height: 1.2;
}
.historySwiper .list--own li {
  margin-bottom: 15px;
}
.historySwiper .list--own li li {
  margin-bottom: 0;
}

.history__item {
  padding: 30px 20px;
}

.history__title {
  font-size: 52px;
  margin-bottom: 40px;
  position: relative;
  text-align: center;
}
.history__title:after {
  display: block;
  width: 40px;
  height: 4px;
  content: "";
  position: absolute;
  background: #E6007E;
  bottom: -15px;
  left: 50%;
  margin-left: -20px;
}

.history__logo {
  width: 100%;
}

.history__item--last {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 1500px) {
  .history__title {
    font-size: 42px;
  }
}
@media (max-width: 1200px) {
  .history__title {
    font-size: 36px;
  }
}
@media (max-width: 576px) {
  .history__title {
    font-size: 28px;
  }

  .historySwiper {
    margin-top: 30px;
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
  }

  .lk__swiper {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
  }

  .lk__item {
    padding: 30px 30px 20px;
  }

  .history.section {
    padding-bottom: 0;
  }
}
.contact__top .description {
  margin-bottom: 80px;
}

.contact__item {
  height: 100%;
  background: #E6007E;
  color: #fff;
  text-align: center;
  padding: 80px 20px;
  font-size: 1.125rem;
  line-height: 1.6;
}
.contact__item .title--border {
  font-size: 18px;
  text-transform: uppercase;
}
.contact__item .title--border:after {
  background: #fff;
}
.contact__item h3 {
  margin-bottom: 15px;
  font-size: 1.125rem;
}
.contact__item a {
  color: #fff;
}
@media (hover: hover) {
  .contact__item a:hover {
    color: #C8026F;
  }
}

@media (max-width: 576px) {
  .contact__item {
    padding: 50px 10px;
  }

  .contact__top .description {
    margin-bottom: 60px;
  }
}
.di__top {
  margin-top: -30px;
  padding-bottom: 100px;
}

@media (max-width: 576px) {
  .di__top {
    padding-bottom: 20px;
  }

  .dl__contact {
    padding-top: 0;
  }
}
.manage__figure {
  padding-top: 56%;
  height: 0;
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}
.manage__figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
}

.manage__title {
  font-size: 1.875rem;
  font-weight: 700;
  padding: 15px 20px;
  border-bottom: 1px solid #CECECE;
  overflow: hidden;
  margin-bottom: 5px;
}
.manage__title span {
  font-size: 1.125rem;
  font-weight: 400;
  font-style: italic;
}

.manage__description {
  padding: 15px;
  font-size: 1.125rem;
}
.manage__description p {
  margin-bottom: 20px;
}

.manage__section {
  position: relative;
  overflow: hidden;
}

.manage__img {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}

@media (max-width: 1500px) {
  .manage__title span {
    margin-top: 5px;
    display: block;
  }
}
@media (max-width: 576px) {
  .manage__title {
    font-size: 1.625rem;
  }
}
@media (min-width: 1200px) {
  .doc__item {
    margin-bottom: 50px;
  }
}

.doc__link {
  background: #E6007E;
  height: 100%;
  color: #fff;
  padding: 65px 10px 45px 10px;
  display: block;
  width: 100%;
}
.doc__link .title--border {
  font-size: 1.875rem;
}
.doc__link .title--border:after {
  background: #fff;
}
@media (max-width: 576px) {
  .doc__link {
    padding: 35px 10px 15px 10px;
  }
  .doc__link .title--border {
    font-size: 1.25rem;
  }
}

@media (hover: hover) {
  a.doc__link:hover {
    color: #fff;
    background: #C8026F;
  }
}

.doc2__list {
  padding-top: 50px;
  padding-bottom: 100px;
  max-width: 720px;
  margin-right: auto;
  margin-right: auto;
  margin-left: auto;
}
.doc2__list .file__list {
  max-width: 670px;
}
.doc2__list .accordion__trigger {
  border-bottom: 1px solid #CECECE;
  padding: 30px 50px 30px 20px;
  padding-right: 50px;
}
.doc2__list .accordion__trigger:after {
  position: relative;
  top: 0;
  transform: rotate(-90deg);
  right: -50px;
  display: inline-block;
}
.doc2__list .accordion__trigger[aria-expanded=true]:after {
  transform: rotate(0deg);
}

.doc2__item .accordion__content {
  padding-left: 20px;
  padding-right: 20px;
}
.doc2__item .accordion__content[aria-hidden=false] {
  border-bottom: 1px solid #CECECE;
}

.investments {
  margin-bottom: 100px;
}

.investmentsSwiper__wrapper {
  height: 0;
  padding-top: 56%;
  position: relative;
  margin-bottom: 40px;
  margin-top: 70px;
}

.investmentsSwiper {
  position: absolute !important;
  top: 0;
  left: 50%;
  height: 100%;
  width: 100vw;
  transform: translateX(-50%);
}
.investmentsSwiper .swiper-slide img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.investmentsSwiper__bg {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.investmentsSwiper2 {
  padding: 0 2px;
}
.investmentsSwiper2.swiper-accordion-active .accordion, .investmentsSwiper2.swiper-accordion-active .investments__item {
  height: auto;
}
.investmentsSwiper2 .accordion__content {
  font-style: normal;
  text-align: left;
}
.investmentsSwiper2 .accordion__content p {
  padding-top: 10px;
  font-style: normal;
}
.investmentsSwiper2 .swiper-slide {
  height: auto;
}
.investmentsSwiper2 .accordion, .investmentsSwiper2 .investments__item {
  height: 100%;
}
.investmentsSwiper2 .accordion__trigger {
  font-weight: 400;
  display: inline-flex;
  width: initial;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.investmentsSwiper2 .accordion__trigger:after {
  transform: translateY(-50%) rotate(-90deg);
  right: 20px;
}
.investmentsSwiper2 .accordion__trigger[aria-expanded=true]:after {
  transform: translateY(-50%) rotate(-180deg);
}
.investmentsSwiper2 .accordion__trigger[aria-expanded=true] {
  width: 100%;
  color: transparent;
  margin-top: 0px;
}

.investments__item {
  border: 1px solid #E6007E;
  padding: 50px 50px 140px 50px;
  text-align: center;
  height: 100%;
  position: relative;
  cursor: pointer;
}
.investments__item .title--border {
  font-size: 30px;
}
.investments__item p {
  font-style: italic;
  font-size: 1.125rem;
}

.swiper-slide-thumb-active .investments__item {
  background: #E6007E;
  color: #fff;
}
.swiper-slide-thumb-active .investments__item .title--border:after {
  background: #fff;
}
.swiper-slide-thumb-active .swiper-slide {
  height: auto;
}
.swiper-slide-thumb-active .accordion__trigger {
  color: #fff;
}
.swiper-slide-thumb-active .accordion__trigger:after {
  filter: brightness(0) invert(1);
}

@media (max-width: 1200px) {
  .investmentsSwiper__wrapper {
    margin-bottom: 12px;
  }
}
@media (max-width: 992px) {
  .investments__item .title--border {
    font-size: 26px;
  }

  .investments__item {
    padding: 30px 20px 120px 20px;
  }
}
@media (max-width: 768px) {
  .investments__item .title--border {
    font-size: 20px;
  }

  .investments__item {
    padding: 30px 15px 90px 15px;
  }
}
@media (max-width: 576px) {
  .investments {
    margin-left: -20px;
    margin-right: -20px;
  }

  .investmentsSwiper__wrapper {
    margin-bottom: 0;
  }

  .investments__item {
    padding: 20px 10px 80px 10px;
  }
  .investments__item .accordion__trigger {
    font-size: 14px;
    padding-right: 30px;
    bottom: 10px;
  }
  .investments__item .accordion__trigger:after {
    width: 9px;
    right: 10px;
  }

  .investments__item {
    border-top: none;
  }
  .investments__item .title--border {
    font-size: 16px;
  }

  .investments__item p {
    font-size: 14px;
  }
}
.shareholders {
  display: flex;
  gap: 60px;
  margin-bottom: 100px;
  margin-top: 80px;
  align-items: center;
}

.shareholders__left {
  flex: 0 0 560px;
  max-width: 560px;
}
.shareholders__left canvas {
  cursor: pointer;
  max-width: 100%;
}

.shareholders__right {
  width: 100%;
  max-width: calc(100% - 620px);
}
.shareholders__right table {
  width: 100%;
  font-size: 1.125rem;
  margin-bottom: 10px;
}
.shareholders__right table th {
  font-weight: 400;
  border-top: none;
  border-bottom: 1px solid #CECECE;
}
.shareholders__right table th:first-child {
  padding-left: 0;
}
.shareholders__right table tbody td:first-child {
  font-weight: 700;
  padding-left: 0;
  padding-left: 0;
}

@media (max-width: 1500px) {
  .shareholders__left {
    flex: 0 0 400px;
    max-width: 400px;
  }

  .shareholders__right {
    max-width: calc(100% - 460px);
  }
}
@media (max-width: 1200px) {
  table td, table th {
    padding: 10px 5px;
  }

  .shareholders {
    gap: 20px;
  }

  .shareholders__right {
    max-width: calc(100% - 420px);
  }

  .shareholders__right table {
    font-size: 1rem;
  }
  .shareholders__right tbody td:not(:first-child) {
    min-width: 80px;
  }
}
@media (max-width: 992px) {
  .shareholders {
    display: block;
  }

  .shareholders__left {
    max-width: 100%;
    flex: 0 0 100%;
  }
  .shareholders__left canvas {
    margin-left: auto;
    margin-right: auto;
  }

  .shareholders__right {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
@media (max-width: 992px) {
  .shareholders {
    margin-bottom: 50px;
    margin-top: 50px;
  }
}
.go-top {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 48px;
  display: inline-block;
  height: 48px;
  background: url("../img/ico_top.svg");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center;
}

.video__wrapper {
  height: 0;
  padding-top: 56%;
  position: relative;
  margin-top: -30px;
  margin-bottom: 100px;
}
.video__wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
  width: 100%;
}

.video__img {
  position: absolute;
  top: 0;
  left: 0;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.video__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
  width: 60px;
  height: 60px;
  background-image: url("../img/play.svg");
  background-size: contain;
  background-position: center;
  background-color: transparent;
  border: none;
}

.video__title {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-transform: uppercase;
  font-size: 22px;
  text-align: center;
  z-index: 5;
  font-weight: 400;
  width: 100%;
}

@media (max-width: 768px) {
  .video__wrapper {
    margin-bottom: 50px;
  }

  .video__title {
    top: 70%;
    font-size: 16px;
  }
}
.bestseller {
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 50px;
}
.bestseller .product__link {
  box-shadow: none;
}

.article2 {
  padding-top: 90px;
  padding-bottom: 80px;
  margin-top: -20px;
}
@media (max-width: 1200px) {
  .article2 {
    padding-top: 70px;
    padding-bottom: 60px;
  }
}
@media (max-width: 576px) {
  .article2 {
    padding-top: 50px;
    padding-bottom: 40px;
  }
}

.nav2 {
  overflow: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 60px;
  background: #fff;
  z-index: 50;
  margin-top: 20px;
}
.nav2 .container {
  overflow: auto;
}

.nav2-list {
  display: inline-flex;
  margin-left: -10px;
  position: relative;
  padding-bottom: 8px;
}
.nav2-list:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 10px;
  height: 8px;
  width: calc(100% - 20px);
  border-radius: 4px;
  background: #d3d3d3;
}
@media (min-width: 1500px) {
  .nav2-list {
    gap: 10px;
  }
}

.nav2-slider {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 8px;
  transition: none;
  display: block;
  width: 50px;
  opacity: 0;
  border-radius: 4px;
  background: #E6007E;
  z-index: 10;
}
.nav2-slider.is-start {
  transition: all 0.3s ease-out;
  opacity: 1;
}

.nav2-link {
  font-size: 1.125rem;
  color: rgba(0, 0, 0, 0.3);
  display: inline-block;
  padding: 15px 10px;
  position: relative;
  white-space: nowrap;
  background: #fff;
  font-weight: 700;
}
.nav2-link.active {
  color: #000;
}
@media (hover: hover) {
  .nav2-link:hover {
    color: #000;
  }
}

@media (max-width: 576px) {
  .nav2-link {
    font-size: 1rem;
  }
}