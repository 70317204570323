.d-none {
    display: none !important;
}

.d-block {
    display: block !important;
}



.container {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: $container-max-width-xxl;

    @media (min-width: $grid-breakpoints-xs) {
        padding-left: 30px;
        padding-right: 30px;
    }


    @media (min-width: $grid-breakpoints-md) {
        padding-left: 80px;
        padding-right: 80px;
    }

}

.container--md{
      max-width:1400px;
}

.container--xs{
    @media (max-width: $grid-breakpoints-sm) {
      max-width:$container-max-width-sm;
    }
}
.container--xxs{
    @media (max-width: $grid-breakpoints-sm) {
      max-width:300px;
        margin-left: auto;
        margin-right: auto;
    }
}

.container--half{
    max-width:$container-max-width-xxl-half;
    padding-left: 20px;
    padding-right: 20px;
    @media (min-width: 375px) {
        padding-left: 30px;
        padding-right: 30px;
    }
    @media (min-width: $grid-breakpoints-md) {
        padding-left: 80px;
        padding-right: 80px;
    }
}


.row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px;
    width: calc(100% + 30px);
    @media (max-width: $grid-breakpoints-sm) {
        margin-left: -8px;
        margin-right: -8px;
        width: calc(100% + 16px);
    }
}

[class*="col-"] {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    margin-bottom: 30px;

    @media (max-width: $grid-breakpoints-sm) {
        padding-left: 8px;
        padding-right: 8px;
        margin-bottom: 16px;
    }
}


.row--16 {
    margin-left: -8px;
    margin-right: -8px;
    width: calc(100% + 16px);

    [class*="col-"] {
        padding-left: 8px;
        padding-right: 8px;
        width: 100%;
        margin-bottom: 16px;
    }

}


.row--60 {
    @media(min-width:$grid-breakpoints-xl) {
        margin-left: -30px;
        margin-right: -30px;
        width: calc(100% + 60px);

        [class*="col-"] {
            padding-left: 30px;
            padding-right: 30px;
            width: 100%;
            margin-bottom: 60px;
        }
    }
}

.w-100 {
    width: 100% !important;
    max-width: 100% !important;
    flex: 0 0 100% !important;
}

.col-6 {
    max-width: 50%;
    flex: 0 0 50%;
}

@media (min-width: $grid-breakpoints-sm) {

    .col-sm-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .col-sm-4 {
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }

    .col-sm-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .col-sm-8 {
        max-width: 66.66%;
        flex: 0 0 66.66%;
    }
}


@media (min-width: $grid-breakpoints-md) {
    .col-md-2 {
        max-width: 16.66%;
        flex: 0 0 16.66%;
    }
    .col-md-4 {
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }
    .col-md-5 {
        max-width: 41.66%;
        flex: 0 0 41.66%;
    }

    .col-md-50,
    .col-md-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .col-md-7 {
        max-width: 58.33%;
        flex: 0 0 58.33%;
    }
    .col-md-8 {
        max-width: 66.66%;
        flex: 0 0 66.66%;
    }

}


@media (min-width: $grid-breakpoints-lg) {
    .col-lg-2 {
        max-width: 16.66%;
        flex: 0 0 16.66%;
    }

    .col-lg-4 {
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }
    .col-lg-5 {
        max-width: 41.66%;
        flex: 0 0 41.66%;
    }

    .col-lg-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }
    .col-lg-7 {
        max-width: 58.33%;
        flex: 0 0 58.33%;
    }

    .col-lg-12 {
        max-width: 100%;
        flex: 0 0 100%;
    }
}

@media (min-width: $grid-breakpoints-xl) {
    .col-xl-125 {
        max-width: 12.5%;
        flex: 0 0 12.5%;
    }

    .col-xl-2 {
        max-width: 16.66%;
        flex: 0 0 16.66%;
    }

    .col-xl-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .col-xl-4 {
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }
    .col-xl-6 {
        max-width: 50%;
        flex: 0 0 50%;
    }

    .col-xl-8 {
        max-width: 66.66%;
        flex: 0 0 66.66%;
    }

    .col-xl-20 {
        max-width: 20%;
        flex: 0 0 20%;
    }

}


@media (min-width: $grid-breakpoints-xxl) {
    .col-xxl-3 {
        max-width: 25%;
        flex: 0 0 25%;
    }

    .col-xxl-4 {
        max-width: 33.33%;
        flex: 0 0 33.33%;
    }
    .col-xxl-20 {
        max-width: 20%;
        flex: 0 0 20%;
    }
}



.img--fluid {
    max-width: 100%;
    height: auto;
}

.box--center {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.nowrap {
    white-space: nowrap !important;
}


.section {
    padding-top: 80px;
    padding-bottom: 80px;

    // @media(max-width:$grid-breakpoints-md) {
    //     padding-top: 60px;
    //     padding-bottom: 60px;
    // }
    @media(max-width:$grid-breakpoints-sm) {
        padding-top: 50px;
        padding-bottom:50px;
    }
}

.section--30 {
    padding-top: 30px;
    padding-bottom: 30px;
}


.d-block {
    display: block;
}

.ml-auto {
    margin-left: auto !important;

}

.p-20 {
    padding: 20px;
}

.pt-0 {
    padding-top: 0px !important;
}

.pt-20 {
    padding-top: 20px !important;
}

.pt-30 {
    padding-top: 30px !important;
}

.pt-40 {
    padding-top: 40px !important;
}

.pt-50 {
    padding-top: 50px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
}

.pb-10 {
    padding-bottom: 10px !important;
}

.pb-20 {
    padding-bottom: 20px !important;
}

.pb-30 {
    padding-bottom: 30px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}

.mt-0 {
    margin-top: 0px !important;
}

.mt-10 {
    margin-top: 10px !important;
}

.mt-15 {
    margin-top: 15px !important;
}

.mt-20 {
    margin-top: 20px !important;
}

.mt-30 {
    margin-top: 30px !important;
}

.mt-40 {
    margin-top: 40px !important;
}

.mt-50 {
    margin-top: 50px !important;
}

.mt-100 {
    margin-top: 100px !important;

    @media(max-width:$grid-breakpoints-md) {
        margin-top: 50px !important;
    }
}

.mb-0 {
    margin-bottom: 0px !important;
}

.mb-10 {
    margin-bottom: 10px !important;
}

.mb-15 {
    margin-bottom: 15px !important;
}

.mb-20 {
    margin-bottom: 20px !important;
}

.mb-30 {
    margin-bottom: 30px !important;
}

.mb-40 {
    margin-bottom: 40px !important;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.mb-70 {
    margin-bottom: 70px !important;
}
.mb-100 {
    margin-bottom: 100px !important;
    @media(max-width:$grid-breakpoints-md) {
        margin-bottom: 60px !important;
    }
}

.mb-sm-50 {
    margin-bottom: 50px !important;
    @media(max-width:$grid-breakpoints-sm) {
        margin-bottom: 30px !important;
    }
}