.disabled {
  color: $placeholder;
}

fieldset {
  border: none;
  padding: 0;
}


.form-h-info {
  margin-top: 5px;
  font-size: $font10;
  max-height: 0;
  overflow: hidden;
  color: $txt-light;
  transition: all 0.3s ease-in-out;
}

.form__input input:focus + .form-h-info {
  max-height: 100px;
}


.form__select {

  padding: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 25px;
  z-index: 10;
  width: 100%;

  label {
    color: $label;
    font-size: $font12;
    margin-bottom: 6px;
    font-weight: 700;
    display: block;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;


    border: 1px solid $input-border;
    border-radius: 12px;
    padding: 12px 45px 12px 15px;
    display: block;
    background: transparent;
    width: 100%;
    font-size: $font18;

    line-height: 1.5;
    cursor: pointer;

    &:focus,
    &:focus-visible {
      outline: 2px solid #000;
      border-color: #000;
      box-shadow: none;
      outline-offset: 0;
      // outline:none;
    }
  }

  option {
    border: none;
    padding: 10px;
    font-size: $font16;
    color: $input;
    line-height: 40px;
  }

  &:after {
    content: "";
    position: absolute;
    right: 15px;
    bottom: 23px;
    z-index: -1;
    width: 12px;
    height: 12px;
    display: block;
    border-left: 1px solid $input;
    border-bottom: 1px solid $input;
    transform: rotate(-45deg);
  }

  @media(max-width: $grid-breakpoints-sm) {
    width: 100%;

    select {
      width: 100%;
    }
  }

}


.is--desktop .custom-select{
  select {
    display: none; /*hide original SELECT element: */
  }
}

.custom-select {
  position: relative;
  z-index: 40;


  .select-selected {
    color: $txt;
    border-radius: 5px;
    padding: 12px 45px 12px 15px;
    border: 1px solid $line3;
    display: block;
    background: transparent;
    width: 100%;
    font-weight: 400;
    font-size: $font18;
    background:#fff;
    z-index:50;
    position:relative;

    &:after {
      content: "";
      position: absolute;
      right: 15px;
      bottom: 23px;
      z-index: -1;
      width: 12px;
      height: 12px;
      display: block;
      border-left: 1px solid #292827;
      border-bottom: 1px solid #292827;
      transform: rotate(-45deg);
    }
  }

  .select-items {
    position: absolute;
    background-color: #fff;
    padding-top:5px;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 49;
    margin-top:-5px;
    border-radius: 5px;
    border: 1px solid $line3;
    overflow: hidden;

    &.select-hide {
      display: none;
    }

    div {
      padding: 8px 15px;
      cursor: pointer;
      font-size: $font18;
      @media (hover: hover) {
        &:hover {
          background-color: $main;
          color: #fff;
        }
      }
    }
  }

  .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
  }
}


.form__checkbox {
  margin-bottom: 0px;
  position: relative;

  input {
    position: absolute;
    left: 0;
    top: 0;
    padding: 0;
    z-index: -1000;
    opacity: 0;
    width: 0;
    height: 0;
  }

  a {
    text-decoration: underline;
    font-weight: 500;
  }

  label {
    position: relative;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 34px;
    line-height: 20px;
    color: $label;
    font-size: $font18;
    line-height: 1.4;
    cursor: pointer;
    display: inline-block;
    width: 100%;

    &:before {
      content: "";
      width: 24px;
      height: 24px;
      position: absolute;
      top: 12px;
      left: 0;
      display: block;
      border: 2px solid $input-border;
      background-color: #fff;
      transition: all 0.3s ease-out;
      border-radius: 4px;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 19px;
      left: 7px;
      width: 0;
      height: 0px;
      transform: rotate(-45deg);
    }
  }

  input:checked ~ label:before {
    background-color: $main;
    border-color: $main;

  }

  input:checked ~ label:after {

    width: 12px;
    height: 7px;
    border-bottom: 3px solid #fff;
    border-left: 3px solid #fff;
  }

  input:focus-visible ~ label:before {
    outline: 2px solid $main;
    outline-offset: 2px;
  }

  input[type="radio"] ~ label {

    &:before {
      border-radius: 50%;
    }
  }

  input[type="radio"]:checked ~ label:after {
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    background: $main;
    top: 4px;
    left: 5px;

  }

  input[type="radio"]:checked ~ label:before {
    background: #fff;
  }

  &.is--invalid {

    label:before {
      border-color: $red;
    }
  }
}


.form__checkbox--small {
  label {
    font-size: $font12;
  }
}

.form__checkbox--medium {
  label {
    font-size: $font16;
  }
}

.input--data {
  background: url("../img/ico/ico-date-input.svg") right 15px center no-repeat;
}

.form__input {
  margin-bottom: 15px;
  position: relative;

  label {
    font-size: $font10;
    margin-bottom: 6px;
    font-weight: 700;
    color: $green;
    display: block;
    position: absolute;
    background: #fff;
    padding: 0 5px;
    top: 10px;
    z-index: -1;
    opacity: 0;
    transition: top 0.3s ease-out;
    transition: top 0.3s ease-out;
  }

  textarea {
    width: 100% !important;
  }


  input,
  textarea {
    border-radius: 6px;
    border: 1px solid $input-border;
    width: 100%;
    display: block;
    color: $input;
    font-size: $font18;
    padding: 12px 15px;
    min-height: 54px;

    &:focus,
    &:focus-visible {
      border-color: $green;
      box-shadow: none;
      box-shadow: inset 0px 0px 0px 1px $green;
      outline-color: transparent;

      &::placeholder {
        opacity: 0;
      }
    }
  }

  input:focus ~ label, input:not(:placeholder-shown) ~ label,
  textarea:focus ~ label, textarea:not(:placeholder-shown) ~ label
  input:valid:required ~ label {
    opacity: 1;
    top: -5px;
    left: 20px;
    z-index: 5;
  }


  &.is--invalid {

    label {
      color: $red;
    }

    input,
    textarea {
      border-color: $red !important;
      box-shadow: inset 0px 0px 0px 1px $red;
      background-image: none;

      &:focus,
      &:focus-visible {
        outline: 2px solid $red;
        border-color: $red;
        box-shadow: none;

        // outline:none;
      }
    }

    &:after {
      content: "+";
      transform: rotate(45deg);
      color: $red;
      position: absolute;

      top: -3px;
      right: 20px;
      font-size: $font40;

    }
  }

  &.is--valid {

    input,
    textarea {
      border-color: $green !important;
      box-shadow: inset 0px 0px 0px 1px $green;
      background-image: none;


    }

    &:after {
      content: "";
      transform: rotate(-45deg);
      border-left: 2px solid $green;
      border-bottom: 2px solid $green;
      position: absolute;
      top: 20px;
      right: 20px;
      display: block;
      width: 12px;
      height: 8px;
    }
  }

  @media(max-width: $grid-breakpoints-lg) {
    input,
    textarea {
      font-size: $font16;
    }
  }

}


.form__input--bold {
  label {
    font-size: $font18;
    color: $txt;
    font-weight: 700;
    margin-bottom: 10px;
  }

  &.is--valid:after {
    top: 52px;
  }

  &.is--invalid:after {
    top: 46px;
  }

}

.form__nolabel {
  &.is--valid {
    &:after {
      top: 15px;
    }
  }

  &.is--invalid {
    &:after {
      top: 7px;
    }
  }
}


.form__input--error {
  color: $red;
  font-size: $font10;
  display: block;
  margin-top: 5px;

}

.form__checkbox {
  .form__input--error {
    margin-bottom: 5px;

  }
}

.form__select + .form__input--error {
  position: relative;
  top: -20px;
}


.form__switch {
  position: relative;
  display: inline-block;
  white-space: nowrap;


  /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
  }

  label {
    padding-right: 60px;;
    line-height: 30px;
    display: inline-block;
    font-size: $font20;
    position: relative;
  }

  /* The slider */
  label:before {
    content: "";
    height: 30px;
    width: 50px;
    border-radius: 16px;
    position: absolute;
    cursor: pointer;
    top: 50%;
    transform: translateY(-15px);
    right: 0;
    right: 0;
    bottom: 0;
    background-color: $bg-grey;
    -webkit-transition: .4s;
    transition: .4s;
    z-index: 2;
    cursor: pointer;
  }

  label:after {
    content: "";
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    right: 24px;
    bottom: 4px;
    border-radius: 11px;
    background-color: #959595;
    -webkit-transition: .4s;
    transition: .4s;
    z-index: 3;
    cursor: pointer;
  }

  input:checked + label:before {
    background-color: $main;
  }

  input:checked + label:after {
    background: #fff;
  }

  input:checked + label:after {
    right: 4px;
  }

  /* Rounded sliders */

}


.modal {
  .form__input {
    input, textarea {
      padding-top: 9px;
      padding-bottom: 9px;
      min-height: 48px;
    }
  }

  .form__input.is--invalid:after {
    top: -6px;
  }
  .form__input.is--valid:after {
    top: 16px;
  }
}

.form__nrInput{
  label{
    display:block;
    width:100%;
  }
  input{
    max-width:70px;
  }
}