@import "fonts";


html {
  font-size: 16px;
  scroll-behavior: smooth;

  @media(max-width: $grid-breakpoints-md) {
    font-size: 14px;
  }

  @media(max-width: 360px) {
    font-size: 12px;
  }
}

body {
  line-height: 1.5;
  font-size: $font18;
  font-family: $font-family-name;
  font-weight: 400;
  color: $txt;
  font-style: normal;
}

* {
  box-sizing: border-box
}

*:before,
*:after {
  box-sizing: border-box;
  font-family: $font-family-name;
}


.tooltip {
  position: relative;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 10px;

  &:after {
    content: "i";
    display: inline-block;
    min-width: 25px;
    height: 25px;
    background: $txt;
    color: #fff;
    text-align: center;
    line-height: 25px;
    border-radius: 50%;

  }

  span {
    opacity: 0;
    position: absolute;
    top: 100%;
    right: 0;

    background: #fff;
    z-index: -1000;
    box-shadow: 2px 2px 15px 0 rgb(0, 0, 0, 0.25);
    padding: 5px;
    font-size: $font12;
    color: $txt;
    width: 0;
    height: 0;
    overflow: hidden;
    text-align: center;
    line-height: 1.2;
  }

  &:hover {
    span {
      opacity: 1;
      z-index: 1;
      height: auto;
      width: auto;
      min-width: 200px;
    }
  }
}


iframe,
video {
  max-width: 100%;
  width: 100%;

}

strong {
  font-weight: 700;
}

.bold {
  font-weight: 700 !important;
}

.italic {
  font-style: italic;
}

.lh--2 {
  line-height: 2 !important;
}


.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font--9 {
  font-size: $font9 !important;
}

.font--10 {
  font-size: $font10 !important;
}

.font--12 {
  font-size: $font12 !important;
}

.font--14 {
  font-size: $font14 !important;
}

.font--18 {
  font-size: $font18 !important;
}

.font--20 {
  font-size: $font20 !important;
}

.main {
  color: $main !important;
}

.white {
  color: #fff !important;
}

.black {
  color: $txt !important;
}

.main2 {
  color: $main2 !important;
}


.txt--center,
.text-center {
  text-align: center !important;
}

.txt--left {
  text-align: left !important;
}

.txt--right {
  text-align: right !important;
}


.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}


.alert,
#komunikat,
.komunikat {
  border: 1px solid rgba(0, 0, 0, 0);
  margin-bottom: 20px;
  padding: 15px;
  text-align: center;
  font-size: $font20;
  line-height: 1.4;
  background-color: #f8fbcb;
  color: $txt !important;
  display: block;
  font-weight: 500;

}

.alert-success {
  background-color: $bg-grey2 !important;
  // border-color: #D6E9C6;

}

.alert-info {
  background-color: #f5f7f8 !important;
  // border-color: rgb(103, 121, 171);
  //     color: rgb(103, 121, 171);; }
}

.alert-warning {
  background-color: #f8f8cb !important;
  // border-color: #FBEED5;
  // color: #C09853;
}

.alert-danger {
  background-color: #faeded !important;
  // border-color: #f1e3e5;
  color: #ca0038 !important;
}

.alert-grey {
  background-color: $bg-grey !important;
  // border-color: #f1e3e5;
  // color: $red;
}


/* remember to define focus styles! */
:focus {
  outline: none;
}

:focus-visible {
  outline: 2px solid $main;
}

a,
button {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}


a {
  color: $txt;
  text-decoration: none;

  @media(hover: hover) {
    &:hover {
      color: $main;
    }
  }

  &:focus-visible {
    outline-offset: 2px;
  }
}


.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  z-index: -100;
  opacity: 0;
}

.history--back {
  width: 50px;
  height: 50px;
  display: block;
  margin-bottom: 0px;

  &:before {
    content: "" !important;
    width: 50px;
    height: 50px;
    background: url("../img/swiper-arrow-left.svg") center no-repeat;
    background-size: 16px;
    display: block;
  }

}


h1,
h2,
h3 {


  letter-spacing: initial;
  line-height: 1.2;
  font-weight: 700;
  font-family: $font2-family-name;

  a {
    color: inherit;
  }
}

h1 {
  font-size: $font40;
  margin-bottom: 30px;

}

h2 {
  font-size: $font40;
  margin-bottom: 30px;

}

h3 {
  font-size: $font30;
  margin-bottom: 20px;

}

.title {
  font-weight: 400;
  font-size: 56px;
  margin-bottom: 40px;

  strong {
    display: block;
    color: $main;
    font-size: 116%;
    line-height: 1;
  }
}

.title2 {
  font-size: $font30;
  font-weight: 700;
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  gap: 20px;

  &:before {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: $main;
    content: "";

  }
}

.title3 {
  font-size: $font40;
  font-weight: 700;
  margin-bottom: 25px;

  span {
    color: $main;
  }
}


.description {
  text-align: center;
  font-size: $font20;
  margin-bottom: 50px;
}


.overflow-hidden {
  overflow: hidden !important;
}


.relative {
  position: relative !important;
}


h4,
h5,
h6 {
  line-height: 1.3;
  font-size: $font18;
  font-weight: 700;
  margin-bottom: 10px;
  font-family: $font2-family-name;
}


@media(max-width: $grid-breakpoints-xxl) {
  .title {
    font-size: $font50;

  }

}

@media(max-width: $grid-breakpoints-xl) {
  h1, h2 {
    font-size: $font34;
  }
  .title {
    font-size: $font40;
    margin-bottom: 30px;

  }
  .title3 {
    font-size: $font36;
  }
}

@media(max-width: $grid-breakpoints-lg) {
  .title2 {
    font-size: $font34;
  }
  .title3 {
    font-size: $font34;
  }
}

@media(max-width: $grid-breakpoints-md) {
  h1, h2 {
    font-size: $font27;
    margin-bottom: 20px;
  }
  .title {
    font-size: $font34;
  }
  .title3 {
    font-size: $font30;
  }
}

@media(max-width: $grid-breakpoints-sm) {
  .title {
    margin-bottom: 20px;
  }
  .title2 {
    margin-bottom: 30px;
    gap: 10px;
    font-size: $font20;

    &:before {
      width: 12px;
      height: 12px;
    }
  }
  .title3 {
    font-size: $font28;
  }

}

@media(max-width: 400px) {

}


ul,
ol {
  list-style: none;
  padding-left: 0;

}

.page__txt {


  line-height: 1.6;

  h3 {
    font-size: $font25;
  }

  img {
    max-width: 100%;
    margin-top: 20px;
    margin-bottom: 30px;
    display: inline-block;
    height: auto;
  }

  ul,
  ol {

    padding-left: 30px
  }

  ul {
    list-style: none;

    li {
      position: relative;

      &:before {
        display: inline-block;
        width: 6px;
        height: 6px;
        position: absolute;
        left: -14px;
        top: 11px;
        content: "";
        background: $main;
      }
    }
  }

  ol {
    list-style: auto;
  }

  ol,
  ul,
  p {
    margin-bottom: 15px;
  }

  li {
    margin-bottom: 5px;
  }

  a {
    text-decoration: underline;
  }


  h2:not(:first-child) {
    margin-top: 35px;
  }

  h3:not(:first-child) {
    margin-top: 25px;
  }

  h3:not([class]) {
    font-size: $font26;
  }


}

.article__imggroup {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  padding-top: 20px;
  padding-bottom: 20px;

  img {
    max-width: calc(33.33% - 10px);
    flex: 0 0 33.33%;
    margin: 0;
  }

  @media(max-width: $grid-breakpoints-md) {
    gap: 10px;

    img {
      max-width: calc(50% - 10px);
      flex: 0 0 50%;
    }
  }

  @media(max-width: $grid-breakpoints-md) {
    gap: 10px;

    img {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}


.link--underline {
  text-decoration: underline;
  font-weight: bold;
  color: $txt;

  @media(hover: hover) {
    &:hover {
      color: $main2;
    }
  }
}

.link--underline-main {
  text-decoration: underline;
  color: $main2;

  @media(hover: hover) {
    &:hover {
      color: $main;
    }
  }
}

.link--arrowdown {
  display: block;
  padding-left: 35px;
  font-size: $font18;
  position: relative;
  line-height: 1;
  padding-top: 10px;
  padding-bottom: 10px;
  background: transparent;
  border: none;
  color:$txt;

  &:before {
    content: url("../img/arrow-down.svg");
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  @media(hover:hover){
    color:$main;
  }
}

.link--contact {
  display: inline-block;
  padding: 5px 0 5px 35px;
  font-size: $font18;
  background: url("../img/ico-phone-2.svg") 0 center no-repeat;
  background-size: 28px;
  white-space: nowrap;
}

.link--mail {
  background-image: url("../img/ico-mail3.svg");
}


input:not([type="number"], [type="radio"], [type="checkbox"]), textarea,
button {
  -webkit-appearance: none;

  font-family: $font-family-name;
  border-radius: 5px;

}

input,
textarea,
select {

  border: 1px solid $input-border;
  line-height: 1.6;
  padding: 10px 15px;
  color: $input;
  font-family: $font-family-name;
  border-radius: 5px;

  &::placeholder {
    font-family: $font-family-name;
  }

}

::placeholder {
  color: $placeholder;
  font-weight: 400;
  font-style: inherit;
  font-family: $font-family-name;
}

.disabled {
  color: $placeholder;
}


.nowrap{
  white-space:nowrap;
}

.btn {
  border: none;
  background-color: $main;
  color: #fff !important;
  font-size: $font18;
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease-out;
  border: none;
  line-height: 1.2;
  padding: 12px 30px;
  height: 60px;
  vertical-align: middle;
  text-align: center;
  border-radius: 5px;
  font-family: $font2-family-name;

  @media(hover: hover) {
    &:hover {
      background-color: $main-hover;
      color: #fff !important
    }
  }

  @media(max-width: $grid-breakpoints-md) {
    padding: 18px 20px;
  }

}

.btn--settings {
  line-height: 1;

  &:before {
    content: url("../img/ico-settings.svg");
    margin-right: 10px;
    position: relative;
    top: 3px;
  }
}

.btn--black {
  background-color: #000;
  @media(hover: hover) {
    &:hover {
      background-color: $main;
      color: #fff !important
    }
  }
}

.btn--order {
  &:before {
    content: url("../img/ico-order.svg");
    margin-right: 10px;
    position: relative;
    top: 2px;
  }
}

.btn--trans {
  border: 1px solid $line3;
  background: transparent;
  color: $txt !important;
  transition: border 0.3s ease-out;
  @media(hover: hover) {
    &:hover {
      background: transparent;
      border-color: $main;
      color: $txt !important
    }
  }

  &.btn--settings {
    &:before {
      content: url("../img/ico-settings2.svg");
    }
  }
}

.btn--pdf {

  &:before {
    content: url("../img/ico-pdf.svg");
    margin-right: 10px;
    position: relative;
    top: 2px;
  }

}


.bg--main2 {
  background: $main2 !important;
}

.bg--main {
  background: $main !important;
}

.bg--grey {
  background: $bg-grey !important;
}

.bg--light {
  background: $main-light !important;
}

hr {
  border: none;
  border-top: 1px solid $line3;
  margin-top: 20px;
  margin-bottom: 20px;
}


table {
  max-width: 100%;
  font-size: $font14;
  border-collapse: separate;
  line-height: 1.4;
  font-weight: 300;

  * {
    line-height: 1.4;
  }

  th {
    background: transparent;
    font-weight: 700;
    border-bottom: 1px solid $line;
    padding: 10px 10px;
    vertical-align: middle;

  }

  td {
    border-bottom: 1px solid $line;
    padding: 10px 10px;
    vertical-align: middle;
  }

  @media(max--width: 1400px) {
    font-size: $font16;
  }


  thead {
    position: relative;

    th {
      border: none;
      padding: 12px 10px 12px 10px;
      border-top: 2px solid $main2;
      border-bottom: 2px solid $main2;
    }

  }

  .align-right {
    text-align: right;
  }

  .align-left {
    text-align: left;
  }

  .align-center {
    text-align: center;
  }

  tbody {

    tr:first-child {

      th,
      td {
        border-top-width: 5px;
      }
    }

  }
}


.table__wrapper {
  position: relative;

  table {
    width: 100%;
    position: relative;

    a {
      display: inline-block;
      padding: 1px 5px;
      text-decoration: underline;
      font-weight: bold;
    }
  }


  @media(max-width: 768px) {
    overflow: auto;

    table {
      min-width: 800px;
      width: initial;


      th,
      td {
        padding: 8px 5px;
      }
    }

    &.table__wrapper--big {
      table {
        min-width: 1300px;
        width: initial;
      }
    }
  }

}


.btn--table {
  background: $main;
  color: #fff;
  padding: 5px 10px !important;

  margin-top: 7px;
  text-decoration: none !important;

  @media(hover: hover) {
    &:hover {
      background: $main2;
      color: #fff;
    }
  }
}


.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 120;
  padding: 30px;
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: visibility 0s linear 0.1s, opacity 0.3s ease;

  &.open {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s;
  }

  .link--underline-main {
    display: block;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 21;
    background-color: rgba(0, 0, 0, 0.7);

  }

  &__close {
    position: absolute;
    top: 0px;
    right: 0px;
    border: none;
    outline: none;
    background: none;
    width: 48px;
    height: 48px;
    display: inline-block;
    background: #fff;
    color: #000;
    padding: 0;
    z-index:50;

    &:after {
      content: "+";

      transform: rotate(45deg);
      font-size: 30px;
      position: absolute;
      top: 4px;
      left: 16px;
      transition: all 0.3s ease-out;

    }

    &:hover {
     color:$main;
    }
  }


  &__container {
    position: absolute;
    z-index: 22;
    max-width: 1520px;
    width: 96%;

    max-width: 700px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    overflow:hidden;

    background-color: #fff;
    border-radius: 5px;
  }

  &__body {
    max-height: 90vh;
    overflow: auto;
    padding: 50px;
  }

  &__title {

    margin-bottom: 30px;
    text-transform: uppercase;
    text-align: left;
    margin-top: 0;
  }


  h4 {
    font-size: 31px;
    margin-bottom: 10px;
    color: #000;
  }

  @media(max-width: $grid-breakpoints-lg) {
    &__title {

      font-size: 24px;
    }

    h4 {
      font-size: 20px;
      margin-bottom: 5px;
    }


    &__body {
      max-height: 90vh;
      overflow: auto;
      padding: 50px 25px;
    }


  }

  ul {
    list-style: disc;
    padding-left: 30px;
  }

}


.img__wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
  height: 0;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  img {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.page__des {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 60px;
  margin-top: 50px;
  font-size: $font20;
  text-align: center;
}

.sl-navigation button {
  width: 48px !important;
  text-indent: -500px !important;
  background-size: 70% !important;

  &:before {
    content: none;
  }
}

.ul--square {
  ul {
    padding-left: 0px;
    font-size: $font14;
  }

  li {
    position: relative;
    padding-left: 25px;

    &:before {
      display: inline-block;
      width: 14px;
      height: 14px;
      background: $main;
      content: "";
      position: absolute;
      top: 3px;
      left: 0;
    }
  }
}
