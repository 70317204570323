@import "components/variables";
@import "components/reset";
@import "components/grid.scss";
@import "components/typography.scss";
@import "components/form.scss";
@import "components/footer.scss";
@import "components/header.scss";


#scrollDown {
  position: absolute;
  bottom: 60px;
  right: calc(50vw - 25px);

  z-index: 20;
  color: $main;
  height: 50px;
  width: 50px;
  background: none;
  border: none;
  transition: none;

  &:before {
    content: "";
    width: 13px;
    height: 13px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    display: block;
    top: 50%;
    left: 50%;
    transform-origin: center;
    transform: translate(-50%, -50%) rotate(-45deg);
    position: absolute;
  }


}

.hero {
  position: relative;

  .swiper-slide {
    height: 960px;
    max-height: 90vh;

    padding-bottom: 100px

  }

  h1, h2 {

  }

  .link--underline {
    color: #fff;
  }

  .container {
    height: 100%;
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 12;
    color: #fff;
  }


  @media(min-width: $grid-breakpoints-sm) {
    .swiper-pagination {
      text-align: right;
      left: 50% !important;
      transform: translateX(-50%);
      bottom: 60px !important;
      max-width: $container-max-width-xxl;
      padding-right: 80px;
      padding-right: 80px;

    }
  }

  .swiper-pagination-bullet {
    background: #fff !important;

  }

  .swiper-button-prev, .swiper-button-next {
    &:after {
      filter: brightness(0) invert(1);
    }
  }


  @media(min-width: $grid-breakpoints-lg) {
    .swiper-button-next {
      right: 80px;
    }

    .swiper-button-prev {
      left: 80px;
    }
  }

  .swiper-slide {
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      display: block;
      width: 100%;
      height: 40%;
      z-index: 5;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.19), transparent);

    }

    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: "";
      display: block;
      width: 100%;
      height: 40%;
      z-index: 5;
      background-image: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.19));

    }
  }

}

.hero__p {
  font-size: 20px;
  margin-bottom: 20px;
}

.hero__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;

}

.hero__btns {
  display: flex;
  align-items: center;
  gap: 10px 30px;
  flex-wrap: wrap;
}

@media(max-width: $grid-breakpoints-3xl) {
  .hero .swiper-slide {
    height: 800px;
  }
}

@media(max-width: $grid-breakpoints-lg) {
  .hero .swiper-slide {
    height: 700px;
  }
  .hero__p {
    font-size: $font18;
    margin-bottom: 10px;
  }
}

@media(max-width: $grid-breakpoints-sm) {
  .hero__p {
    font-size: $font14;
  }
  .hero {

    .title {
      font-size: $font24;
      margin-bottom: 15px;
    }

    .container {
      padding-left: 20px;
      padding-right: 20px;
    }

    .swiper-slide {
      height: 460px;
      padding-bottom: 70px;
    }

    .swiper-button-prev, .swiper-button-next {
      display: none;

    }
  }


  .hero__btns {
    flex-direction: column;
    justify-content: center;
  }
  #scrollDown {
    bottom: 30px;
  }
}


.partners {
  padding-top: 80px;
  padding-bottom: 50px;
}

.partners__list {
  display: flex;
  gap: 10px 20px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
}

.partners__item {
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-height: 90px;
    max-width: 100%;
    object-fit: contain;
  }
}

@media(max-width: $grid-breakpoints-sm) {
  .partners {
    padding-top: 40px;
    padding-bottom: 40px;
  }

}


.contactW__section {
  position: relative;
  min-height: 950px;
  padding-top: 100px;
  padding-bottom: 100px;
  color: #fff;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.contactW__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  z-index: -1;
}

.contactW__left {
  max-width: 50%;

  p {
    margin-bottom: 40px;
  }
}

@media(max-width: $grid-breakpoints-lg) {
  .contactW__section {
    min-height: 480px;
    padding-top: 100px;
    align-items: flex-end;
    padding-bottom: 45px;

  }

  .contactW__left {
    max-width: 100%;
  }
}


.hssp__section {
  position: relative;
  padding-top: 140px;
  padding-bottom: 80px;
  color: #fff;
  overflow: hidden;
}

.hssp__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  z-index: -1;
}

.hssp__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 40px;
  margin-top: 70px;
}

.hssp__list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}

.hssp__item {
  position: relative;
  padding-left: 65px;
  line-height: 1.2;

  img {
    position: absolute;
    top: 0;
    left: 0;
  }

  strong {
    display: block;
    font-size: $font20;
    font-family: $font2-family-name;
    margin-bottom: 5px;
  }

  span {
    color: $txt-light-w;
    display: block;
    font-size: $font16;

  }
}

@media(max-width: $grid-breakpoints-sm) {
  .hssp__section {
    padding-top: 80px;
  }
  .hssp__wrapper {
    margin-top: 40px;
  }
}


.characteristics {
  background: $blue;
  color: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
}

.characteristics__list {
  display: flex;
  justify-content: space-between;
  gap: 20px 0px;
}

.characteristics__item {

  flex: 0 0 11%;
  position: relative;
  text-align: center;

  img {
    max-width: 100%;
  }

  span {
    display: block;
    text-align: center;
    font-size: $font14;
    line-height: 1.2;
    margin-top: 15px;
  }
}

@media(max-width: $grid-breakpoints-xl) {
  .characteristics__item span {
    font-size: $font11;
  }
}

@media(max-width: $grid-breakpoints-lg) {
  .characteristics__list {
    flex-wrap: wrap;
    justify-content: center;

  }

  .characteristics__item {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media(max-width: $grid-breakpoints-sm) {

  .characteristics__item {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}


.pr__nav {
  position: relative;
  margin-bottom: 40px;
  @media(max-width: $grid-breakpoints-sm) {
    margin-bottom: 20px;
  }
}

.pr__nav-slider {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  background: $main;
  height: 10px;
  width: 70px;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  z-index: 10;

}


.pr__nav--count {
  position: relative;

  &:after {
    content: "";
    width: 100%;
    height: 10px;
    background: #D3D3D3;
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: -1;
    display: block;
    border-radius: 5px;
    z-index: 1;
  }

}

.tabs__navlist {
  display: inline-flex;
  gap: 10px;
  position: relative;
  max-width: 100%;
  z-index: 1;


  @media(min-width: $grid-breakpoints-xl) {
    gap: 20px;
  }
}

.tabs__nav-trigger {
  padding: 10px 5px 30px 5px;
  border: none;
  background: none;
  display: inline-block;
  font-size: $font18;
  transition: all 0.3s ease-in-out;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.3);
  font-weight: 700;

  @media(hover: hover) {
    &:hover {
      color: #000;
    }
  }

  &[aria-selected="true"] {
    color: #000;
  }
}

.tabs--links {
  .pr__nav-slider {
    display: none;
  }

  .tabs__nav-item.is-active {
    a {
      color: #000;
      position: relative;
      padding-left: 0;
      padding-right: 0;

      &:after {
        content: "";
        width: 100%;
        height: 10px;
        background: $main;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        display: block;
        border-radius: 5px;
        z-index: 20;
      }
    }

  }
}


.tabs__panel, .tabs2__panel {
  display: none;
  margin-bottom: 10px;
  &.is-current {
    display: block;
  }

  @media(min-width: $grid-breakpoints-md) {
    margin-bottom: 30px;
  }
}

@media(max-width: $grid-breakpoints-md) {
  .tabs__nav-trigger {
    font-size: $font16;
  }
}


.blog__list {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 30px;
}

.blog__item {
  flex: 0 0 100%;
  padding: 0;
  height: 100%;

  .blog__link {
    display: block;
    height: 100%;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    outline: 2px solid transparent;
    background: #fff;
  }
}

.blog__link--first {
  position: relative;

  .blog__figure {
    padding-top: 63%;
    margin-bottom: 0;
  }

  .blog__txt {
    z-index: 30;
    background: #fff;
    border-radius: 5px;
  }

  @media(min-width: $grid-breakpoints-sm) {
    .blog__txt {
      position: absolute;
      bottom: 20px;
      width: calc(50% - 40px);
      right: 20px;

    }
    .blog__figure {

      border-radius: 5px;
      height: 100%;

      img {
        border-radius: 5px;
      }
    }
  }
  @media(max-width: $grid-breakpoints-sm) {
    .blog__figure {
      width: 100vw;
      margin-left: -30px;
      border: none;

    }
    .blog__txt {
      position: relative;
      top: -20px;
    }
  }
}


.blog__item--r {
  max-width: calc(33.33% - 20px);
  flex: 0 0 33.33%;
}

.blog__figure {
  width: 100%;
  padding-top: 83%;
  position: relative;
  overflow: hidden;
  height: 0;
  margin-bottom: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  img {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
  }
}

.blog__title {
  font-size: $font30;
  font-weight: 700;
  min-height: 75px;
  margin-bottom: 15px;
}

.blog__txt {
  padding: 20px 30px;


}

.blog__summary {
  font-size: $font18;
  color: $txt;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.blog__link {
  @media(hover: hover) {
    &:hover {
      outline-color: $main;
    }
  }
}


.blogM {
  overflow: hidden;
  padding-top: 90px;
  padding-bottom: 40px;
  background: $bg-grey;
}

.blogSwiper {
  position: relative;
  margin-left: -10px;

  .swiper-nav {
    padding-left: 20px;
  }
}

.blogSwiper .swiper {

  width: calc(100vw - 80px);
  @media(min-width: 1680px) {
    width: calc(50vw + 830px);
  }

  @media(max-width: $grid-breakpoints-md) {
    width: calc(100vw - 10px);
  }


}

.blogSwiper .swiper-slide {
  padding: 10px;
  height: auto;
  max-width: 28%;
}


@media(max-width: $grid-breakpoints-xxl) {
  .blog__title {
    font-size: 25px;
    min-height: 80px;
  }

  .blogSwiper .swiper-slide {

  }
}

@media(max-width: $grid-breakpoints-xl) {
  .blog__title {
    font-size: 20px;
    min-height: 55px;
  }

  .blogSwiper .swiper-slide {
    max-width: 40%;
  }
}

@media(max-width: $grid-breakpoints-md) {
  .blog__title {
    font-size: 17px;
    min-height: 44px;
  }

  .blog__txt {
    padding: 20px;
  }
}

@media(max-width: $grid-breakpoints-sm) {
  .blogSwiper .swiper-slide {
    max-width: 75%;
  }

  .blogM {
    padding-top: 30px;
  }
}


.swiper-pagination-bullet {
  background: $main !important;
  opaxcity: 0.5;

  width: 12px !important;
  height: 12px !important;
  border-radius: 0 !important;
}

.swiper-pagination-bullet-active {
  opacity: 1 !important;
}


body {
  .swiper-button-prev, .swiper-button-next {
    width: 48px;
    height: 48px;
  }


  .swiper-button-next:after {
    content: "" !important;
    width: 48px;
    height: 48px;
    background: url("../img/swiper-right-b.svg") center no-repeat;
    background-size: 16px;
  }

  .swiper-button-prev:after {
    content: "" !important;
    width: 48px;
    height: 48px;
    background: url("../img/swiper-left-b.svg") center no-repeat;
    background-size: 16px;
  }
}

.swiper-nav {
  max-width: 500px;
  display: flex;
  gap: 20px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;

  .swiper-scrollbar {
    height: 10px;
    width: 100%;
    cursor: pointer;
  }

  .swiper-scrollbar-drag {
    background: #3D3D3C;
  }

  .swiper-button-prev, .swiper-button-next {

    position: relative;
    transform: none;
    top: 0;
    margin: 0;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  @media(max-width: $grid-breakpoints-sm) {
    margin-top: 30px;
  }


}

.swiper-button-disabled {
  opacity: 0.08 !important;
}

.swiper-nav2 {
  .swiper-button-prev, .swiper-button-next {

    display: flex;
    align-items: center;
    justify-content: center;

    &:after {
      content: "";
      width: 10px;
      height: 10px;
      display: block;
      border-left: 2px solid #000;
      border-bottom: 2px solid #000;
      transform: rotate(45deg);
      margin: 0;
      background-image: none;
    }
  }

  .swiper-button-prev {
    left: -20px;
    margin-top: -32px;
  }

  .swiper-button-next {
    right: -20px;
    margin-top: -32px;

    &:after {
      transform: rotate(-135deg);
    }
  }
}


.configuration {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  position: relative;
}

.configuration__left,
.configuration__right {
  width: 100%;
  position: relative;
}

.configuration__left {
  padding-top: 80px;
  padding-bottom: 40px;
  padding-right: 20px;
  margin-left: auto;

  h3 {
    font-size: $font40;
  }

  h4 {
    font-weight: 400;
    text-transform: uppercase;
    font-size: $font20;
    margin-bottom: 50px;
  }

}

.configuration__list {
  display: flex;
  gap: 30px;
  margin-bottom: 70px;
}

.configuration__item {
  strong {
    display: block;
    font-size: $font20;
  }

  span {
    color: $txt-light;
  }
}


.tabSwiper {
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 60px;

  .swiper-slide {
    width: auto;
  }

  @media(max-width: $grid-breakpoints-3xl) {
    margin-bottom: 40px;
  }

  @media(max-width: $grid-breakpoints-xl) {
    margin-bottom: 30px;
  }
}


.configurationImgWrapper {
  width: 100vw;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 500px;
    width: 94%;
  }


}

.configuration__wrapperBack {
  width: 100vw;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 500px;
    width: 94%;
  }

}


.configuration-clipping-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  z-index: 10;
  display: block;
  background: #fff;

  &:after {
    content: "";
    display: block;
    width: 100px;
    height: 100%;
    left: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 10;
    background-image: linear-gradient(to right, rgba(51, 87, 190, 0.1), transparent);
  }

  .configuration__wrapper {
    overflow: hidden;
    height: 100%;
  }

  //     transition: width 0.1s ease-out;
  // }
}

.configuration__move {
  width: 14px;
  height: 30px;
  border-radius: 7px;
  background: $blue;
  position: absolute;
  top: 10%;
  right: 0;
  transform: translate(7px, 10px);
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  z-index: 20;

  &:before {
    content: "||";
    font-size: 12px;
    font-weight: 700;
  }
}


.configuration__imgLeft--2 {
  opacity: 1;

  @media (min-width: 576px) {
    .hero__txt {
      max-width: 360px;
    }
  }
}


@media(min-width: $grid-breakpoints-md) {

  .configuration__left {
    flex: 0 0 50%;
    width: 50%;
    height: 100%;
    justify-content: flex-end;

  }

  .configuration__right {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .configuration-clipping-wrapper {
    .configurationImgWrapper {
      width: 50vw;

    }
  }
  .configuration__wrapperBack {
    width: 50vw;

  }
}

@media(max-width: $grid-breakpoints-xxl) {
  .configuration__left {
    h3 {
      font-size: $font34;
    }

    h4 {
      font-size: $font18;
    }
  }
}

@media(max-width: $grid-breakpoints-xl) {
  .configuration__left {
    h3 {
      font-size: $font30;
      margin-bottom: 10px;
    }

    h4 {
      font-size: $font16;

      margin-bottom: 30px;
    }
  }

  .configuration__list {
    margin-bottom: 40px;
    flex-wrap: wrap;
  }

  .configuration__item {
    max-width: calc(50% - 15px);
    flex: 0 0 50%;
  }
}

@media(max-width: $grid-breakpoints-md) {
  .configuration__left {
    h3 {
      font-size: $font26;
    }

    h4 {
      font-size: $font14;
      margin-bottom: 15px;
    }
  }
}


@media(max-width: $grid-breakpoints-sm) {
  .configuration__left {
    padding-top: 40px;

    .btn--settings {
      display: flex;
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
  }
}


.swiper-wrapper {
  &.tabs__navlist {
    gap: 0;

    &:after {
      //content: none;
      //content: none;

    }

    .tabs__nav-trigger {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.about__section {
  padding-top: 140px;
  padding-bottom: 140px;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    width: 140px;
    height: 140px;
    position: absolute;
    right: 50%;
    bottom: 0;
    background: $main;
  }
}

.about__left {
  width: 50%;
  max-width: 670px;
  padding-right: 40px;

  padding-bottom: 100px;

  p {
    font-size: $font20;
  }
}

.about__imgRight {
  position: absolute;
  left: 50%;
  top: 0;
  height: 730px;
  width: auto;
}

.about__accordion {
  padding-top: 70px;
}


@media(max-width: $grid-breakpoints-lg) {

  .about__accordion {
    padding-top: 20px;
  }

  .about__left {
    width: 100%;
    max-width: 100%;
    padding-right: 0;
    padding-bottom: 40px;
  }

  .about__imgRight {
    position: static;
    width: 100%;
    height: auto;
    margin-left: 30px;
  }
  .about__section:after {
    width: 110px;
    height: 110px;
    right: initial;
    left: 0;
    bottom: 30px;
  }
}

@media(max-width: $grid-breakpoints-md) {
  .about__section:after {
    width: 60px;
    height: 60px;
    left: 0;
    bottom: 80px;
  }
}

@media(max-width: $grid-breakpoints-sm) {
  .about__section {
    padding-top: 50px;

    .title {
      text-align: center;
    }

    .container > p {
      text-align: center;
    }
  }
}

@media(max-width: 375px) {
  .about__section:after {
    width: 50px;
  }
}


.accordion__content {
  display: none;
  padding: 0;

  p, ul, ol {
    margin-bottom: 20px;
  }
}

.accordion {
  border-bottom: 1px solid $line2;
  position: relative;
  padding: 15px 0;

  &:after {
    content: "";
    position: absolute;
    left: 0px;
    bottom: -1px;
    background: $main;
    transition: all 0.3s ease-out;
    transform-origin: center;
    width: 0px;
    display: block;
    height: 1px;
  }

  @media(hover: hover) {
    &:hover {
      &:after {
        width: 50px;
      }
    }
  }

  &.active {
    &:after {
      width: 80px;
    }
  }
}

.accordion__trigger {
  border: none;
  width: 100%;
  display: block;
  position: relative;

  text-align: left;
  background: transparent;

  font-size: $font30;
  // background: $main;
  font-weight: 400;
  padding: 20px 50px 20px 0px;
  color: $txt;
  // color: #fff;


  &:before, &:after {
    content: "";
    position: absolute;
    right: 0px;
    top: 50%;
    background: $main;
    transition: all 0.3s ease-out;
    transform-origin: center;
    width: 25px;
    display: block;
    height: 1px;
  }

  &:after {
    transform: rotate(-90deg);
  }

  .hide {
    display: none;
  }

  .show {
    display: inline;
  }

}

.accordion__trigger[aria-expanded="true"] {
  &:after {
    transform: rotate(0deg);
  }
}

.accordion2 {
  border: none;

  &:after {
    content: none;
  }
}

.accordion2 {
  padding: 0;

  .accordion__trigger {
    padding: 15px 0px 15px 50px;
    font-size: $font18;
    font-weight: 400;

    &:before, &:after {
      right: initial;
      left: 0;
      background: $txt;
    }

    @media(hover: hover) {
      &:hover {
        color: $main;

        &:before, &:after {
          background: $main;
        }
      }
    }
  }
}

.accordion__inner {
  padding-bottom: 10px;
}

.accordion3 {
  background: $bg-grey2;
  padding: 0 20px;
  border-radius: 5px;
  border-radius: 5px;
  border: none;
  margin-bottom: 20px;

  &:after {
    content: none;
  }

  .accordion__trigger {
    font-size: $font20;
    font-weight: 700;
    padding: 20px 30px 20px 0;

    &:after {
      content: none;
    }

    &:before {
      width: 12px;
      height: 12px;
      border-left: 1px solid $txt;
      border-bottom: 1px solid $txt;
      transform-origin: center;
      transform: rotate(-45deg);
      background: transparent;
      top: 23px;

    }

    &[aria-expanded="true"] {
      &:before {
        transform: rotate(135deg);
        top: 30px;
      }
    }
  }
}

.accordion4 {
  background: transparent;
  padding: 0;
  margin-bottom: 0px;
  margin-top: 0px;
  border-radius: 0;
  border-bottom: 1px solid $line3;

  .accordion__trigger {
    font-weight: 400;
  }
}


@media(max-width: $grid-breakpoints-xxl) {
  .accordion__trigger {
    font-size: $font28;
  }
}

@media(max-width: $grid-breakpoints-xl) {
  .accordion__trigger {
    font-size: $font26;
  }
}

@media(max-width: $grid-breakpoints-md) {
  .accordion__trigger {
    font-size: $font24;
    padding-top: 14px;
    padding-bottom: 14px;
  }
}


.offertSwiper {
  .swiper-slide {
    height: auto;
    width: 22%;
  }

  .swiper {
    width: calc(100vw - 80px);
    @media(min-width: 1680px) {
      width: calc(50vw + 820px);
    }
    @media(max-width: $grid-breakpoints-md) {
      width: calc(100vw - 10px);
    }
  }
}

.offertSwiperNav {
  .tabs__nav-trigger {
    font-weight: 400;
    color: $txt;

    &[aria-selected=true] {
      font-weight: 500;
    }
  }

  .swiper {
    z-index: 12;
  }
}

.offert__section {
  padding-top: 80px;
  padding-bottom: 40px;
  overflow: hidden;
  .tabs__panel{
    min-height:540px;

  }
}


.offert__header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  margin-bottom: 40px;
  justify-content: space-between;

  .title {
    margin-bottom: 0;
  }
}

@media(max-width: $grid-breakpoints-xl) {
  .offertSwiper {
    .swiper-slide {
      width: 30%;
    }
  }
}

@media(max-width: $grid-breakpoints-lg) {
  .offertSwiper {
    .swiper-slide {
      width: 40%;
    }
  }
}

@media(max-width: $grid-breakpoints-sm) {

  .offert__section {
    padding-top: 40px;
    .tabs__panel {
      min-height: 440px;
    }
  }
  .offertSwiper {

    margin-left: -10px;

    .swiper-slide {
      width: 75%;
    }
  }

  .offert__header {
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
  }
}


.tabs2__navlist {
  display: flex;
  gap: 0;

  li:first-child {
    .tabs2__nav-trigger {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }

  li:last-child {
    .tabs2__nav-trigger {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.tabs2__nav-trigger {
  background: #fff;
  color: $txt-light;
  border: 1px solid $line;
  font-size: $font16;
  padding: 15px 30px;
  text-transform: uppercase;
  font-weight: 700;
  border-radius:0;

  &[aria-selected='true'] {
    color: #fff;
    background: $txt-dark;
    border-color: $txt-dark;
  }

}


.sector--main {
  margin-bottom: 130px;
}


.sector__list {
  margin-top: -30px;
  display: flex;
  flex-wrap: wrap;
  gap: 28px;
  justify-content: center;
  margin-bottom: 30px;
}


.sector__item {
  flex: 0 0 25%;
  max-width: calc(25% - 21px);
}

.sector__link, .card__link {
  padding-top: 70%;
  height: 0;
  overflow: hidden;
  display: block;
  width: 100%;
  position: relative;

  &.active {

    h3 {
      position: relative;
      padding-top: 10px;

      &:after {
        content: "";
        display: block;
        width: 20px;
        height: 2px;
        background: #fff;
        position: absolute;
        top: 0;

        left: 50%;
        margin-left: -10px;
      }
    }
  }
}


.card__front, .card__back, .card {
  background: $main;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  overflow: hidden;
  backface-visibility: hidden;
  padding: 20px;

  h3 {
    font-size: $font20;
    margin-bottom: 0;
    text-transform: uppercase;
  }
}

.card__back {
  transform: rotateY(180deg);
}

.sector__link:hover .card__front {
  transform: rotateY(-180deg); //to rotate it towards left
}

.sector__link:hover .card__back {
  transform: rotateY(0); // show backside of card on hover
}

.card__link {
  @media(hover: hover) {
    &:hover {
      .card {
        background: $main-hover;
      }
    }
  }
}

@media(max-width: $grid-breakpoints-xxl) {

  .sector__list {
    gap: 16px;
  }
  .sector__item {
    max-width: calc(25% - 12px);
  }
  .card__front, .card__back {
    h3 {
      font-size: $font16;
    }
  }
}

@media(max-width: $grid-breakpoints-lg) {
  .sector__item {
    flex: 0 0 33.33%;
    max-width: calc(33.33% - 11px);
  }
}

@media(max-width: $grid-breakpoints-md) {
  .sector__item {
    flex: 0 0 50%;
    max-width: calc(50% - 8px);
  }


  .sector--main {
    margin-bottom: 70px;
  }
}


@media(max-width: 420px) {
  .card__front, .card__back, .card {
    padding: 20px 10px;


  }


  .sector__list {
    gap: 8px;
  }

  .sector__item {
    flex: 0 0 50%;
    max-width: calc(50% - 4px);
  }

}


.sector__list2 {
  margin-top: 50px;
  margin-bottom: 200px;

  .sector__item {
    flex: 0 0 12.5%;
    max-width: calc(12.5% - 25px);

    h3 {
      font-size: 9px;
    }

    .card__front, .card__back {
      padding: 10px;
    }
  }

  @media(max-width: $grid-breakpoints-xxl) {
    .sector__item {
      max-width: calc(12.5% - 14px);
    }
  }

  @media(max-width: $grid-breakpoints-xl) {
    gap: 10px;
    .sector__item {
      flex: 0 0 12.5%;
      max-width: calc(12.5% - 9px);
    }
  }
  @media(max-width: $grid-breakpoints-lg) {
    margin-top: 0px;
    margin-bottom: 80px;
    gap: 12px;
    .sector__item {
      flex: 0 0 25%;
      max-width: calc(25% - 9px);
    }
  }
  @media(max-width: $grid-breakpoints-sm) {
    gap: 12px;
    margin-bottom: 30px;
    .sector__item {
      flex: 0 0 33.33%;
      max-width: calc(33.33% - 8px);
    }
  }
}


.product__section {
  margin-top: -30px;
  overflow: hidden;
}


.product__wrapper {
  margin-bottom: 50px;

  @media(max-width: $grid-breakpoints-sm) {
    margin-bottom: 20px;
  }
}


.product__img {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-45%, 50%);
  width: auto;
  height: 180px;
}

.produkt__item {
  background: $main;
  text-align: center;
  color: #fff;
  padding: 50px 20px 100px 20px;
  min-height: 350px;
  position: relative;
  margin-bottom: 120px;

  .history--back {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 10px;

  }

  &.produkt__item--pb0 {
    padding-bottom: 50px;
    margin-bottom: 0px;
  }

  h2 {
    position: relative;
    font-size: $font30;
    margin-bottom: 60px;

    &:after {
      display: block;
      width: 30px;
      height: 2px;
      content: "";
      position: absolute;
      background: #fff;
      bottom: -30px;
      left: 50%;
      margin-left: -15px;
    }
  }

  p {
    font-size: $font18;
    font-style: italic;
    max-width: 550px;
    margin-left: auto;
    margin-right: auto;

    line-height: 1.2;
  }

  .product__txt--wide {
    max-width: 910px;
  }

  @media(max-width: $grid-breakpoints-xl) {
    min-height: 300px;
    h2 {
      font-size: $font28
    }

    .product__img {
      height: 120px;
    }
  }

  @media(max-width: $grid-breakpoints-md) {
    padding: 35px 20px 90px 20px;
    min-height: auto;
    margin-bottom: 90px;

    h2 {
      font-size: $font26;
    }
  }


  @media(max-width: $grid-breakpoints-sm) {
    h2 {
      font-size: $font22;
      margin-bottom: 40px;

      &:after {
        width: 20px;
        bottom: -10px;
        margin-left: -15px;
      }
    }
    margin-bottom: 70px;
  }

}


.file__list, .file__wrapper {


}


.file__item {
  display: flex;
  align-items: center;
  padding-left: 70px;
  background: url("../img/pdf.svg") 0px center no-repeat;
  background-size: 48px;
  border-bottom: 1px solid $line;
  gap: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.file__txt {
  width: 100%;
  font-size: $font18;

  p {
    margin-bottom: 0;
  }
}


.file__link {
  display: inline-flex;
  align-items: center;
  min-height: 50px;
  justify-content: center;
  padding: 10px 20px 10px 30px;
  color: $txt;
  background: #EAEAEA;
  font-size: $font14;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 700;
  margin-left: auto;
  font-family: $font2-family-name;
  border-radius: 5px;
  transition: all 0.3s ease-out;

  &:after {
    content: url("../img/ico-download.svg");
    margin-left: 10px;
    transition: all 0.3s ease-out;
  }

  @media(hover: hover) {
    &:hover {
      background-color: $main;
      color: #FFF;

      &:after {
        filter: brightness(0) invert(1);
      }
    }
  }
}

.file__title {
  font-size: $font18;
  margin-bottom: 0px;
  font-weight: 700;
}


@media(max-width: $grid-breakpoints-md) {
  .file__txt p {
    font-size: $font14;
  }
  .file__item {
    padding-left: 60px;

  }
  .file__link {
    text-indent: -1000px;
    min-width: 50px;
    height: 0;
    padding: 0;

    &:after {
      text-indent: 0px;
      margin-left: 0;
    }
  }
}


//produkt


.product__figure {
  position: relative;
  height: 0;
  padding-top: 90%;
  transition: all 0.3s ease-in-out;

  img {
    width: 90%;
    height: 90%;
    object-fit: contain;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

}

.product__txt {
  padding: 10px 30px 20px 30px
}

.product__title {
  font-size: $font24;
  margin-bottom: 15px;
  transition: color 0.3s ease-in-out;

  span {
    color: $main;
    display: block;
  }
}

.product__summary {
  color: $txt;
}


.product__txt--small {
  padding: 10px 20px 15px 20px;

  .product__title {
    font-size: $font15;
    margin-bottom: 5px;
  }

  .product__summary {
    font-size: $font16;
  }
}

.product__item {
  position: relative;
opacity:1;
  transition: opacity 0.3s ease-out;
}

.product__item--op0 {
  opacity: 0;
}


.product__link {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  border-radius: 5px;
  background: $bg-grey;
  outline: 1px solid transparent;
  @media(hover: hover) {

    &:hover,
    &.active {
      outline-color: $main !important;
      border-color: $main !important;
      color: $txt;

      .product__title {
        color: $txt;
      }

      .product__summary {
        color: $txt;
      }
    }
  }
}

.product__item--big {
  .product__title {
    font-size: $font32;
  }
}

.product2__link {
  display: flex;
  gap: 15px;
  justify-content: center;
  padding: 10px 10px 10px 80px;
  background: #fff;
  outline-color: #d3d3d3;
  border:1px solid transparent;
  height: 100%;
  position: relative;
  gap: 5px;

  min-height: 90px;

  &.active{
    background: $bg-grey;
  }

  img {
    width: 68px;
    height: auto;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  h3 {
    margin-bottom: 0;
    font-size: $font18;
  }
}

.swiper {
  .product__item {
    padding: 8px;
    height: 100%;
  }

  .product__link {
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
    outline-width: 2px;
  }
}

.product__item--white .product__link {
  background: #fff;
  outline-color: #d3d3d3;
}

.product2__section {
  padding-top: 30px;

}


.category {
  .product__title {
    font-size: $font22;

    margin-bottom: 0;
  }

  .product__figure {
    padding-top: 105%;
  }
}

.categoryS {
  padding-top: 50px;
  padding-bottom: 50px;
}

.pr--contact {
  background: #fff;
  padding: 10px;
  color: #000;
  display: inline-flex;
  gap: 5px;
  align-items: center;
  font-weight: 700;
  border-radius: 5px;
  border: 1px solid $line3;

  &:before {
    content: "";
    width: 24px;
    height: 24px;
    display: block;
    background: url("../img/ico-phone.svg") center no-repeat;
    background-size: 100%;
  }
}

.pr--email {
  &:before {
    background-image: url("../img/ico-mail3.svg");
  }
}

.product__contact {
  background: $bg-grey;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 50px;

  .pr--contact {
    width: 100%;
    margin-top: 10px;
  }

  h3 {
    font-size: $font26;
  }

  p {
    margin-bottom: 30px;
  }

}

.categoryS__title {
  padding: 20px 25px;
  margin-bottom: 0;
  font-size: $font18;
}

.categoryS__link {
  min-height: 90px;
  justify-content: center;

}


@media(max-width: $grid-breakpoints-3xl) {
  .product__txt {
    padding: 10px 25px 20px 25px
  }

  .product__title {
    font-size: $font22;
  }
  .category {
    .product__title {
      font-size: $font20;
    }
  }
  .product__item--big {
    .product__title {
      font-size: $font28;
    }
  }

}

@media(max-width: $grid-breakpoints-xl) {
  .product__txt {
    padding: 10px 20px 20px 20px
  }
  .product__title {
    font-size: $font20;
  }
  .category {
    .product__title {
      font-size: $font18;
    }
  }
  .product__item--big {
    .product__title {
      font-size: $font26;
    }
  }
}

@media(max-width: $grid-breakpoints-md) {
  .product__title {
    font-size: $font17;
  }
  .category {
    .product__title {
      font-size: $font16;
    }
  }

  .product__txt {
    padding: 10px 15px 20px;
  }

  .product__item--big {
    .product__title {
      font-size: $font24;
    }
  }


}

@media(max-width: $grid-breakpoints-sm) {
  .product__item--big {
    .product__title {
      font-size: $font20;
    }
  }
  .category {
    .product__title {
      font-size: $font12;
    }
  }
  .product__contact {
    padding: 30px 15px;

    h3 {
      font-size: $font13;
    }

    p {
      font-size: $font10;
      margin-bottom: 10px;
    }

    .pr--contact {
      font-size: $font10;

      &:before {
        width: 12px;
        height: 12px;
      }
    }
  }

  .categoryS__link {
    align-items: center;
    text-align: center;
  }

  .product__txt--small {
    padding-top: 10px;

    .product__title {
      font-size: $font12;
    }

    .product__summary {
      font-size: $font11;
    }
  }


}

//produkt-end


.gallery__wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.gallery__item {
  max-width: calc(16.66% - 10px);
  flex: 0 0 16.66%;
  position: relative;

  &:after {
    display: block;
    content: "";
    width: 100%;
    height: 8px;
    position: absolute;
    bottom: 0;
    left: 0;

    z-index: 10;
  }

}


@media(max-width: $grid-breakpoints-md) {
  .gallery__wrapper {
    gap: 8px;
  }

  .gallery__item {
    max-width: calc(25% - 6px);
    flex: 0 0 25%;
  }
}


.gallery__link {
  height: 0;
  padding-top: 90%;
  position: relative;
  display: block;

  img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  &:before {
    content: url(../img/btn-search-white.svg);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    z-index: -1;
    opacity: 0;
    transition: opacity .3s ease-out 0s;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: #b8308b;
    z-index: -1;
    transition: all .3s ease-out;
    opacity: 0;
  }

  @media(hover: hover) {
    &:hover {
      &:before {
        z-index: 12;
        opacity: 1;
        transition: opacity .3s ease-out;
      }

      &:after {
        opacity: 0.2;
        z-index: 10;
      }
    }
  }


}


.sl-prev {
  color: transparent !important;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("../img/swiper-left.svg");
    background-size: 25px;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    display: block;
  }
}

.sl-next {
  color: transparent !important;

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("../img/swiper-right.svg");
    background-size: 25px;
    background-position: center;
    background-repeat: no-repeat;
    top: 0;
    left: 0;
    display: block;
  }
}


.newsletter {
  @media(min-width: $grid-breakpoints-lg) {
    .container {
      max-width: 900px;
    }
  }

  @media(max-width: $grid-breakpoints-sm) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.newsletter__form {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  width: 100%;

  label {
    color: #fff;
  }

  .form__input {
    width: 100%;
  }

  @media(min-width: $grid-breakpoints-md) {
    .form__input {
      flex: 0 0 50%;
      max-width: calc(50% - 10px);
    }
  }

}


.newsletter__wrapper {
  background-color: $main;
  color: #fff;
  padding: 40px 20px;
  margin-left: -20px;
  margin-right: -20px;

  .title {
    color: #fff;
    text-align: center;
  }


  @media(min-width: $grid-breakpoints-sm) {
    padding: 50px;
  }

  @media(min-width: $grid-breakpoints-lg) {
    padding: 60px 100px;
  }
}


.newsletter__wrapper {
  .form__input--error {
    color: #eee;
  }
}


@media(min-width: $grid-breakpoints-lg) {
  .cart__wrapper {
    display: flex;
    gap: 50px;
  }

  .cart__left {
    flex: 0 0 450px;
  }

  .cart__right {
    max-width: calc(100% - 500px);

    img {
      max-width: 100%;
    }
  }
}

.cart__left {
  .form__input {
    width: 100%;
    max-width: 100%;
  }
}

#cookie_info {
  border-color: $main;
  background: #FEF7A8 !important;

  #cookie_info2 {
    background: #FEF7A8 !important;
  }

  .cookie-info-txt-elm {
    color: #000;
  }

  #close_info_cookie {
    background-color: $main;
  }
}


.strategy {
  position: relative;
}

.strategy__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.strategy__wrapper {
  position: relative;
}

.strategySwiper2 {
  padding-bottom: 70px !important;
}

.strategySwiper__btn {
  border: 1px solid $main;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $main;
  cursor: pointer;

  font-size: $font30;
  font-weight: 700;

  &:after {
    content: "";
    display: inline-block;
    background-image: url("../img/arrow-right-blue.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 30px;
    height: 40px;
    margin-left: 20px;
    transition: all 0.3s ease-out;
  }
}

.strategySwiper .swiper-slide-thumb-active {
  .strategySwiper__btn {
    color: #fff;
    background: $main;

    &:after {
      filter: brightness(0) invert(1);
      transform: rotate(90deg);
    }
  }
}

.vision {
  padding-top: 100px;
}


.vision__txt {
  h2 {
    font-size: 86px;
    font-weight: 400;
    margin-bottom: 60px;
  }

  p {
    font-weight: 700;
    font-size: 22px;
    max-width: 870px;
    margin-bottom: 15px;
  }

  ul {
    padding-left: 25px;

    li {
      font-size: 26px;

      &:before {
        left: -25px;
      }
    }
  }
}


.mission__heading {
  text-align: right;
  font-size: 34px;
  margin-top: 60px;
  margin-bottom: 30px;
  max-width: 1070px;
  font-weight: 700;
  margin-left: auto;
  font-style: italic;
}

.tech {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  padding: 10px;
}

.tech__item {
  flex: 0 0 50%;
  max-width: calc(50% - 15px);
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  min-height: 180px;
  position: relative;
  z-index: 2;

}

.tech__item {
  &:before {
    display: block;
    content: "";
    width: 56px;
    height: 56px;
    position: absolute;
    z-index: -1;
  }

  &.tech__item--left.tech__item--top {
    &:before {
      border-left: 1px solid $main;
      border-top: 1px solid $main;
      top: -10px;
      left: -10px;
    }
  }

  &.tech__item--right.tech__item--top {
    &:before {
      border-right: 1px solid $main;
      border-top: 1px solid $main;
      top: -10px;
      right: -10px;
    }
  }

  &.tech__item--left.tech__item--bottom {
    &:before {
      border-left: 1px solid $main;
      border-bottom: 1px solid $main;
      bottom: -25px;
      left: -10px;
      width: 140px;
      height: 80px;
    }
  }

  &.tech__item--right.tech__item--bottom {
    &:before {
      border-right: 1px solid $main;
      border-bottom: 1px solid $main;
      bottom: -25px;
      right: -10px;
      width: 140px;
      height: 80px;
    }
  }
}

.tech__txt {
  font-size: $font18;
  padding: 25px;
  width: 100%;
  font-weight: 500;
}

.tech__title {
  font-size: 34px;
  font-weight: 700;
  padding: 15px 0;
  margin-bottom: 0;
}

.tech__letter {

  span {
    background: $main;
    color: #fff;
    font-size: 100px;
    font-weight: 700;
    min-width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center
  }

}

.tech__item--left {
  .tech__letter {
    margin-left: 20px;
  }
}

.tech__item--right {
  .tech__letter {
    margin-right: 20px;
    order: -1;
  }

  .tech__txt {
    text-align: right;

    order: 2;
  }

  .tech__title {
    order: 1;
  }
}

.tech__item--top {
  .tech__letter {
    align-self: flex-end;
  }

  .tech__title {
    align-self: flex-end;
  }
}

.tech__item--bottom {
  .tech__txt {
    align-self: flex-end;
  }
}

.tech__bottom {
  max-width: calc(100% - 300px);
  width: 700px;
  margin-left: auto;
  margin-right: auto;
  font-weight: 700;
  text-align: center;
  font-size: $font18;

  strong {
    font-size: $font30;

  }
}

@media(max-width: $grid-breakpoints-xxl) {

  .vision {
    padding-top: 50px;
  }


  .vision__txt {
    h2 {
      font-size: 64px;
      margin-bottom: 40px;
    }

    ul li {
      font-size: 20px;
    }

    p {
      font-size: $font18;
    }
  }

  .strategySwiper__btn {
    height: 70px;
    font-size: $font26;

    &:after {
      background-size: 20px;
    }
  }

  .mission__heading {
    font-size: 26px;
    margin-top: 50px;
    max-width: 900px;
  }

  .tech__letter {
    span {
      font-size: 75px;
      min-width: 90px;
      height: 90px;
    }
  }

  .tech__title {
    font-size: $font26;
  }

  .tech__txt {
    font-size: $font16;
  }

  .tech__bottom {
    font-size: $font16;
  }


}

@media(max-width: $grid-breakpoints-xl) {

  .mission__heading {
    font-size: 22px;
  }

  .vision__txt h2 {
    font-size: 44px;
  }

  .tech {
    gap: 16px;

  }
  .tech__item {
    flex-wrap: wrap;
    align-items: flex-start;
    min-height: auto;
    max-width: calc(50% - 8px);
    gap: 0;

    &.tech__item--top {
      .tech__txt {
        order: -1;
      }
    }

    &.tech__item--bottom {
      .tech__txt {
        order: 2;
      }
    }

    &.tech__item--left {
      justify-content: flex-end;
    }
  }
  .tech__txt {
    width: 100%;
  }

  .tech__letter span {
    font-size: 50px;
    min-width: 60px;
    height: 60px;
  }

  .tech__title {
    font-size: $font20;
  }

  .tech__item--bottom:before {
    width: 30px !important;
    height: 60px !important;
  }

  .tech__bottom {
    max-width: calc(100% - 70px);

  }
}


@media(max-width: $grid-breakpoints-sm) {
  .strategySwiper__btn {
    height: 50px;
    font-size: $font18;
    padding: 0 5px;
    text-align: center;

    &:after {
      margin-left: 5px;
      background-size: 15px;
    }
  }

  .vision {
    padding-top: 20px;
  }

  .vision__txt {
    h2 {
      font-size: $font26;
      margin-bottom: 20px;
    }

    ul li {
      font-size: $font18;
    }

    p {
      font-size: $font16;
    }
  }

  .mission__heading {
    font-size: $font16;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .tech__txt {
    padding: 10px;
    font-size: $font14;
  }

  .tech__title {
    font-size: $font18;
    padding: 10px 0;
  }

  .tech__letter span {
    font-size: 34px;
    min-width: 46px;
    height: 46px;
  }
  .tech__item--left .tech__letter {
    margin-left: 10px;
  }
  .tech__item--right .tech__letter {
    margin-right: 10px;
  }

  .tech__bottom {
    font-size: $font14;
  }
}


@media(max-width: 450px) {
  .tech__txt {
    font-size: $font12;
  }
}


.lk__swiper {
  margin-left: -40px;
  margin-right: -40px;
  width: calc(100% + 80px);
  padding: 10px 40px;
  position: relative;
  margin-bottom: 150px;

  .swiper-slide {
    height: auto;
    height: auto;
  }


}

.lk__item {
  padding: 45px 30px 35px 30px;
  height: 100%;
  border: 1px solid #B3E4FA;
  transition: all 0.3s ease-out;

  text-align: center;

  .title--border {
    font-size: $font18;

    margin-bottom: 50px;

    &:after {
      bottom: -25px;
    }
  }

  .file__link {
    display: block;
    padding: 12px;
    color: $main;

  }

  @media(hover: hover) {
    &:hover {
      background: #d6deec;

      .file__title {
        background-image: url("../img/ico-download.svg");
      }

      h3 {
        color: $main;
      }
    }
  }

}

.swiper-slide, .col-md-4 {
  height: initial;

  &:nth-child(odd) {
    .lk__item {
      border-color: #C7C1DB;
    }
  }
}

.historySwiper {

  position: relative;
  padding: 10px 40px;
  margin-top: 50px;
  margin-left: -40px;
  margin-right: -40px;
  width: calc(100% + 80px);

  .swiper-wrapper {
    padding: 5px 0px;
  }

  .swiper-slide {
    height: initial;

    border: 1px solid #B3E4FA;

    &:nth-child(even) {
      border-color: #C7C1DB;
    }
  }

  .list--own {

    line-height: 1.2;

    li {
      margin-bottom: 15px;

      li {
        margin-bottom: 0;
      }
    }
  }
}

.history__item {
  padding: 30px 20px;
}


.history__title {
  font-size: 52px;
  margin-bottom: 40px;
  position: relative;
  text-align: center;

  &:after {
    display: block;
    width: 40px;
    height: 4px;
    content: "";
    position: absolute;
    background: $main;
    bottom: -15px;
    left: 50%;
    margin-left: -20px;
  }
}

.history__logo {
  width: 100%;
}

.history__item--last {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media(max-width: $grid-breakpoints-xxl) {
  .history__title {
    font-size: 42px;
  }
}

@media(max-width: $grid-breakpoints-xl) {
  .history__title {
    font-size: 36px;
  }
}

@media(max-width: $grid-breakpoints-sm) {

  .history__title {
    font-size: 28px;
  }
  .historySwiper {
    margin-top: 30px;
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
  }

  .lk__swiper {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
  }
  .lk__item {
    padding: 30px 30px 20px;
  }

  .history.section {
    padding-bottom: 0;
  }
}


.contact__top {
  .description {
    margin-bottom: 80px;
  }
}


.contact__item {
  height: 100%;
  background: $main;
  color: #fff;
  text-align: center;
  padding: 80px 20px;
  font-size: $font18;
  line-height: 1.6;

  .title--border {
    font-size: 18px;
    text-transform: uppercase;

    &:after {
      background: #fff;
    }
  }

  h3 {
    margin-bottom: 15px;
    font-size: $font18;
  }

  a {
    color: #fff;
    @media(hover: hover) {
      &:hover {
        color: $main-hover
      }
    }
  }
}

@media(max-width: $grid-breakpoints-sm) {
  .contact__item {
    padding: 50px 10px;
  }

  .contact__top {
    .description {
      margin-bottom: 60px;
    }
  }
}


.di__top {
  margin-top: -30px;
  padding-bottom: 100px;
}


@media(max-width: $grid-breakpoints-sm) {
  .di__top {
    padding-bottom: 20px;
  }
  .dl__contact {
    padding-top: 0;
  }

}


.manage__figure {
  padding-top: 56%;
  height: 0;;
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
}

.manage__title {
  font-size: $font30;
  font-weight: 700;
  padding: 15px 20px;
  border-bottom: 1px solid $line;
  overflow: hidden;
  margin-bottom: 5px;

  span {
    font-size: $font18;
    font-weight: 400;
    font-style: italic;

  }
}

.manage__description {
  padding: 15px;
  font-size: $font18;

  p {
    margin-bottom: 20px;
  }
}

.manage__section {
  position: relative;
  overflow: hidden;
}


.manage__img {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}


@media(max-width: $grid-breakpoints-xxl) {
  .manage__title {
    span {
      margin-top: 5px;
      display: block;
    }
  }
}

@media(max-width: $grid-breakpoints-sm) {
  .manage__title {
    font-size: $font26;
  }
}

.doc__item {
  @media(min-width: $grid-breakpoints-xl) {
    margin-bottom: 50px;
  }
}

.doc__link {
  background: $main;
  height: 100%;
  color: #fff;
  padding: 65px 10px 45px 10px;
  display: block;

  width: 100%;

  .title--border {
    font-size: $font30;

    &:after {
      background: #fff;
    }
  }


  @media(max-width: $grid-breakpoints-sm) {
    padding: 35px 10px 15px 10px;
    .title--border {
      font-size: $font20;
    }
  }
}

a.doc__link {
  @media(hover: hover) {

    &:hover {
      color: #fff;
      background: $main-hover;
    }
  }
}


.doc2__list {
  padding-top: 50px;
  padding-bottom: 100px;

  max-width: 720px;
  margin-right: auto;
  margin-right: auto;
  margin-left: auto;

  .file__list {
    max-width: 670px;
  }

  .accordion__trigger {
    border-bottom: 1px solid $line;

    padding: 30px 50px 30px 20px;

    padding-right: 50px;

    &:after {
      position: relative;
      top: 0;
      transform: rotate(-90deg);
      right: -50px;
      display: inline-block;
    }

    &[aria-expanded="true"] {
      &:after {
        transform: rotate(0deg);
      }

    }

  }

}

.doc2__item .accordion__content {
  padding-left: 20px;
  padding-right: 20px;

  &[aria-hidden="false"] {
    border-bottom: 1px solid $line;
  }
}


.investments {
  margin-bottom: 100px;

}


.investmentsSwiper__wrapper {
  height: 0;
  padding-top: 56%;
  position: relative;
  margin-bottom: 40px;

  margin-top: 70px;
}


.investmentsSwiper {
  position: absolute !important;
  top: 0;
  left: 50%;
  height: 100%;
  width: 100vw;
  transform: translateX(-50%);

  .swiper-slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.investmentsSwiper__bg {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 20;
  transform: translate(-50%, -50%);

  width: 100%;
  height: 100%;
  object-fit: cover;
}

.investmentsSwiper2 {
  padding: 0 2px;

  &.swiper-accordion-active {
    .accordion, .investments__item {
      height: auto;
    }
  }

  .accordion__content {
    font-style: normal;
    text-align: left;

    p {
      padding-top: 10px;
      font-style: normal;

    }
  }

  .swiper-slide {
    height: auto;
  }

  .accordion, .investments__item {
    height: 100%;
  }


  .accordion__trigger {

    font-weight: 400;
    display: inline-flex;
    width: initial;

    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    &:after {
      transform: translateY(-50%) rotate(-90deg);

      right: 20px;
    }

    &[aria-expanded=true]:after {
      transform: translateY(-50%) rotate(-180deg);
    }

    &[aria-expanded=true] {
      width: 100%;
      color: transparent;
      margin-top: 0px;
    }
  }
}

.investments__item {
  border: 1px solid $main;
  padding: 50px 50px 140px 50px;
  text-align: center;
  height: 100%;
  position: relative;
  cursor: pointer;

  .title--border {
    font-size: 30px;
  }

  p {
    font-style: italic;
    font-size: $font18;
  }

}

.swiper-slide-thumb-active {
  .investments__item {
    background: $main;
    color: #fff;

    .title--border {
      &:after {
        background: #fff;
      }
    }
  }

  .swiper-slide {
    height: auto;
  }

  .accordion__trigger {
    color: #fff;

    &:after {
      filter: brightness(0) invert(1);
    }
  }
}

@media(max-width: $grid-breakpoints-xl) {

  .investmentsSwiper__wrapper {
    margin-bottom: 12px;
  }
}


@media(max-width: $grid-breakpoints-lg) {
  .investments__item .title--border {
    font-size: 26px;
  }

  .investments__item {
    padding: 30px 20px 120px 20px;
  }

}

@media(max-width: $grid-breakpoints-md) {
  .investments__item .title--border {
    font-size: 20px;
  }

  .investments__item {
    padding: 30px 15px 90px 15px;
  }
}


@media(max-width: $grid-breakpoints-sm) {
  .investments {
    margin-left: -20px;
    margin-right: -20px;
  }

  .investmentsSwiper__wrapper {
    margin-bottom: 0;
  }

  .investments__item {
    padding: 20px 10px 80px 10px;

    .accordion__trigger {
      font-size: 14px;
      padding-right: 30px;
      bottom: 10px;

      &:after {
        width: 9px;

        right: 10px
      }
    }
  }

  .investments__item {

    border-top: none;

    .title--border {
      font-size: 16px;
    }
  }
  .investments__item {
    p {
      font-size: 14px;
    }
  }
}


.shareholders {
  display: flex;
  gap: 60px;
  margin-bottom: 100px;
  margin-top: 80px;
  align-items: center;
}

.shareholders__left {
  flex: 0 0 560px;
  max-width: 560px;

  canvas {
    cursor: pointer;
    max-width: 100%;
  }
}

.shareholders__right {
  width: 100%;
  max-width: calc(100% - 620px);

  table {
    width: 100%;
    font-size: $font18;

    margin-bottom: 10px;

    th {
      font-weight: 400;
      border-top: none;
      border-bottom: 1px solid $line;

      &:first-child {
        padding-left: 0;
      }
    }

    tbody {
      td:first-child {
        font-weight: 700;
        padding-left: 0;
        padding-left: 0;
      }
    }
  }
}

@media(max-width: $grid-breakpoints-xxl) {
  .shareholders__left {
    flex: 0 0 400px;
    max-width: 400px;
  }

  .shareholders__right {
    max-width: calc(100% - 460px);
  }
}

@media(max-width: $grid-breakpoints-xl) {
  table td, table th {
    padding: 10px 5px;
  }
  .shareholders {
    gap: 20px;
  }
  .shareholders__right {
    max-width: calc(100% - 420px);
  }
  .shareholders__right {
    table {
      font-size: $font16;
    }

    tbody {
      td:not(:first-child) {
        min-width: 80px;
      }
    }
  }
}

@media(max-width: $grid-breakpoints-lg) {
  .shareholders {
    display: block;
  }
  .shareholders__left {
    max-width: 100%;
    flex: 0 0 100%;

    canvas {
      margin-left: auto;
      margin-right: auto;

    }
  }
  .shareholders__right {
    max-width: 100%;
    flex: 0 0 100%;
  }

}


@media(max-width: $grid-breakpoints-lg) {
  .shareholders {
    margin-bottom: 50px;
    margin-top: 50px;

  }
}


.go-top {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 48px;
  display: inline-block;
  height: 48px;
  background: url("../img/ico_top.svg");
  background-size: 14px;
  background-repeat: no-repeat;
  background-position: center;
}


.video__wrapper {
  height: 0;
  padding-top: 56%;
  position: relative;
  margin-top: -30px;
  margin-bottom: 100px;

  video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 1;
    width: 100%;
  }
}

.video__img {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 1;
  width: 100%;
  height: 100%;
}


.video__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 8;
  width: 60px;
  height: 60px;
  background-image: url("../img/play.svg");
  background-size: contain;
  background-position: center;
  background-color: transparent;
  border: none;

}

.video__title {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-transform: uppercase;
  font-size: 22px;
  text-align: center;
  z-index: 5;
  font-weight: 400;
  width: 100%;
}

@media(max-width: $grid-breakpoints-md) {

  .video__wrapper {
    margin-bottom: 50px;
  }
  .video__title {
    top: 70%;
    font-size: 16px;
  }

}


.bestseller {
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 50px;

  .product__link {
    box-shadow: none;
  }
}


.article2 {
  padding-top: 90px;
  padding-bottom: 80px;
  margin-top: -20px;
  @media(max-width: $grid-breakpoints-xl) {
    padding-top: 70px;
    padding-bottom: 60px;
  }
  @media(max-width: $grid-breakpoints-sm) {
    padding-top: 50px;
    padding-bottom: 40px;
  }
}

.nav2 {
  overflow: hidden;
  position: sticky;
  top: 60px;
  background: #fff;
  z-index: 50;
  margin-top: 20px;

  .container {
    overflow: auto;
  }

}


.nav2-list {
  display: inline-flex;
  margin-left: -10px;
  position: relative;
  padding-bottom: 8px;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 10px;
    height: 8px;
    width: calc(100% - 20px);
    border-radius: 4px;
    background: $bg-grey3;
  }

  @media(min-width: $grid-breakpoints-xxl) {
    gap: 10px;
  }

}

.nav2-slider {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 8px;
  transition: none;
  display: block;
  width: 50px;
  opacity: 0;
  border-radius: 4px;
  background: $main;
  z-index: 10;

  &.is-start {
    transition: all 0.3s ease-out;
    opacity: 1;
  }
}

.nav2-link {
  font-size: $font18;
  color: $txt-light2;
  display: inline-block;
  padding: 15px 10px;
  position: relative;
  white-space: nowrap;
  background: #fff;
  font-weight: 700;


  &.active {
    color: #000;


  }

  @media(hover: hover) {
    &:hover {
      color: #000;
    }
  }
}

@media(max-width: $grid-breakpoints-sm) {
  .nav2-link {
    font-size: $font16;
  }
}



